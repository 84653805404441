import React from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';
import EventsFacilities from '../Components/EventsFacilities';

const EventsPreWeddingPhotoShoot = () => {
    return (
        <>
            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>

            <section className="packagesPage_overview section_heading">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Pre Wedding Photoshoot Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                A prewedding photo shoot works best when you have the right location, best frames, angles and lighting, and a relaxed frame of mind – some of the reasons why Tapovana is increasingly becoming a preferred destination for prewedding shoots. We know that you have to fuss over your outfits and looks as you seize perfect moments in a frame during the photo shoot, and that is why we take care of everything else. Find the prewedding photo shoot that best serves your requirement.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_packagesSubPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img src={"assets/media/eventsPage_preweddingPhotoshoot.jpg"} className="packagesPage_banner subPage_banner" alt="Events Wedding Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <EventsFacilities />

            <WebsiteFooter />
        </>
    )
}

export default EventsPreWeddingPhotoShoot
