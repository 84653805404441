import React, { useEffect } from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';

import { Card } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

import BanquetFacilities from '../Components/BanquetFacilities';
import BanquetEnquiry from '../Components/BanquetEnquiry';

const BanquetGreenlawn = () => {
    var navigate = useNavigate();

    
    useEffect(() => {
        window.scrollTo(0, 0)
        let height_arr = [];

        let cardBody_banquetSubPage = document.getElementsByClassName("cardBody_banquetSubPage");

        for (let i = 0; i < cardBody_banquetSubPage.length; i++) {
            cardBody_banquetSubPage[i].style.display = "block";
            let height_data = cardBody_banquetSubPage[i].clientHeight;
            height_arr.push(parseInt(height_data));
        }
        var max_height = (Math.max.apply(Math, height_arr));

        for (let i = 0; i < cardBody_banquetSubPage.length; i++) {
            cardBody_banquetSubPage[i].style.height = (max_height + 20) + "px";
        }

    }, [])

    return (
        <>

            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>
            <section className="banquetGreenlawnPage_overview section_heading">

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Green Lawn Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                Our sprawling green lawns are a perfect place for you to host gatherings and parties. We take care of the tiniest of details – from lawn decorations to sitting arrangements - to ensure you have a memorable outdoor lawn party experience. As we provide the green lawn banquet, we ensure that the guests are protected from harsh weather and enjoy the party unaffected. As the guests enjoy the lush green surroundings and misty clouds, we serve delectable dishes as per the menu selected by you.
                            </p>

                        </div>
                    </div>
                </div>
            </section>

            <section className="section_banquetSubPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0 ">
                            <img src={"assets/media/banquetPage_greenlawn.jpg"} className="banquetGreenlawnPage_banner subPage_banner" alt="Banquet Green Lawn Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <BanquetFacilities />

            <section className="section_capacity">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            <h2 className="text-cener script_font">Seating Capacity</h2>
                        </div>
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                            <table className="table border border-dark" >
                                <thead className="table-dark">
                                    <tr>
                                        <th>Arrangements</th>
                                        <th>Capacity</th>
                                    </tr>
                                </thead>
                                <tbody className="">
                                    <tr>
                                        <td>Theatre</td>
                                        <td>500 Persons </td>
                                    </tr>
                                    <tr>
                                        <td>Cocktail</td>
                                        <td>400 Persons</td>
                                    </tr>
                                    <tr>
                                        <td>Round Table Dinner</td>
                                        <td>200 Persons</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-lg-4"></div>

                    </div>
                </div>
            </section>

            <BanquetEnquiry />

            <section className="section_features section_subHeading" id="section_features">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            <h2 className="text-cener script_font">Our Banquets</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-lg-4 mx-auto div_card_banquetSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_banquetSubPage">
                                <Card.Img className="img_card_banquetSubPage" variant="top" src={"assets/media/banquetPage_hall.jpg"} />
                                <Card.Body className="cardBody_banquetSubPage">
                                    <Card.Title>Ball Room</Card.Title>
                                    <Card.Text className="text-justify">
                                        While on stay, if we have guests who want to have grand celebrations, we can arrange them. At Tapovana, we have the arrangements for the oversized banquets that can be hosted by our guests. All the deals will be taken care of by us for the same.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_banquetSubPage " value="Show More" onClick={() => { navigate("/banquetHall") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-4 mx-auto div_card_banquetSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_banquetSubPage">
                                <Card.Img className="img_card_banquetSubPage" variant="top" src={"assets/media/banquetPage_boardroom.jpg"} />
                                <Card.Body className="cardBody_banquetSubPage">
                                    <Card.Title>Board Room</Card.Title>
                                    <Card.Text className="text-justify">
                                        At Tapovana, we have the service of many different types of the banquet. One of these types of banquets that we provide here is a Board room banquet. So if our guests want to have small or large-scale professional meetings, they can even be arranged with us.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_banquetSubPage " value="Show More" onClick={() => { navigate("/banquetBoardroom") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-4 mx-auto div_card_banquetSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_banquetSubPage">
                                <Card.Img className="img_card_banquetSubPage" variant="top" src={"assets/media/banquetPage_outdoor.jpg"} />
                                <Card.Body className="cardBody_banquetSubPage">
                                    <Card.Title>Out Door</Card.Title>
                                    <Card.Text className="text-justify">
                                        Outdoor banquets are always suitable for family gatherings. Tapovana makes sure that our guests can taste the same without facing any kind of problems. We help you with the most pleasing ambience and arrange your surroundings with your requests.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_banquetSubPage" value="Show More" onClick={() => { navigate("/banquetOutdoor") }} />
                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                </div>
            </section>


            <WebsiteFooter />

        </>
    )
}

export default BanquetGreenlawn
