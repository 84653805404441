import React, { useEffect } from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';

import { Card } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';


const FacilitiesInfinitypool = () => {
    var navigate = useNavigate();

    
    useEffect(() => {
        window.scrollTo(0, 0)
        let height_arr = [];

        let cardBody_facilitiesSubPage = document.getElementsByClassName("cardBody_facilitiesSubPage");

        for (let i = 0; i < cardBody_facilitiesSubPage.length; i++) {
            cardBody_facilitiesSubPage[i].style.display = "block";
            let height_data = cardBody_facilitiesSubPage[i].clientHeight;
            height_arr.push(parseInt(height_data));
        }
        var max_height = (Math.max.apply(Math, height_arr));

        for (let i = 0; i < cardBody_facilitiesSubPage.length; i++) {
            cardBody_facilitiesSubPage[i].style.height = (max_height + 20) + "px";
        }

    }, [])

    return (
        <>

            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>
            <section className="facilitiesInfinitypoolPage_overview section_heading">

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Infinitypool Facility Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                Imagine the serene yet ominous sensation of being in a seemingly unending pool of cool water brimmed by green rolling hills! That’s how you feel when you step into the infinity pool at Tapovana. An absolute favourite among guests across all age groups, our infinity pool is the perfect place to hear the silent ripples punctuated by the singsong of exotic birds. And it is here, in this infinity pool, that you feel the heavenly grandeur of Sakleshpur.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_facilitiesSubPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img src={"assets/media/facilitiesPage_infinitypool.jpg"} className="facilitiesInfinitypoolPage_banner subPage_banner" alt="Facilities Infinitypool Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_features section_subHeading" id="section_features">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            <h2 className="text-cener script_font">Our Facilities</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-lg-3 div_card_facilitiesSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_facilitiesSubPage">
                                <Card.Img className="img_card_facilitiesSubPage" variant="top" src={"assets/media/recreationPage_banner.jpg"} />
                                <Card.Body className="cardBody_facilitiesSubPage">
                                    <Card.Title>Recreation</Card.Title>
                                    <Card.Text className="text-justify">
                                        There are various fun and recreational activities for all age groups at Tapovana. We know how to engage all members of your family.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_facilitiesSubPage" value="Show More" onClick={() => { navigate("/recreation") }} />
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="col-md-6 col-lg-3 div_card_facilitiesSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_facilitiesSubPage">
                                <Card.Img className="img_card_facilitiesSubPage" variant="top" src={"assets/media/diningPage_banner.jpg"} />
                                <Card.Body className="cardBody_facilitiesSubPage">
                                    <Card.Title>Dining</Card.Title>
                                    <Card.Text className="text-justify">
                                        We know that a perfect holiday is incomplete without delicious food. At Tapovana, we offer our guests a wholesome dining experience throughout their stay.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_facilitiesSubPage" value="Show More" onClick={() => { navigate("/dining") }} />
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="col-md-6 col-lg-3 div_card_facilitiesSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_facilitiesSubPage">
                                <Card.Img className="img_card_facilitiesSubPage" variant="top" src={"assets/media/facilitiesPage_spa1.jpg"} />
                                <Card.Body className="cardBody_facilitiesSubPage">
                                    <Card.Title>Spa</Card.Title>
                                    <Card.Text className="text-justify">
                                        The spa facilities at Tapovana are one of a kind as they offer an amalgam of top-notch equipment, professionally-trained staff, and a soothing, calming ambience.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_facilitiesSubPage" value="Show More" onClick={() => { navigate("/facilitiesSpa") }} />
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="col-md-6 col-lg-3 div_card_facilitiesSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_facilitiesSubPage">
                                <Card.Img className="img_card_facilitiesSubPage" variant="top" src={"assets/media/facilitiesPage_yoga.jpg"} />
                                <Card.Body className="cardBody_facilitiesSubPage">
                                    <Card.Title>Yoga</Card.Title>
                                    <Card.Text className="text-justify">
                                        When you spend your vacations with us, you must not miss our daily Yoga sessions with our trained instructors.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_facilitiesSubPage" value="Show More" onClick={() => { navigate("/facilitiesYoga") }} />
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </section>


            <WebsiteFooter />

        </>
    )
}

export default FacilitiesInfinitypool
