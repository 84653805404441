import React from 'react';
import * as FiIcons from "react-icons/fi";
import * as FaIcons from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const WebsiteFooter = () => {
    const navigate = useNavigate();

    const openInNewTab = (data, mode) => {
        if (mode == "phone") {
            let url = "tel:" + data;
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
        if (mode == "mail") {
            let url = "mailto:" + data;
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
        if (mode == "link") {
            let url = data;
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }

    const OpenInNewTab = (Type, Url) => {
        if (Type == "Link") {
            const newWindow = window.open(Url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }

    return (
        <>

            <section className="section_footer " id="section_contact_us">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 text-center">
                            <div className="footer_head" style={{ textAlign: "center" }}>
                                <div className="" style={{ color: "#fff", display: "inline-block", textAlign: "left" }}>
                                    <img className="img_logo" src={"assets/media/tapovana_logo_white.png"} onClick={() => { navigate("/") }} />
                                </div>
                            </div>
                            <div className="" style={{ textAlign: "center" }}>
                                <div className="" style={{ color: "#fff", display: "inline-block", textAlign: "left" }}>
                                    <p className="m-0"><b>Corporate Office:</b></p>
                                    <p className="footer_link text-justify" onClick={() => openInNewTab("https://www.google.com/maps/place/Tapovana+Heritage+Resorts+And+Spa/@12.925773,77.560985,14z/data=!4m5!3m4!1s0x0:0xf9c1e58ce3b911b5!8m2!3d12.9257733!4d77.5609852?hl=en-US", "link")}><b>Unit of The Orrix Ventures</b><br />#1238, 2nd Floor, 23rd Cross, 30th Main, Banashankari 2 Stage, Bangalore - 560070.</p>
                                    <p className="cursive_font footer_link" onClick={() => openInNewTab("+91-9686659966", "phone")}><span><FiIcons.FiPhone /></span> +91 96866 59966</p>
                                    <p className="cursive_font footer_link" onClick={() => openInNewTab("reservations@tapovanaresorts.com", "mail")}><span><FiIcons.FiMail /></span> reservations@tapovanaresorts.com</p>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className="footer_head" style={{ textAlign: "center" }}>
                                <div className="" style={{ color: "#fff", display: "inline-block", textAlign: "left" }}>
                                    <h4 className="script_font footer_htag">Links</h4>
                                </div>
                            </div>
                            <div className="" style={{ textAlign: "center" }}>
                                <div className="" style={{ color: "#fff", display: "inline-block", textAlign: "left" }}>
                                    <ul className="footer_ul">
                                        <li className="cursive_font footer_link" onClick={() => { navigate("/aboutUs") }}>About Us</li>
                                        <li className="cursive_font footer_link" onClick={() => { navigate("/room") }}>Rooms</li>
                                        <li className="cursive_font footer_link" onClick={() => { navigate("/facilities") }}>Facilities</li>
                                        <li className="cursive_font footer_link" onClick={() => { navigate("/banquet") }}>Banquets</li>
                                        <li className="cursive_font footer_link" onClick={() => { navigate("/package") }}>Packages</li>
                                        {/* <li className="cursive_font footer_link" onClick={() => OpenInNewTab("Link", "https://tapovanaresorts.com/")}>Invest in Resort</li>
                                        <li className="cursive_font footer_link" onClick={() => OpenInNewTab("Link", "https://tapovanaresorts.com/invest-in-farmhouse/")}>Invest in Farmhouse</li>
                                        <li className="cursive_font footer_link" onClick={() => OpenInNewTab("Link", "https://tapovanaresorts.com/how-it-works/")}>How it works?</li> */}
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-4 px-0 text">
                            <div className="footer_head" style={{ textAlign: "center" }}>
                                <div className="" style={{ color: "#fff", display: "inline-block", textAlign: "left" }}>
                                    <h4 className="script_font footer_htag">Connect</h4>
                                </div>
                            </div>
                            <div className="" style={{ textAlign: "center" }}>
                                <div className="" style={{ color: "#fff", display: "inline-block", textAlign: "left" }}>
                                    <button className="btn border border-dark rounded-circle mx-1 text-light btn_icon mb-1" onClick={() => openInNewTab("https://www.facebook.com/tapovana.resorts.3", "link")} ><FaIcons.FaFacebookF /></button>
                                    <button className="btn border border-dark rounded-circle mx-1 text-light btn_icon mb-1" onClick={() => openInNewTab("https://twitter.com/TapovanaR", "link")} ><FaIcons.FaTwitter /></button>
                                    <button className="btn border border-dark rounded-circle mx-1 text-light btn_icon mb-1" onClick={() => openInNewTab("https://www.youtube.com/channel/UCDaPHyQmF5AO1Ws0Hw7atLw/?guided_help_flow=5", "link")} ><FaIcons.FaYoutube /></button>
                                    <button className="btn border border-dark rounded-circle mx-1 text-light btn_icon mb-1" onClick={() => openInNewTab("https://www.instagram.com/tapovanaresortsspa/", "link")} ><FaIcons.FaInstagram /></button>
                                    <button className="btn border border-dark rounded-circle mx-1 text-light btn_icon mb-1" onClick={() => openInNewTab("https://www.linkedin.com/company/tapovana-heritage-resorts-and-spa", "link")} ><FaIcons.FaLinkedinIn /></button>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="row">
                        <div className="col-12 text-center div_copyright">
                            <p>Copyright <FaIcons.FaRegCopyright /> 2022 Tapovana Resorts / All rights reserved | <span className="cursive_font link_policy" onClick={() => { navigate("/policy") }}>Policy.</span><br />Developed by: <span className="cursive_font  odm_link" onClick={() => OpenInNewTab("Link", "https://www.orangedigitalmedia.in/")}>Orange Digital Media</span></p>
                        </div>
                    </div>
                </div>

            </section>

        </>
    )
}

export default WebsiteFooter
