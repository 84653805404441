import React, { useEffect } from 'react'
import WebsiteFooter from '../Components/WebsiteFooter';

import { useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';

const Room = () => {

    var navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
        
        let height_arr = [];

        let cardBody_roomSubPage = document.getElementsByClassName("cardBody_roomSubPage");

        for (let i = 0; i < cardBody_roomSubPage.length; i++) {
            cardBody_roomSubPage[i].style.display = "block";
            let height_data = cardBody_roomSubPage[i].clientHeight;
            height_arr.push(parseInt(height_data));
        }
        var max_height = (Math.max.apply(Math, height_arr));

        for (let i = 0; i < cardBody_roomSubPage.length; i++) {
            cardBody_roomSubPage[i].style.height = (max_height + 20) + "px";
        }

    }, [])

    return (
        <div>
            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>
            <section className="roomPage_overview section_heading">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Room Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                At Tapovana, we understand your requirement for a clean, well-adorned and spacious accommodation that commands a pleasant view. Our spacious suites and sprawling room give you the feel of abundance in a warm, traditional home-like setting.
                            </p>
                            <p className="text-justify">
                                Choose accommodation according to your preference, taste, or requirement – from two-room room to large bungalows with four to five rooms. All our living and utility spaces are built using sustainable material in the ornate traditional style of architecture and house all modern amenities for a luxurious stay.
                            </p>
                        </div>
                    </div>
                </div>

            </section>
            <section className="roomPage_Img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0 ">
                            <img src={"assets/media/roomPage_banner.jpg"} className="roomPage_banner" alt="Rooms Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_features section_subHeading" id="section_features">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            <h2 className="text-cener script_font">Our Rooms</h2>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-4 div_card_roomSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_roomSubPage">
                                <Card.Img className="img_card_roomSubPage" variant="top" src={"assets/media/roomPage_hilltop_cottage.jpg"} />
                                <Card.Body className="cardBody_roomSubPage">
                                    <Card.Title>Hilltop Cottage</Card.Title>
                                    <Card.Text className="text-justify">
                                        Best suited for couples and small families, our Hilltop Cottages are modern private spaces carved in ancient Malnad architecture.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_roomSubPage" value="Show More" onClick={() => { navigate("/roomHilltopCottage") }} />
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="col-md-4 div_card_roomSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_roomSubPage">
                                <Card.Img className="img_card_roomSubPage" variant="top" src={"assets/media/roomPage_heritage_thottimane.jpg"} />
                                <Card.Body className="cardBody_roomSubPage">
                                    <Card.Title>Heritage Thottimane</Card.Title>
                                    <Card.Text className="text-justify">
                                        Our Heritage Thottimane villas are ideal for a leisurely vacation with friends and family members amidst the misty mountains.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_roomSubPage" onClick={() => { navigate("/roomHeritageThottimane") }} value="Show More" />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-4 div_card_roomSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_roomSubPage">
                                <Card.Img className="img_card_roomSubPage" variant="top" src={"assets/media/roomPage_valley_view_villa.jpg"} />
                                <Card.Body className="cardBody_roomSubPage">
                                    <Card.Title>Valley View Villa</Card.Title>
                                    <Card.Text className="text-justify">
                                        These Villas afford the best views of the rolling hills, the lush green valley, the sparkling waterfalls, and the aromatic coffee plantations.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_roomSubPage" value="Show More" onClick={() => { navigate("/roomValleyViewVilla") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-4 div_card_roomSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_roomSubPage">
                                <Card.Img className="img_card_roomSubPage" variant="top" src={"assets/media/roomPage_luxury_villa.jpg"} />
                                <Card.Body className="cardBody_roomSubPage">
                                    <Card.Title>Luxury Villa</Card.Title>
                                    <Card.Text className="text-justify">
                                        When you book Luxury villa, be prepared to spend your vacation like Monarch, a Maharaja! Indulge in luxury while you soak in the treasures of nature.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_roomSubPage" value="Show More" onClick={() => { navigate("/roomLuxuryVilla") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-4 div_card_roomSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_roomSubPage">
                                <Card.Img className="img_card_roomSubPage" variant="top" src={"assets/media/roomPage_private_pool_villa.jpg"} />
                                <Card.Body className="cardBody_roomSubPage">
                                    <Card.Title>Private Pool Villa</Card.Title>
                                    <Card.Text className="text-justify">
                                        The private pool villa is the most special one. We have made this villa so that our guests can get the private pool inside their villa.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_roomSubPage" value="Show More" onClick={() => { navigate("/roomPrivatePoolVilla") }} />
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </section>

            <WebsiteFooter />

        </div>
    )
}

export default Room
