import React from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';
import EventsFacilities from '../Components/EventsFacilities';

const EventsPostWeddingPhotoShoot = () => {
    return (
        <>
            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>

            <section className="packagesPage_overview section_heading">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Post Wedding Photoshoot Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                Weddings are special occasions that we cherish and remember all our lives. It is no surprise then that it seems important to preserve the key moments of a wedding ceremony, trying each and every one of our family members and guests to get in the frame. However, it is the bride and the groom and a few loved ones for whom the day holds an exceptional significance. At Tapovana, you can book a post-wedding photoshoot with your loving half and close friends and family members against breathtaking vistas in the background. Find your radiance and good humour in the pleasant surroundings of Tapovana as you create memories for life after the frenzy of wedding crowds and customs is over.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_packagesSubPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img src={"assets/media/eventsPage_postweddingPhotoshoot.jpg"} className="packagesPage_banner subPage_banner" alt="Events Wedding Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <EventsFacilities />

            <WebsiteFooter />
        </>
    )
}

export default EventsPostWeddingPhotoShoot
