import React, { useEffect } from 'react'

import WebsiteFooter from './Components/WebsiteFooter'



const AboutUs = () => {

    

    useEffect(() => {
        window.scrollTo(0, 0)

        let ourvision_height_data = document.getElementById("col_p_ourvision").clientHeight;
        document.getElementById("img_ourvision").style.height = ourvision_height_data + "px";

        let ourmission_height_data = document.getElementById("col_p_ourmission").clientHeight;
        document.getElementById("img_ourmission").style.height = ourmission_height_data + "px";

        let md_height_data = document.getElementById("col_p_md").clientHeight;
        document.getElementById("img_md").style.height = md_height_data + "px";

        let coo_height_data = document.getElementById("col_p_coo").clientHeight;
        document.getElementById("img_coo").style.height = coo_height_data + "px";

    }, [])
    return (
        <>
            <div className="after_header" style={{ height: "75px" }}></div>

            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>

            <section className="aboutUsPage_overview section_heading">

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">About Us</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                Tapovana is much more than a premium luxury resort perched on the lofty hills of Western Ghats; it is an experience to be cherished and celebrated.
                            </p>
                            <p className="text-justify">
                                Nestled amidst verdant forests and surrounded by aromatic coffee plantations, Tapovana affords a refreshing view of green rolling hills and panoramic vistas, which gives you a sense of timelessness and pristine tranquillity.</p>
                            <p className="text-justify">
                                Adding to the pleasant environs, Tapovana also offers its guests an immersive experience of the unbroken culture and heritage of this sacred land, which includes food - local delicacies prepared using age-old recipes, traditional art, and dance forms, bullock-cart rides, farming lessons, and of course, exciting stories from the past that shed light on the local history.
                            </p>
                            <p className="text-justify">
                                Our eco-friendly resort promotes a natural lifestyle and sustainable development. We engage local villagers as vendors to source food items and essentials and provide them employment opportunities within the property. We work with the villagers as a family to preserve the environment, grow healthy, organic food, and carry the cultural heritage forward.
                            </p>
                            <p className="text-justify">
                                Come, visit us to experience the stillness of time and the rejuvenation of the soul.
                            </p>
                        </div>
                    </div>
                </div>

            </section>

            <section className="section_aboutUsPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img src={"assets/media/tapovana.jpg"} className="aboutUsPage_banner" alt="aboutUs Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="aboutUsPage_overview section_heading">

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Who we are?</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col_p_who_we_are" id="col_p_who_we_are">
                            <p className="text-justify">
                                We are a team of Entrepreneurs, done our MBA / MS from premier institutes and have spent long years in USA and Dubai. We have returned to India with a vision to build a chain of world-class resorts around heritage sites, temple towns and untapped natural places in India. The whole theme of the resorts revolve around sustainability of mother nature & India’s rich heritage and culture.
                            </p>
                            <p className="text-justify">
                                Over the years, ‘standardization’ has become synonymous with the hospitality industry. It is thus our endeavor to craft a distinct identity for ourselves in the space of heritage hospitality – one that combines comfort with heritage and delivers class with every travel experience.
                            </p>
                        </div>
                    </div>
                </div>

            </section>

            <section className="aboutUsPage_overview bg_colour section_heading">

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Our Vision</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5 col_img_ourvision  text-center" id="col_img_ourvision">
                            <img className="img_aboutUsPage" id="img_ourvision" src={"assets/media/vision.png"} />
                        </div>
                        <div className="col-md-7 col_p_ourvision" id="col_p_ourvision">
                            <p className="text-justify">
                                Tapovana is a master in making “THE” most acknowledged eco-sensitive brand that offers Heritage hospitality. It even gives an ingenious opportunity for investment at the same time. By doing this, Tapovana will have a unique world-class resort that can be made with distinctive architecture, motivating tourism and even getting more profits.
                            </p>
                            <p className="text-justify">
                                Tapovana wants you to remember our heritage and rich culture as you all tend to miss out on your daily busy lives. Join us in doing these unique endeavours to maintain our heritage and culture while keeping us near nature.
                            </p>
                        </div>
                    </div>
                </div>

            </section>

            <section className="aboutUsPage_overview section_heading">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Our Mission</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7" id="col_p_ourmission">
                            <p className="text-justify" >
                                Tapovana has decided to build a landing place for people near India’s heritage sites, including the temples and nature. By doing this, people can have a refreshing experience of the culture and heritage they are forgetting.
                            </p>
                            <p className="text-justify">
                                Tapovana ensures that all the guests will have an unforgettable experience while staying in a comfortable and safe environment. The environment and the built-in are just perfect for all the guests and even the staff.
                            </p>
                            <p className="text-justify">
                                Tapovana even ensures that they use the local amenities and help grow the local people and businesses. This way, even regional growth can be seen. Tapovana grows with its people.
                            </p>
                        </div>
                        <div className="col-md-5 text-center" id="col_img_ourmission">
                            <img className="img_aboutUsPage" id="img_ourmission" src={"assets/media/mission.png"} />
                        </div>

                    </div>

                </div>
            </section>

            <section className="aboutUsPage_overview bg_colour section_heading">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center" >
                            <h1 className="text-cener script_font div_heading">Our Team</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5 text-center" id="col_img_md">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <img className="img_aboutUsPage" id="img_md" src={"assets/media/aboutUs_images/tapovana_team_md.png"} />
                                    </div>
                                    <div className="col-lg-6">
                                        <h4 className="text-center">Mr. Balakrishna A </h4>
                                        <h5 className="text-center">(Managing Director)</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7" id="col_p_md">
                            <p className="text-justify">
                                Balakrishna is an engineering graduate with over 22 years of work experience, spanning the United States, United Kingdom and India. He is a strong entrepreneur with a wide array of disciplines including direct customer handling, Software Architecture, Financial Planning, Strategy, Conceptualization. Currently he is focusing on Resort Construction, public relations and end-to-end multi stakeholder management. Having spent long years in the USA and UK, returned to India with a vision to build a chain of world- class resorts around heritage sites and untapped Natural places of India. He is an avid traveler who has covered the length and breadth of India, and has vast knowledge and fundamentals of Heritage Resorts and Sustainable Tourism.
                            </p>
                        </div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-7" id="col_p_coo">
                            <p className="text-justify">
                                Ravi holds a Masters degree in Tourism Management. He has held General Manager role in 4 Luxury hotels. He has over 20 years of hospitality experience with strong entrepreneurial skills, and a proven track record of success in achieving budgets, generating profits and upholding exemplary service levels for upscale and luxury Hotels like Burj Al Arab - Dubai, Hilton Qasr Al Sharq - Saudi Arabia and Albustan Palace - Muscat. He has worked with diverse organizations like Sheraton, Hilton, Intercontinental, Jumeirah, Carlson, and ITC in India and abroad.
                            </p>
                        </div>
                        <div className="col-md-5 text-center" id="col_img_coo">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <img className="img_aboutUsPage " id="img_coo" src={"assets/media/aboutUs_images/tapovana_team_coo.png"} />
                                    </div>
                                    <div className="col-lg-6 p-0">
                                        <h4 className="text-center">Mr. Ravishankar.B</h4>
                                        <h5>(Senior Advisory Board Member)</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            <section className="aboutUsPage_celebratingHeritage section_heading">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading" >
                            <h1 className="text-cener script_font">Celebrating Heritage</h1>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <img className="img_aboutUsPage aboutUsPage_banner" src={"assets/media/aboutUs_images/aboutUsPage_celebratingHeritage.png"} />
                            <p className="text-justify pt-3">
                                India takes pride in celebrating her unsurpassed heritage and culture that dates back to several thousand years. Distinctive edifices, perennial culture and the determination to keep this incredibility have preserved for us an era no short of marvels. We, at Tapovana Heritage Resorts, hope to continue in this tradition and preserve our rich heritage for decades to come, and help you reap eternal returns on your investment while doing so. An integral part of doing this is by empowering the local communities. And we do so by –
                            </p>

                            <div className="row">
                                <div className="col-md-3">
                                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                                        <img src={"assets/media/aboutUs_images/aboutus_1_1.png"} alt="aboutus_1_1" data-aos="zoom-in" className="aboutUs_icon size-full aligncenter" />
                                    </p>
                                    <p style={{ textAlign: "center" }}>
                                        Hiring employees from the local communities
                                    </p>
                                </div>
                                <div className="col-md-3">
                                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                                        <img src={"assets/media/aboutUs_images/aboutus_1_2.png"} alt="aboutus_1_1" data-aos="zoom-in" className="aboutUs_icon size-full aligncenter" />
                                    </p>
                                    <p style={{ textAlign: "center" }}>
                                        Food sourced from local organic farms
                                    </p>
                                </div>
                                <div className="col-md-3">
                                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                                        <img src={"assets/media/aboutUs_images/aboutus_1_3.png"} alt="aboutus_1_1" data-aos="zoom-in" className="aboutUs_icon size-full aligncenter" />
                                    </p>
                                    <p style={{ textAlign: "center" }}>
                                        Celebrating local festivals, fairs & clutures
                                    </p>
                                </div>
                                <div className="col-md-3">
                                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                                        <img src={"assets/media/aboutUs_images/aboutus_1_4.png"} alt="aboutus_1_1" data-aos="zoom-in" className="aboutUs_icon size-full aligncenter" />
                                    </p>
                                    <p style={{ textAlign: "center" }}>
                                        Employee clothing to be locally sourced
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3 ml-auto">
                                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                                        <img src={"assets/media/aboutUs_images/aboutus_1_5.png"} alt="aboutus_1_1" data-aos="zoom-in" className="aboutUs_icon size-full aligncenter" />
                                    </p>
                                    <p style={{ textAlign: "center" }}>
                                        Educate local community
                                    </p>
                                </div>
                                <div className="col-md-3">
                                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                                        <img src={"assets/media/aboutUs_images/aboutus_1_6.png"} alt="aboutus_1_1" data-aos="zoom-in" className="aboutUs_icon size-full aligncenter" />
                                    </p>
                                    <p style={{ textAlign: "center" }}>
                                        Showcasing the local atrs and crafts
                                    </p>
                                </div>
                                <div className="col-md-3 mr-auto">
                                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                                        <img src={"assets/media/aboutUs_images/aboutus_1_7.png"} alt="aboutus_1_7" data-aos="zoom-in" className="aboutUs_icon size-full aligncenter" />
                                    </p>
                                    <p style={{ textAlign: "center" }}>
                                        Having a formal CSR Program
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-1"></div>
                    </div>
                </div>
            </section>

            <section className="aboutUsPage_givingBackToPlanet bg_colour section_heading" >
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading" >
                            <h1 className="text-cener script_font">Giving back to the planet</h1>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <img className="img_aboutUsPage aboutUsPage_banner" src={"assets/media/aboutUs_images/aboutUsPage_givingBackToPlanet.png"} />
                            <p className="text-justify pt-3">
                                Just like our heritage, India is a bountiful land rich in flora and fauna. The responsibility of preserving this illustrious natural treasure is upon us. At Tapovana Resorts, we leave no stone unturned when it comes to implementing practices that are eco-sensitive. From sustainable practices to eco-conscious project development, we always aim to protect and preserve the environment and communities around us. Sustainability is one of the most important focal points to us. We have set up long-term science based targets to meet goals in terms of water management, reducing waste and energy consumption in all aspects of our business. A few things we have plannned to implement with the word go are –
                            </p>

                            <div className="row">
                                <div className="col-md-3">
                                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                                        <img src={"assets/media/aboutUs_images/aboutus_2_1.png"} alt="aboutus_2_1" data-aos="zoom-in" className="aboutUs_icon size-full aligncenter" />
                                    </p>
                                    <p style={{ textAlign: "center" }}>
                                        Effective waste management system in partnership with local bodies
                                    </p>
                                </div>
                                <div className="col-md-3">
                                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                                        <img src={"assets/media/aboutUs_images/aboutus_2_2.png"} alt="aboutus_2_1" data-aos="zoom-in" className="aboutUs_icon size-full aligncenter" />
                                    </p>
                                    <p style={{ textAlign: "center" }}>
                                        Organic waste to be distributed to local farmers for farming purposes
                                    </p>
                                </div>
                                <div className="col-md-3">
                                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                                        <img src={"assets/media/aboutUs_images/aboutus_2_3.png"} alt="aboutus_2_1" data-aos="zoom-in" className="aboutUs_icon size-full aligncenter" />
                                    </p>
                                    <p style={{ textAlign: "center" }}>
                                        Rain water harvesting and state-of-the-art water treatment systems
                                    </p>
                                </div>
                                <div className="col-md-3">
                                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                                        <img src={"assets/media/aboutUs_images/aboutus_2_4.png"} alt="aboutus_2_1" data-aos="zoom-in" className="aboutUs_icon size-full aligncenter" />
                                    </p>
                                    <p style={{ textAlign: "center" }}>
                                        Strict implementation of no plastic and leave no trace policies
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                                        <img src={"assets/media/aboutUs_images/aboutus_2_5.png"} alt="aboutus_2_1" data-aos="zoom-in" className="aboutUs_icon size-full aligncenter" />
                                    </p>
                                    <p style={{ textAlign: "center" }}>Promote the use of organic products</p>
                                </div>
                                <div className="col-md-3">
                                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                                        <img src={"assets/media/aboutUs_images/aboutus_2_6.png"} alt="aboutus_2_1" data-aos="zoom-in" className="aboutUs_icon size-full aligncenter" />
                                    </p>
                                    <p style={{ textAlign: "center" }}>
                                        Architectural design to blend nature without disturbing it
                                    </p>
                                </div>
                                <div className="col-md-3">
                                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                                        <img src={"assets/media/aboutUs_images/aboutus_2_7.png"} alt="aboutus_2_1" data-aos="zoom-in" className="aboutUs_icon size-full aligncenter" />
                                    </p>
                                    <p style={{ textAlign: "center" }}>
                                        Energy independent by using natural sources of energy
                                    </p>
                                </div>
                                <div className="col-md-3">
                                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                                        <img src={"assets/media/aboutUs_images/aboutus_2_8.png"} alt="aboutus_2_8" data-aos="zoom-in" className="aboutUs_icon size-full aligncenter" />
                                    </p>
                                    <p style={{ textAlign: "center" }}>
                                        Energy efficient lighting and appliances
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-1"></div>
                    </div>
                </div>
            </section>

            <WebsiteFooter />
        </>
    )
}

export default AboutUs
