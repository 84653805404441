import React, { useEffect } from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';

import { Card } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';


const DiningRoomservice = () => {
    var navigate = useNavigate();

    
    useEffect(() => {
        window.scrollTo(0, 0)
        let height_arr = [];

        let cardBody_diningSubPage = document.getElementsByClassName("cardBody_diningSubPage");

        for (let i = 0; i < cardBody_diningSubPage.length; i++) {
            cardBody_diningSubPage[i].style.display = "block";
            let height_data = cardBody_diningSubPage[i].clientHeight;
            height_arr.push(parseInt(height_data));
        }
        var max_height = (Math.max.apply(Math, height_arr));

        for (let i = 0; i < cardBody_diningSubPage.length; i++) {
            cardBody_diningSubPage[i].style.height = (max_height + 20) + "px";
        }

    }, [])

    return (
        <>

            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>
            <section className="diningRoomservicePage_overview section_heading">

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Roomservice Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                If you are a stay-in-bed vacationer or simply wish to remedy a quick craving for a snack or beverage, you will be delighted in our efficient room service. We have a special team of kitchen and service staff dedicated to getting your orders to your room in the least amount of time. Your orders reach our centralised kitchen and are serviced on priority. We follow stringent room service protocols for hygiene, serving etiquette and guest privacy to ensure that you get the best of Tapovana hospitality in the comfort of your room.
                            </p>

                        </div>
                    </div>
                </div>
            </section>

            <section className="section_diningSubPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img src={"assets/media/diningPage_roomservice.jpg"} className="diningRoomservicePage_banner subPage_banner" alt="Dining Roomservice Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_features section_subHeading" id="section_features">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            <h2 className="text-cener script_font">Other Dinings</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-lg-4 mx-auto div_card_diningSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_diningSubPage">
                                <Card.Img className="img_card_diningSubPage" variant="top" src={"assets/media/diningPage_restaurant.jpg"} />
                                <Card.Body className="cardBody_diningSubPage">
                                    <Card.Title>Restaurant</Card.Title>
                                    <Card.Text className="text-justify">
                                        The restaurants present at Tapovana are a one-stop for all your food needs. We serve all different kinds of cuisines. We provide services from fine dining to casual dining. Our guests can have the experience of all the different types of dining. We have other restaurants that even include contemporary, fast food, appetisers ambience-style.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_diningSubPage" value="Show More" onClick={() => { navigate("/diningRestaurant") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-4 mx-auto div_card_diningSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_diningSubPage">
                                <Card.Img className="img_card_diningSubPage" variant="top" src={"assets/media/diningPage_poolside.jpg"} />
                                <Card.Body className="cardBody_diningSubPage">
                                    <Card.Title>Pool Side</Card.Title>
                                    <Card.Text className="text-justify">
                                        During a hot day, all we need is a good dip in the pool and relax. So, Tapovana has made sure of that interest of our guests. Because of this interest of our guests, we have the facility of dining near the pool. This dining facility is again available for all our guests.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_diningSubPage" value="Show More" onClick={() => { navigate("/diningPoolside") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-4 mx-auto div_card_diningSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_diningSubPage">
                                <Card.Img className="img_card_diningSubPage" variant="top" src={"assets/media/diningPage_moonlight.jpg"} />
                                <Card.Body className="cardBody_diningSubPage">
                                    <Card.Title>Moon Light</Card.Title>
                                    <Card.Text className="text-justify">
                                        Want a romantic date with your partner? Don’t worry! Tapovana is here for your rescue. Just tell us the time and location of the resort or the nearby areas. We will make sure you get it. Nothing pleases one’s partner when the other half takes care of them by taking them out on romantic dates.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_diningSubPage" value="Show More" onClick={() => { navigate("/diningMoonlight") }} />
                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                </div>
            </section>


            <WebsiteFooter />

        </>
    )
}

export default DiningRoomservice
