import React, { useContext, useEffect, useState } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import * as FiIcons from "react-icons/fi";
import * as FaIcons from "react-icons/fa";
import { ContextData } from '../Components/ContextData';
const WebsiteNavbar = () => {
    const context_data = useContext(ContextData)
    const navigate = useNavigate();

    const [cottagesShow, setCottagesShow] = useState(false);
    const showCottagesDropdown = (e) => {
        setCottagesShow(!cottagesShow);
    }
    const hideCottagesDropdown = e => {
        setCottagesShow(false);
    }

    const [facilitiesShow, setFacilitiesShow] = useState(false);
    const showFacilitiesDropdown = (e) => {
        setFacilitiesShow(!facilitiesShow);
    }
    const hideFacilitiesDropdown = e => {
        setFacilitiesShow(false);
    }

    const [packagesShow, setPackagesShow] = useState(false);
    const showPackagesDropdown = (e) => {
        setPackagesShow(!packagesShow);
    }
    const hidePackagesDropdown = e => {
        setPackagesShow(false);
    }

    const [eventsShow, setEventsShow] = useState(false);
    const showEventsDropdown = (e) => {
        setEventsShow(!eventsShow);
    }
    const hideEventsDropdown = e => {
        setEventsShow(false);
    }

    const [banquetsShow, setBanquetsShow] = useState(false);
    const showBanquetsDropdown = (e) => {
        setBanquetsShow(!banquetsShow);
    }
    const hideBanquetsDropdown = e => {
        setBanquetsShow(false);
    }

    const [destinationShow, setDestinationShow] = useState(false);
    const showDestinationDropdown = (e) => {
        setDestinationShow(!destinationShow);
    }
    const hideDestinationDropdown = e => {
        setDestinationShow(false);
    }

    const [navClickData, setNavClickData] = useState(true);

    const [navClassName, setNavClassName] = useState("WebsiteNavbar")

    useEffect(() => {
        const checkPosition = () => {
            if (window.matchMedia('(min-width: 992px)').matches) {
                document.getElementById("btn_sticky_call").style.display = "none";
            } else {
                document.getElementById("btn_sticky_call").style.display = "block";
            }
        }

        checkPosition();
    }, [])

    const OpenInNewTab = (Type, data) => {
        if (Type == "Link") {
            const newWindow = window.open(data, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }

    }


    return (
        <div>
            <section className="section_navbar" >
                <Navbar collapseOnSelect expand="lg" className={navClassName} id={'navbar_colorChange'} fixed="top">
                    <Navbar.Brand to="#home"><img className="img_logo ms-5" src={"assets/media/tapovana_logo_white.png"} onClick={() => { navigate("/") }} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mx-auto">

                            <Nav.Link className="WebsiteNavLink" id={(window.location.pathname == "/") ? "navlink_active" : ""} href="#" onClick={() => { setNavClickData(!navClickData); navigate("/") }} >Home</Nav.Link>

                            <NavDropdown title="Rooms" style={{ color: "#fff" }} className={((window.location.pathname == "/rooms") || (window.location.pathname == "/roomHilltopCottage") || (window.location.pathname == "/roomHeritageThottimane") || (window.location.pathname == "/roomValleyViewVilla") || (window.location.pathname == "/roomLuxuryVilla") || (window.location.pathname == "/roomPrivatePoolVilla") ? "WebsiteNavLink navlink_active" : "WebsiteNavLink")} id="collasible-nav-dropdown"
                                show={cottagesShow} bg="dark"
                                onMouseEnter={showCottagesDropdown}
                                onMouseLeave={hideCottagesDropdown}
                                onClick={() => setCottagesShow(!cottagesShow)}>

                                <NavDropdown.Item id={((window.location.pathname == "/roomHilltopCottage")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/roomHilltopCottage") }} href="#">Hilltop Cottage</NavDropdown.Item>

                                <NavDropdown.Item id={((window.location.pathname == "/roomHeritageThottimane")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/roomHeritageThottimane") }} href="#">Heritage Thottimane</NavDropdown.Item>

                                <NavDropdown.Item id={((window.location.pathname == "/roomValleyViewVilla")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/roomValleyViewVilla") }} href="#">Valley View Villa</NavDropdown.Item>

                                <NavDropdown.Item id={((window.location.pathname == "/roomLuxuryVilla")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/roomLuxuryVilla") }} href="#">Luxury Villa</NavDropdown.Item>

                                <NavDropdown.Item id={((window.location.pathname == "/roomPrivatePoolVilla")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/roomPrivatePoolVilla") }} href="#">Private Pool Villa</NavDropdown.Item>

                            </NavDropdown>

                            <NavDropdown title="Facilities" style={{ color: "#fff" }} className={((window.location.pathname == "/facilities") || (window.location.pathname == "/facilitiesSpa") || (window.location.pathname == "/facilitiesYoga") || (window.location.pathname == "/facilitiesInfinitypool") || (window.location.pathname == "/facilitiesOthers") || (window.location.pathname == "/facilities") || (window.location.pathname == "/facilitiesSpa") || (window.location.pathname == "/facilitiesYoga") || (window.location.pathname == "/facilitiesInfinitypool") || (window.location.pathname == "/facilitiesOthers") || (window.location.pathname == "/dining") || (window.location.pathname == "/diningRestaurant") || (window.location.pathname == "/diningRoomservice") || (window.location.pathname == "/diningPoolside") || (window.location.pathname == "/diningMoonlight") || (window.location.pathname == "/recreation") || (window.location.pathname == "/recreationTrekking") || (window.location.pathname == "/recreationBirdwatching") || (window.location.pathname == "/recreationPlantationwalk") || (window.location.pathname == "/recreationVillagevisit") || (window.location.pathname == "/recreationMountainbiking") || (window.location.pathname == "/recreationIndoorgames")) ? "WebsiteNavLink navlink_active" : "WebsiteNavLink"} id="collasible-nav-dropdown"
                                show={facilitiesShow} bg="dark"
                                onMouseEnter={showFacilitiesDropdown}
                                onMouseLeave={hideFacilitiesDropdown}
                                onClick={() => setFacilitiesShow(!facilitiesShow)}>

                                <NavDropdown.Item id={((window.location.pathname == "/recreation") || (window.location.pathname == "/recreationTrekking") || (window.location.pathname == "/recreationBirdwatching") || (window.location.pathname == "/recreationPlantationwalk") || (window.location.pathname == "/recreationVillagevisit") || (window.location.pathname == "/recreationMountainbiking") || (window.location.pathname == "/recreationIndoorgames")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/recreation") }} href="#" >Recreation</NavDropdown.Item>

                                <NavDropdown.Item id={((window.location.pathname == "/dining") || (window.location.pathname == "/diningRestaurant") || (window.location.pathname == "/diningRoomservice") || (window.location.pathname == "/diningPoolside") || (window.location.pathname == "/diningMoonlight")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/dining") }} href="#">Dining</NavDropdown.Item>

                                <NavDropdown.Item id={((window.location.pathname == "/facilitiesSpa")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/facilitiesSpa") }} href="#">SPA</NavDropdown.Item>

                                <NavDropdown.Item id={((window.location.pathname == "/facilitiesYoga")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/facilitiesYoga") }} href="#">Yoga</NavDropdown.Item>

                                <NavDropdown.Item id={((window.location.pathname == "/facilitiesInfinitypool")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/facilitiesInfinitypool") }} href="#">Infinity Pool</NavDropdown.Item>

                            </NavDropdown>

                            <NavDropdown title="Banquets" style={{ color: "#fff" }} className={((window.location.pathname == "/banquet") || (window.location.pathname == "/banquetHall") || (window.location.pathname == "/banquetBallRoom") || (window.location.pathname == "/banquetBoardroom") || (window.location.pathname == "/banquetOutdoor") || (window.location.pathname == "/banquetGreenlawn") || (window.location.pathname == "/eventsWedding") || (window.location.pathname == "/eventsMeetings") || (window.location.pathname == "/eventsParties") || (window.location.pathname == "/eventsPreweddingPhotoshoot") || (window.location.pathname == "/eventsPostweddingPhotoshoot")) ? "WebsiteNavLink navlink_active" : "WebsiteNavLink"}
                                id="collasible-nav-dropdown"
                                show={banquetsShow} bg="dark"
                                onMouseEnter={showBanquetsDropdown}
                                onMouseLeave={hideBanquetsDropdown}
                                onClick={() => setBanquetsShow(!banquetsShow)}>

                                <NavDropdown.Item id={((window.location.pathname == "/banquetBallRoom")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/banquetBallRoom") }} href="#">Ball Room</NavDropdown.Item>

                                <NavDropdown.Item id={((window.location.pathname == "/banquetBoardroom")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/banquetBoardroom") }} href="#">Board Room</NavDropdown.Item>

                                <NavDropdown.Item id={((window.location.pathname == "/banquetOutdoor")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/banquetOutdoor") }} href="#">Outdoor Catering</NavDropdown.Item>

                                <NavDropdown.Item id={((window.location.pathname == "/banquetGreenlawn")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/banquetGreenlawn") }} href="#">Greenlawn Banquet</NavDropdown.Item>

                                <NavDropdown title="Destination Events" className={((window.location.pathname == "/eventsWedding") || (window.location.pathname == "/eventsAnniversary") || (window.location.pathname == "/eventsBirthday") || (window.location.pathname == "/eventsPreweddingPhotoshoot")) ? "navdropdown_active" : ""}
                                    drop="end"
                                    id="subDropDown"
                                    show={destinationShow}
                                    onMouseEnter={showDestinationDropdown}
                                    onMouseLeave={hideDestinationDropdown}
                                    onClick={() => setDestinationShow(!destinationShow)}>

                                    <NavDropdown.Item id={((window.location.pathname == "/eventsWedding")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/eventsWedding") }} href="#">Wedding</NavDropdown.Item>

                                    <NavDropdown.Item id={((window.location.pathname == "/eventsMeetings")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/eventsMeetings") }} href="#">Meetings / Conference</NavDropdown.Item>

                                    <NavDropdown.Item id={((window.location.pathname == "/eventsParties")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/eventsParties") }} href="#">Parties</NavDropdown.Item>

                                    <NavDropdown.Item id={((window.location.pathname == "/eventsPreweddingPhotoshoot")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/eventsPreweddingPhotoshoot") }} href="#">Prewedding Photoshoot</NavDropdown.Item>

                                    <NavDropdown.Item id={((window.location.pathname == "/eventsPostweddingPhotoshoot")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/eventsPostweddingPhotoshoot") }} href="#">Postwedding Photoshoot</NavDropdown.Item>

                                </NavDropdown>
                            </NavDropdown>


                            <NavDropdown title="Packages" style={{ color: "#fff" }} className={((window.location.pathname == "/packages") || (window.location.pathname == "/packagesStandard") || (window.location.pathname == "/packagesWellness") || (window.location.pathname == "/packagesHoneymoon") || (window.location.pathname == "/packagesKids") || (window.location.pathname == "/packagesHeritage") || (window.location.pathname == "/packagesSpiritual") || (window.location.pathname == "/packagesCorporate") || (window.location.pathname == "/packagesWedding")) ? "WebsiteNavLink navlink_active" : "WebsiteNavLink"} id="collasible-nav-dropdown"
                                show={packagesShow} bg="dark"
                                onMouseEnter={showPackagesDropdown}
                                onMouseLeave={hidePackagesDropdown}
                                onClick={() => setPackagesShow(!packagesShow)}>

                                <NavDropdown.Item id={((window.location.pathname == "/packagesHoneymoon")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/packagesHoneymoon") }} href="#">Honeymoon</NavDropdown.Item>

                                <NavDropdown.Item id={((window.location.pathname == "/packagesSpiritual")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/packagesSpiritual") }} href="#">Spiritual</NavDropdown.Item>

                                <NavDropdown.Item id={((window.location.pathname == "/packagesWellness")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/packagesWellness") }} href="#">Wellness</NavDropdown.Item>

                                <NavDropdown.Item id={((window.location.pathname == "/packagesHeritage")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/packagesHeritage") }} href="#">Heritage</NavDropdown.Item>

                                <NavDropdown.Item id={((window.location.pathname == "/PackagesAdventure")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/PackagesAdventure") }} href="#">Adventure</NavDropdown.Item>

                                <NavDropdown.Item id={((window.location.pathname == "/packagesNewYear")) ? "navdropdown_active" : ""} onClick={() => { setNavClickData(!navClickData); navigate("/packagesNewYear") }} href="#" >New Year</NavDropdown.Item>

                            </NavDropdown>

                            <Nav.Link className="WebsiteNavLink" id={((window.location.pathname == "/contact")) ? "navlink_active" : ""} onClick={() => { navigate("/contact") }} href="#" >Contact Us</Nav.Link>

                            <Nav.Link className="WebsiteNavLink" id={((window.location.pathname == "/careers")) ? "navlink_active" : ""} onClick={() => { navigate("/careers") }} href="#" >Careers</Nav.Link>

                            <Nav.Link onClick={() => OpenInNewTab("Link", "tel:+91-9686659966")} className="WebsiteNavLink">+91 96866 59966</Nav.Link>

                        </Nav>

                    </Navbar.Collapse>
                </Navbar>
            </section>

            <section className="section_sticky_btn">
                <input type="button" value="BOOK NOW !" className="btn btn_sticky_bookNow" id="btn_sticky_bookNow" onClick={() => OpenInNewTab("Link", "https://booking.tapovanaresorts.com")} />

                <button className="btn btn_sticky_call" id="btn_sticky_call" onClick={() => OpenInNewTab("Link", "tel:+91-9686659966")}><FiIcons.FiPhone /></button>
                <button className="btn btn_sticky_whatsapp" onClick={() => OpenInNewTab("Link", "https://api.whatsapp.com/send?phone=+91-9686659966&text=Hi%20I%20am%20interested%20in%20Tapovana%20Resorts%20and%20Spa%20Please%20call%20back%20and%20share%20the%20details.")}><FaIcons.FaWhatsapp /></button>


            </section>


        </div >
    )
}

export default WebsiteNavbar
