import React, { useContext, useEffect, useState } from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';
import { useNavigate } from 'react-router-dom';


import RoomFacilities from '../Components/RoomFacilities';
import Axios from "axios";
import { ContextData } from '../Components/ContextData';
var moment = require("moment")

const RoomValleyViewVilla = () => {
    const context_data = useContext(ContextData)
    var navigate = useNavigate();
    

    useEffect(() => {
        context_data.setType("room")
        context_data.setRoom("valley_view_villa")
        context_data.setPackageName(false)

        window.scrollTo(0, 0)
        let height_arr = [];

        let cardBody_roomSubPage = document.getElementsByClassName("cardBody_roomSubPage");

        for (let i = 0; i < cardBody_roomSubPage.length; i++) {
            cardBody_roomSubPage[i].style.display = "block";
            let height_data = cardBody_roomSubPage[i].clientHeight;
            height_arr.push(parseInt(height_data));
        }
        var max_height = (Math.max.apply(Math, height_arr));

        for (let i = 0; i < cardBody_roomSubPage.length; i++) {
            cardBody_roomSubPage[i].style.height = (max_height + 20) + "px";
        }
        getTariffData();

    }, [])

    const [tariffData, setTariffData] = useState(null);


    const getTariffData = async () => {
        var getTariffsData = {
            type: "room",
            room: "valley_view_villa",
            check_in_day: moment(new Date()).format("D"),
            check_in_month: moment(new Date()).format("M"),
        };

        
        const getTariffsUrl = global.config.server_url + "getRoomDetails";
        Axios.post(getTariffsUrl, getTariffsData).then((tariffResponse) => {
            try {  setTariffData(tariffResponse.data[0]); }
            catch { console.log("error"); }
        }).catch(err => console.log(err));
    }

    function showDiscountAmount(tariff, amounts) {
        var weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var weekday = weekdays[new Date().getDay()];

        var base_discount_perc = (tariff.base_discount_perc) ? parseFloat(tariff.base_discount_perc) : 0;

        var monday_discount_perc = (tariff.monday_discount_perc) ? parseFloat(tariff.monday_discount_perc) : 0;
        var tuesday_discount_perc = (tariff.tuesday_discount_perc) ? parseFloat(tariff.tuesday_discount_perc) : 0;
        var wednesday_discount_perc = (tariff.wednesday_discount_perc) ? parseFloat(tariff.wednesday_discount_perc) : 0;
        var thursday_discount_perc = (tariff.thursday_discount_perc) ? parseFloat(tariff.thursday_discount_perc) : 0;
        var friday_discount_perc = (tariff.friday_discount_perc) ? parseFloat(tariff.friday_discount_perc) : 0;
        var saturday_discount_perc = (tariff.saturday_discount_perc) ? parseFloat(tariff.saturday_discount_perc) : 0;
        var sunday_discount_perc = (tariff.sunday_discount_perc) ? parseFloat(tariff.sunday_discount_perc) : 0;

        var day_discount_perc = (tariff.day_discount_perc) ? parseFloat(tariff.day_discount_perc) : 0;

        var room_discount_amount = 0;

        if (day_discount_perc) {
            room_discount_amount = parseFloat(amounts) * parseFloat(day_discount_perc) / 100;
        }
        else if ((weekday.toLowerCase() == "monday" && monday_discount_perc) || (weekday.toLowerCase() == "tuesday" && tuesday_discount_perc) || (weekday.toLowerCase() == "wednesday" && wednesday_discount_perc) || (weekday.toLowerCase() == "thursday" && thursday_discount_perc) || (weekday.toLowerCase() == "friday" && friday_discount_perc) || (weekday.toLowerCase() == "saturday" && saturday_discount_perc) || (weekday.toLowerCase() == "sunday" && sunday_discount_perc)) {
            switch (weekday.toLowerCase()) {
                case "monday":
                    room_discount_amount = parseFloat(amounts) * parseFloat(monday_discount_perc) / 100;
                    break;
                case "tuesday":
                    room_discount_amount = parseFloat(amounts) * parseFloat(tuesday_discount_perc) / 100;
                    break;
                case "wednesday":
                    room_discount_amount = parseFloat(amounts) * parseFloat(wednesday_discount_perc) / 100;
                    break;
                case "thursday":
                    room_discount_amount = parseFloat(amounts) * parseFloat(thursday_discount_perc) / 100;
                    break;
                case "friday":
                    room_discount_amount = parseFloat(amounts) * parseFloat(friday_discount_perc) / 100;
                    break;
                case "saturday":
                    room_discount_amount = parseFloat(amounts) * parseFloat(saturday_discount_perc) / 100;
                    break;
                case "sunday":
                    room_discount_amount = parseFloat(amounts) * parseFloat(sunday_discount_perc) / 100;
                    break;
            }
        }
        else if (base_discount_perc) {
            room_discount_amount = parseFloat(amounts) * parseFloat(base_discount_perc) / 100;
        }

        var amount_after_discount = amounts + room_discount_amount
        if (amount_after_discount == amounts) {
            return <span>{indianNumberFormat(amount_after_discount)}/- + GST</span>
        } else if (amount_after_discount < amounts) {
            return <><div><span style={{ textDecoration: "line-through" }}>{indianNumberFormat(amounts)}</span></div><div><span><b>{indianNumberFormat(amount_after_discount)}/- + GST</b></span></div></>
        } else if (amount_after_discount > amounts) {
            return <span>{indianNumberFormat(amount_after_discount)}/- + GST</span>
        }
    }

    function indianNumberFormat(x) {
        if (x) {
            var number = parseFloat(x)
            return number.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: "currency", currency: "INR"
            });
        } else {
            return x
        }
    }

    const handleBookBtn = (roomName) => {
        var check_in = context_data.checkIn
        var check_out = moment(check_in).add(1, "days").format("yyyy-MM-DD")
        context_data.setMinCheckOut(check_out)
        context_data.setCheckIn(check_in)
        context_data.setCheckOut(check_out)

        context_data.setDatesModalShow(true)
    }

    return (
        <>
            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>
            <section className="roomValleyviewPage_overview section_heading">

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Valley View Villa Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                Experience the opulence of nature at our Valley View Villas. These Villas afford the best views of the rolling hills, the lush green valley, the sparkling waterfalls, and the aromatic coffee plantations. On a clear day, you would be blessed with a spectacular sunrise and a mesmerising sunset. Take in this magnificent view while enjoying our world-class service and delicious food preparations. Relish steaming cups of coffee over soulful conversations with your loved ones in the privacy of these thoughtfully designed villas while enjoying our quick and efficient service.
                            </p>
                            <p className="text-justify">
                                Like other staying options, the Valley View Villas are also reminiscent of the local heritage and are designed in the traditional architectural style with a warm and endearing ambience accentuated by an ornate swing and beautiful plants all around the place.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_roomSubPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0 ">
                            <img src={"assets/media/roomPage_valley_view_villa.jpg"} className="roomValleyviewPage_banner subPage_banner" alt="Room Valleyview Villa Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <RoomFacilities />

            {(tariffData != null && tariffData != false) ? (
                <section className="section_tariff">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center div_subHeading">
                                <h2 className="text-cener script_font">Valley View Villa Tariff</h2>
                                <h5 className="text-cener script_font tariff_line">Tariff includes Buffet Breakfast, Lunch & Dinner</h5>
                            </div>

                            <div className="col-lg-3"></div>
                            <div className="col-lg-6 ">
                                <table className="table border border-dark" >
                                    <thead className="table-dark">
                                        <tr>
                                            <th>Guests</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {(tariffData.one_adult) ? (
                                            <tr>
                                                <td>Single</td>
                                                <td>{showDiscountAmount(tariffData, tariffData.one_adult)}</td>
                                            </tr>
                                        ) : ""}

                                        {(tariffData.two_adult) ? (
                                            <tr>
                                                <td>Double</td>
                                                <td>{showDiscountAmount(tariffData, tariffData.two_adult)}</td>
                                            </tr>
                                        ) : ""}

                                        {(tariffData.children) ? (
                                            <tr>
                                                <td>Children (6 to 12 Years)</td>
                                                <td>{showDiscountAmount(tariffData, tariffData.children)}</td>
                                            </tr>
                                        ) : ""}

                                        {(tariffData.extra_person) ? (
                                            <tr>
                                                <td>Extra Person</td>
                                                <td>{showDiscountAmount(tariffData, tariffData.extra_person)}</td>
                                            </tr>
                                        ) : ""}

                                        <tr>
                                            <td colSpan="2">
                                                <input onClick={() => handleBookBtn()} type="button" value="Book Now" className="btn btn_book btn_header" />
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-3"></div>

                        </div>
                    </div>
                </section>
            ) : ""}

            <WebsiteFooter />

        </>
    )
}

export default RoomValleyViewVilla
