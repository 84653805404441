import React, { useEffect } from 'react'
import * as BsIcons from "react-icons/bs";

const BanquetFacilities = () => {

    useEffect(() => {
        window.scrollTo(0, 0);

    }, [])

    return (
        <>
            <section className="section_room_facilities" id="section_room_facilities">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            <h2 className="text-cener script_font">Banquet Facilities</h2>
                        </div>
                        <div className="col-lg-2"></div>
                        {(window.location.pathname == "/banquetOutdoor") ? (
                            <>
                                <div className="col-md-6 col-lg-4">
                                    <div className="">
                                        <ul className="cottages_facilities_ul">
                                            <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Decoration by professionals</li>
                                            <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Elegant table and chair decorations</li>
                                            <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Modern Audio Visual equipment</li>
                                            <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Flower arrangements</li>
                                            <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Bottled water</li>
                                            <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Photo / Videographer</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <ul className="cottages_facilities_ul">
                                        <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Theme dinners</li>
                                        <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Customised menu</li>
                                        <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Variety of food stalls</li>
                                        <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Live food counters</li>
                                        <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Pure vegetarian & Non vegetarian menu selection</li>
                                        <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> High quality service</li>
                                    </ul>
                                </div >
                            </>
                        ) :
                            ((window.location.pathname == "/banquetGreenlawn") ?
                                (
                                    <>
                                        <div className="col-md-6 col-lg-4">
                                            <div className="">
                                                <ul className="cottages_facilities_ul">
                                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Decoration by professionals</li>
                                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Elegant table and chair decorations</li>
                                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Modern Audio Visual equipment</li>
                                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Flower arrangements</li>
                                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Bottled water</li>
                                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Photo / Videographer</li>
                                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Barbeque</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-6">
                                            <ul className="cottages_facilities_ul">
                                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Theme dinners</li>
                                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Customised menu</li>
                                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Variety of food stalls</li>
                                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Live food counters</li>
                                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Pure vegetarian & Non vegetarian menu selection</li>
                                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> High quality service</li>
                                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Bonfire (provided depends on weather conditions)</li>
                                            </ul>
                                        </div >
                                    </>
                                )
                                : (

                                    <>
                                        <div className="col-md-6 col-lg-4">
                                            <div className="">
                                                <ul className="cottages_facilities_ul">
                                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Air Conditioned</li>
                                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Bottled water</li>
                                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Modern Audio Visual equipment</li>
                                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Access to green lawn</li>
                                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Stationeries on request</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-6">
                                            <ul className="cottages_facilities_ul">
                                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Adjustable chairs & tables</li>
                                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Wi-Fi on request (charges applicable)</li>
                                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Traditional tile floors</li>
                                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Pure vegetarian & Non vegetarian menu selection</li>
                                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Drop down screen and Overhead LCD Projector</li>
                                            </ul>
                                        </div>
                                    </>
                                )
                            )
                        }

                    </div >
                </div >
            </section >
        </>
    )
}

export default BanquetFacilities
