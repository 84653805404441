import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';

import './Components/Config';

import WebsiteNavbar from './Components/WebsiteNavbar';
import Home from "./Home";
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import Room from './Rooms/Room';
import RoomLuxuryVilla from './Rooms/RoomLuxuryVilla';
import RoomHilltopCottage from './Rooms/RoomHilltopCottage';
import RoomHeritageThottimane from './Rooms/RoomHeritageThottimane';
import RoomValleyViewVilla from './Rooms/RoomValleyViewVilla';
import RoomPrivatePoolVilla from './Rooms/RoomPrivatePoolVilla';

import Facilities from './Facilities/Facilities';
import FacilitiesYoga from './Facilities/FacilitiesYoga';
import FacilitiesSpa from './Facilities/FacilitiesSpa';
import FacilitiesInfinitypool from './Facilities/FacilitiesInfinitypool';

import Recreation from './Recreations/Recreation';
import RecreationTrekking from './Recreations/RecreationTrekking';
import RecreationBirdwatching from './Recreations/RecreationBirdwatching';
import RecreationPlantationwalk from './Recreations/RecreationPlantationwalk';
import RecreationVillagevisit from './Recreations/RecreationVillagevisit';
import RecreationMountainbiking from './Recreations/RecreationMountainbiking';
import RecreationIndoorgames from './Recreations/RecreationIndoorgames';

import Dining from './Dinings/Dining';
import DiningRestaurant from './Dinings/DiningRestaurant';
import DiningRoomservice from './Dinings/DiningRoomservice';
import DiningPoolside from './Dinings/DiningPoolside';
import DiningMoonlight from './Dinings/DiningMoonlight';

import Banquet from './Banquets/Banquet';
import BanquetHall from './Banquets/BanquetHall';
import BanquetBallroom from './Banquets/BanquetBallroom';
import BanquetBoardroom from './Banquets/BanquetBoardroom';
import BanquetOutdoor from './Banquets/BanquetOutdoor';
import BanquetGreenlawn from './Banquets/BanquetGreenlawn';

import EventsWedding from './Events/EventsWedding';
import EventsMeetings from './Events/EventsMeetings';
import EventsParties from './Events/EventsParties';
import EventsPreWeddingPhotoShoot from './Events/EventsPreWeddingPhotoShoot';
import EventsPostWeddingPhotoshoot from './Events/EventsPostWeddingPhotoShoot';

import Package from './Packages/Package';
import PackagesAdventure from './Packages/PackagesAdventure';
import PackagesHeritage from './Packages/PackagesHeritage';
import PackagesHoneymoon from './Packages/PackagesHoneymoon';
import PackagesNewYear from './Packages/PackagesNewYear';
import PackagesSpiritual from './Packages/PackagesSpiritual';
import PackagesWellness from './Packages/PackagesWellness';

import Contact from './Contact';
import Careers from './Careers';
import AboutUs from './AboutUs';
import Policy from './Policy';

import moment from 'moment';
import { ContextData } from './Components/ContextData';
import Aos from 'aos';

const App = () => {
	const [modalShow, setModalShow] = useState(false);
	const [processing, setProcessing] = useState(false)
	const [thankyouModal, setThankyouModal] = useState(false)
	const [datesModalShow, setDatesModalShow] = useState(false)

	const [checkIn, setCheckIn] = useState("2022-08-10")
	const [minCheckIn, setMinCheckIn] = useState("2022-08-10")
	const [maxCheckIn, setMaxCheckIn] = useState("")
	const [checkOut, setCheckOut] = useState("")
	const [minCheckOut, setMinCheckOut] = useState("")
	const [maxCheckOut, setMaxCheckOut] = useState("")

	const [type, setType] = useState("")
	const [room, setRoom] = useState("")
	const [packageName, setPackageName] = useState("")

	var location = useLocation();

	useEffect(() => {
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
		window.scrollTo(0, 0)
		Aos.init()
	}, [location])

	useEffect(() => {
		setDates();
	}, [])

	const submitEnquiry = (e) => {
		e.preventDefault();

		setProcessing(true)
		let name = e.target.name.value;
		let mobile = (formMobile) ? formMobile : e.target.mobile.value;
		let email = e.target.email.value;

		const addEnquiryUrl = global.config.server_url + "addEnquiry";
		const mobileRegex = new RegExp("[+][0-9]{2}[1-9]{1}[0-9]{9}");

		const addEnquiryData = {
			name: name,
			mobile: mobile,
			email: email
		};

		if (mobileRegex.test(mobile)) {
			axios.post(addEnquiryUrl, addEnquiryData).then((addEnquiryResponse) => {
				if (addEnquiryResponse.data === true) {
					e.target.name.value = "";
					e.target.mobile.value = "";
					e.target.email.value = "";
					setModalShow(false)
					setProcessing(false)
					setThankyouModal(true)
					setTimeout(() => {
						setThankyouModal(false)
					}, 3000);
				} else {
					alert("Error in Submitting Application!");
					setModalShow(false)
					setProcessing(false)
				}
			}).catch(err => { console.log(err); alert("Error in Submitting Application"); setProcessing(false) });
		} else {
			alert("Invalid Mobile Number")
			setProcessing(false)
		}

	}

	const [formMobile, setFormMobile] = useState()

	const setDates = () => {
		setMinCheckIn("2022-08-10")
		setMaxCheckIn("2023-08-10")
		setMinCheckOut("2022-08-11")
		setMaxCheckOut("2023-08-11")

		setCheckIn("2022-08-10")
		setCheckOut("2022-08-11")
	}

	const setCheckInDate = (e) => {
		var check_in = e.target.value
		var check_out = moment(check_in).add(1, "days").format("yyyy-MM-DD")
		setMinCheckOut(check_out)

		setCheckIn(check_in)
		setCheckOut(check_out)
	}

	const setCheckOutDate = (e) => {
		var check_out = e.target.value
		setCheckOut(check_out)
	}

	const submitBookForm = (e) => {
		e.preventDefault();
		var check_in = e.target.check_in.value;
		var check_out = e.target.check_out.value;

		if (packageName) {
			var url = (global.config.booking_url + "?check_in=" + check_in + "&check_out=" + check_out + "&type=" + type + "&package_name=" + packageName + "&room=" + room)
			const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
			if (newWindow) newWindow.opener = null
		} else {
			var url = (global.config.booking_url + "?check_in=" + check_in + "&check_out=" + check_out + "&type=" + type + "&room=" + room)
			const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
			if (newWindow) newWindow.opener = null
		}

		setDatesModalShow(false)
	}

	const context_data = {
		setType: setType,
		setPackageName: setPackageName,
		setRoom: setRoom,
		setDatesModalShow: setDatesModalShow,
		setModalShow: setModalShow,
		setProcessing: setProcessing,
		setThankyouModal: setThankyouModal,

		minCheckIn: minCheckIn,
		setMinCheckIn: setMinCheckIn,
		maxCheckIn: maxCheckIn,
		setMaxCheckIn: setMaxCheckIn,
		minCheckOut: minCheckOut,
		setMinCheckOut: setMinCheckOut,
		maxCheckOut: maxCheckOut,
		setMaxCheckOut: setMaxCheckOut,
		checkIn: checkIn,
		setCheckIn: setCheckIn,
		checkOut: checkOut,
		setCheckOut: setCheckOut,
	}


	return (
		<ContextData.Provider value={context_data}>

			<div>

				<Modal className="modal_processing" show={processing} backdrop="static" keyboard={false} centered>
					<div className="text-center">
						<img src={"assets/media/tapovana_leaf.png"} alt="" />
					</div>
				</Modal>

				<Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={thankyouModal} keyboard={false} centered onHide={() => setThankyouModal(false)}>
					<Modal.Header className="text-center" closeButton>
						<Modal.Title id="contained-modal-title-vcenter" className="modal_title">
							<img className="img_logo " src={"assets/media/tapovana_logo_black.png"} />
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="  px-3 mx-auto">
						<div className="card">
							<div className="card-body">
								<h3 className="text-center"><b>Thank you for the Enquiry</b></h3>
								<h4 className="text-center">Our customer relationship manager will get in touch with you shortly.</h4>
							</div>
						</div>
					</Modal.Body>
				</Modal>

				<Modal
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={modalShow}
					onHide={() => setModalShow(false)}
				>
					<Modal.Header className="text-center" closeButton>
						<Modal.Title id="contained-modal-title-vcenter" className="modal_title">
							<img className="img_logo " src={"assets/media/tapovana_logo_black.png"} />
						</Modal.Title>
					</Modal.Header>

					<form onSubmit={(e) => { submitEnquiry(e) }}  >

						<Modal.Body className="  px-3 mx-auto">
							<div className="card">
								<div className="card-body">
									<h3 className="text-center"><b>We are launching on 10th August 2022</b></h3>
								</div>
							</div>
							<div className="form-group pt-3">
								<h4 className="text-center script_font">Fill your details for Enquiry</h4>
							</div>
							<div className="form-group">
								<label htmlFor="name">Name</label>
								<input type="text" required name="name" id="name" className="form-control" placeholder="Name" />
							</div>
							<div className="form-group">
								<label htmlFor="mobile">Mobile</label>
								<div className="form-control">
									<PhoneInput defaultCountry={"IN"} className="phone_input" placeholder="Enter phone number" name="mobile" value={formMobile} onChange={setFormMobile} />

								</div>
							</div>
							<div className="form-group">
								<label htmlFor="email">Email</label>
								<input type="email" required name="email" id="email" className="form-control" placeholder="Email" />
							</div>

						</Modal.Body>
						<Modal.Footer>
							<input type="submit" value="Enquire" className="btn btn_header" />
							<input type="submit" value="Close" className="btn btn-danger" onClick={() => setModalShow(false)} />
						</Modal.Footer>
					</form>
				</Modal>

				<Modal
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={datesModalShow}
					onHide={() => setDatesModalShow(false)}
				>
					<Modal.Header className="text-center" closeButton>
						<Modal.Title id="contained-modal-title-vcenter" className="modal_title">
							<img className="img_logo " src={"assets/media/tapovana_logo_black.png"} />
						</Modal.Title>
					</Modal.Header>
					<form onSubmit={submitBookForm}>
						<Modal.Body className="px-3">
							<div className="container-fluid">
								<div className="row">
									<div className="col-md-6">
										<div className="form-floating mb-3">
											<input type="date" name="check_in" className="form-control" id="floatingInput" placeholder="Check In" min={minCheckIn} max={maxCheckIn} value={checkIn} required onChange={setCheckInDate} />
											<label for="floatingInput">Check In</label>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-floating mb-3">
											<input type="date" name="check_out" className="form-control" id="floatingInput" placeholder="Check Out" min={minCheckOut} max={maxCheckOut} value={checkOut} required onChange={setCheckOutDate} />
											<label for="floatingInput">Check Out</label>
										</div>
									</div>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<input type="submit" value="Book" className="btn btn_header" />
							<input type="button" value="Close" className="btn btn-danger" onClick={() => setDatesModalShow(false)} />
						</Modal.Footer>
					</form>
				</Modal>

				<Routes>
					<Route exact path="/" element={<><WebsiteNavbar /><Home /></>} />

					<Route exact path="/room" element={<><WebsiteNavbar /><Room /></>} />
					<Route exact path="/roomHilltopCottage" element={<><WebsiteNavbar /><RoomHilltopCottage /></>} />
					<Route exact path="/roomHeritageThottimane" element={<><WebsiteNavbar /><RoomHeritageThottimane /></>} />
					<Route exact path="/roomValleyViewVilla" element={<><WebsiteNavbar /><RoomValleyViewVilla /></>} />
					<Route exact path="/roomLuxuryVilla" element={<><WebsiteNavbar /><RoomLuxuryVilla /></>} />
					<Route exact path="/roomPrivatePoolVilla" element={<><WebsiteNavbar /><RoomPrivatePoolVilla /></>} />

					<Route exact path="/facilities" element={<><WebsiteNavbar /><Facilities /></>} />
					<Route exact path="/facilitiesYoga" element={<><WebsiteNavbar /><FacilitiesYoga /></>} />
					<Route exact path="/facilitiesSpa" element={<><WebsiteNavbar /><FacilitiesSpa /></>} />
					<Route exact path="/facilitiesInfinitypool" element={<><WebsiteNavbar /><FacilitiesInfinitypool /></>} />

					<Route exact path="/recreation" element={<><WebsiteNavbar /><Recreation /></>} />
					<Route exact path="/recreationTrekking" element={<><WebsiteNavbar /><RecreationTrekking /></>} />
					<Route exact path="/recreationBirdwatching" element={<><WebsiteNavbar /><RecreationBirdwatching /></>} />
					<Route exact path="/recreationPlantationwalk" element={<><WebsiteNavbar /><RecreationPlantationwalk /></>} />
					<Route exact path="/recreationVillagevisit" element={<><WebsiteNavbar /><RecreationVillagevisit /></>} />
					<Route exact path="/recreationMountainbiking" element={<><WebsiteNavbar /><RecreationMountainbiking /></>} />
					<Route exact path="/recreationIndoorgames" element={<><WebsiteNavbar /><RecreationIndoorgames /></>} />

					<Route exact path="/dining" element={<><WebsiteNavbar /><Dining /></>} />

					<Route exact path="/dining" element={<><WebsiteNavbar /><Dining /></>} />
					<Route exact path="/diningRestaurant" element={<><WebsiteNavbar /><DiningRestaurant /></>} />
					<Route exact path="/diningRoomservice" element={<><WebsiteNavbar /><DiningRoomservice /></>} />
					<Route exact path="/diningPoolside" element={<><WebsiteNavbar /><DiningPoolside /></>} />
					<Route exact path="/diningMoonlight" element={<><WebsiteNavbar /><DiningMoonlight /></>} />

					<Route exact path="/banquet" element={<><WebsiteNavbar /><Banquet /></>} />
					<Route exact path="/banquetHall" element={<><WebsiteNavbar /><BanquetHall /></>} />
					<Route exact path="/banquetBallroom" element={<><WebsiteNavbar /><BanquetBallroom /></>} />
					<Route exact path="/banquetBoardroom" element={<><WebsiteNavbar /><BanquetBoardroom /></>} />
					<Route exact path="/banquetOutdoor" element={<><WebsiteNavbar /><BanquetOutdoor /></>} />
					<Route exact path="/banquetGreenlawn" element={<><WebsiteNavbar /><BanquetGreenlawn /></>} />

					<Route exact path="/eventsWedding" element={<><WebsiteNavbar /><EventsWedding /></>} />
					<Route exact path="/eventsMeetings" element={<><WebsiteNavbar /><EventsMeetings /></>} />
					<Route exact path="/eventsParties" element={<><WebsiteNavbar /><EventsParties /></>} />
					<Route exact path="/eventsPreWeddingPhotoShoot" element={<><WebsiteNavbar /><EventsPreWeddingPhotoShoot /></>} />
					<Route exact path="/eventsPreWeddingPhotoShoot" element={<><WebsiteNavbar /><EventsPostWeddingPhotoshoot /></>} />

					<Route exact path="/package" element={<><WebsiteNavbar /><Package /></>} />
					<Route exact path="/packagesAdventure" element={<><WebsiteNavbar /><PackagesAdventure /></>} />
					<Route exact path="/packagesHeritage" element={<><WebsiteNavbar /><PackagesHeritage /></>} />
					<Route exact path="/packagesHoneymoon" element={<><WebsiteNavbar /><PackagesHoneymoon /></>} />
					<Route exact path="/packagesNewYear" element={<><WebsiteNavbar /><PackagesNewYear /></>} />
					<Route exact path="/packagesSpiritual" element={<><WebsiteNavbar /><PackagesSpiritual /></>} />
					<Route exact path="/packagesWellness" element={<><WebsiteNavbar /><PackagesWellness /></>} />

					<Route exact path="/contact" element={<><WebsiteNavbar /><Contact /></>} />
					<Route exact path="/careers" element={<><WebsiteNavbar /><Careers /></>} />
					<Route exact path="/aboutUs" element={<><WebsiteNavbar /><AboutUs /></>} />
					<Route exact path="/policy" element={<><WebsiteNavbar /><Policy /></>} />

				</Routes>

			</div>
		</ContextData.Provider>
	)
}

export default App