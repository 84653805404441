import React, { useEffect } from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';

import { useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';

const Package = () => {
    var navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        
        let packages_height_arr = [];

        let cardBody_packagesPage = document.getElementsByClassName("cardBody_packagesPage");

        for (let i = 0; i < cardBody_packagesPage.length; i++) {
            cardBody_packagesPage[i].style.display = "block";
            let packages_height_data = cardBody_packagesPage[i].clientHeight;
            packages_height_arr.push(parseInt(packages_height_data));
        }
        var packages_max_height = (Math.max.apply(Math, packages_height_arr));

        for (let i = 0; i < cardBody_packagesPage.length; i++) {
            cardBody_packagesPage[i].style.height = (packages_max_height + 20) + "px";
        }

    }, [])


    return (
        <>
            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>

            <section className="offersPromotionsPage_overview section_heading">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Packages Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                Tapovana has the best services in the whole area. We have many world-class services. These services range from having the best restaurants to spa centres. We even provide our guests with the option of staying in villas to have their entire personal space and never think about disturbances.
                            </p>
                            <p className="text-justify">
                                All these services are of the best quality and don’t have flaws. In this way, we are a family of Tapovana, giving the best experience to our customers. We have a group of fully trained staff members and professionals who take on all the services running in the resort. And they even make sure that our guests will not face any kind of discomfort or problem.
                            </p>
                            <p className="text-justify">
                                We provide our guests with the services such as professional trekking sessions, plantation visits, camping in the coffee plantation, mountain biking, heritage site visit, village tours, etc. All this is provided by Tapovana to our guests. Our guests can even get customised gift vouchers for their loved ones.
                            </p>
                            <p className="text-justify">
                                We provide the best in resort amenities such as having a world-class spa service done by professionals, a jacuzzi, and the best restaurants. All the services provided are done by professionals who have many years experience so that our guests don’t face any problems.
                            </p>
                            <p className="text-justify">
                                To avail of all these excellent services provided by Tapovana, we have different types of service packages. All the other packages consist of different kinds of services we provide. Our guests can customise their packages according to their needs.
                            </p>
                            <p className="text-justify">
                                Our guests can add or remove the services they want from the preexisting package or customise their own package. In this way, our guests will pay only for what they want and enjoy their holidays without thinking of anything else with Tapovana. So let’s customise your package together at Tapovana.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_offersSubPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img src={"assets/media/offersPage_packages.jpg"} className="offersPackagesPage_banner subPage_banner" alt="Offers Promotions  Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_packagesPage section_subHeading">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            <h2 className="text-cener script_font">Our Packages</h2>
                        </div>
                    </div>

                    <div className="row  pt-4">
                        <div className="col-md-4 div_card_packagesPage ml-auto">
                            <Card className="card_packagesPage">
                                <Card.Img className="img_card_packagesPage" variant="top" src={"assets/media/packagesPage_honeymoon.jpg"} />
                                <Card.Body className="cardBody_packagesPage">
                                    <Card.Title>Honeymoon Package</Card.Title>
                                    <Card.Text className="text-justify">
                                        If you are looking for getaway and experience the best time with your loved one.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_packagesPage" value="Show More" onClick={() => { navigate("/packagesHoneymoon") }} />
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="col-md-4 div_card_packagesPage ml-auto">
                            <Card className="card_packagesPage">
                                <Card.Img className="img_card_packagesPage" variant="top" src={"assets/media/packagesPage_spiritual.jpg"} />
                                <Card.Body className="cardBody_packagesPage">
                                    <Card.Title>Spiritual Package</Card.Title>
                                    <Card.Text className="text-justify">
                                        This Package is suitable for those who like to visit spiritual places like temples, places and others.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_packagesPage" value="Show More" onClick={() => { navigate("/packagesSpiritual") }} />
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="col-md-4 div_card_packagesPage ml-auto">
                            <Card className="card_packagesPage">
                                <Card.Img className="img_card_packagesPage" variant="top" src={"assets/media/facilitiesPage_yoga.jpg"} />
                                <Card.Body className="cardBody_packagesPage">
                                    <Card.Title>Wellness Package</Card.Title>
                                    <Card.Text className="text-justify">
                                        The best way to have a refreshing vacation is by Starting the Day with Yoga and Meditation.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_packagesPage" value="Show More" onClick={() => { navigate("/packagesWellness") }} />
                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                    <div className="row  pt-4">
                        <div className="col-md-4 div_card_packagesPage mx-auto  ">
                            <Card className="card_packagesPage">
                                <Card.Img className="img_card_packagesPage" variant="top" src={"assets/media/packagesPage_heritage.jpg"} />
                                <Card.Body className="cardBody_packagesPage">
                                    <Card.Title>Heritage Package</Card.Title>
                                    <Card.Text className="text-justify">
                                        Best Package for those who like to experience the heritage places.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_packagesPage" value="Show More" onClick={() => { navigate("/packagesHeritage") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-4 div_card_packagesPage ml-auto">
                            <Card className="card_packagesPage">
                                <Card.Img className="img_card_packagesPage" variant="top" src={"assets/media/packagesPage_adventure.jpg"} />
                                <Card.Body className="cardBody_packagesPage">
                                    <Card.Title>Adventure Package</Card.Title>
                                    <Card.Text className="text-justify">
                                        For those who love nature and can’t resist a good adventure, This Package is for you.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_packagesPage" value="Show More" onClick={() => { navigate("/packagesAdventure") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-4 div_card_packagesPage ml-auto">
                            <Card className="card_packagesPage">
                                <Card.Img className="img_card_packagesPage" variant="top" src={"assets/media/packagesPage_newyear.jpg"} />
                                <Card.Body className="cardBody_packagesPage">
                                    <Card.Title>New Year Package</Card.Title>
                                    <Card.Text className="text-justify">
                                        Let new year be filled with adventure, nature & excitement. Tapovana is the best place to bring in this new year with your friends, family, or loved ones.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_packagesPage" value="Show More" onClick={() => { navigate("/packagesNewyear") }} />
                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                </div>

            </section>


            <WebsiteFooter />
        </>
    )
}

export default Package