import React, { useEffect } from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';

import { Card } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';


const RecreationIndoorgames = () => {
    var navigate = useNavigate();

    
    useEffect(() => {
        window.scrollTo(0, 0)
        let height_arr = [];

        let cardBody_recreationSubPage = document.getElementsByClassName("cardBody_recreationSubPage");

        for (let i = 0; i < cardBody_recreationSubPage.length; i++) {
            cardBody_recreationSubPage[i].style.display = "block";
            let height_data = cardBody_recreationSubPage[i].clientHeight;
            height_arr.push(parseInt(height_data));
        }
        var max_height = (Math.max.apply(Math, height_arr));

        for (let i = 0; i < cardBody_recreationSubPage.length; i++) {
            cardBody_recreationSubPage[i].style.height = (max_height + 20) + "px";
        }

    }, [])

    return (
        <>

            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>
            <section className="recreationIndoorgamesPage_overview section_heading">

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Indoor Games Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                Vacations allow families to stay together and play together. At Tapovana, we have scores of indoor games and activities to keep you and your loved ones pleasantly engaged for hours. The beautiful swimming pool is one place for the entire family to bond, feast and celebrate.
                            </p>
                            <p className="text-justify">
                                We also have multiple indoor gaming options for all age groups, from popular dice and board games like Ludo, carrom and chess to billiards, badminton and table tennis, to keep you busy and the children away from gadgets and mobile phones.
                            </p>

                        </div>
                    </div>
                </div>
            </section>

            <section className="section_recreationSubPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img src={"assets/media/recreationPage_indoorGames.jpg"} className="recreationIndoorgamesPage_banner subPage_banner" alt="Recreation Indoor Games  Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_features section_subHeading" id="section_features">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            <h2 className="text-cener script_font">Our Recreations</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 col-lg-4 div_card_recreationSubPage mx-auto ">
                            <Card className="card_recreationSubPage">
                                <Card.Img className="img_card_recreationSubPage" variant="top" src={"assets/media/recreationPage_trekking.jpg"} />
                                <Card.Body className="cardBody_recreationSubPage">
                                    <Card.Title>Trekking</Card.Title>
                                    <Card.Text className="text-justify">
                                        Tapovana is a resort set in the beautiful high mountains between the beautiful farmlands that yield from sweet-smelling coffee to strong tea leaves. Imagine trolling between them during the sunny day or having spent a pleasant starry night in a small camp settled by you. We will look forward to your visit to Tapovana.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_recreationSubPage" value="Show More" onClick={() => { navigate("/recreationTrekking") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 col-lg-4 div_card_recreationSubPage mx-auto ">
                            <Card className="card_recreationSubPage">
                                <Card.Img className="img_card_recreationSubPage" variant="top" src={"assets/media/recreationPage_birdWatching.jpg"} />
                                <Card.Body className="cardBody_recreationSubPage">
                                    <Card.Title>Bird Watching</Card.Title>
                                    <Card.Text className="text-justify">
                                        Bird watching is a work of art which is done by a very few people. We at Tapovana have skilled staff who will help you with the bird-watching activities. Bird watching can be done by our naked eyes, or we can use devices. Devices such as DSLR cameras or binoculars are used for the process.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_recreationSubPage" value="Show More" onClick={() => { navigate("/recreationBirdwatching") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 col-lg-4 div_card_recreationSubPage mx-auto ">
                            <Card className="card_recreationSubPage">
                                <Card.Img className="img_card_recreationSubPage" variant="top" src={"assets/media/recreationPage_plantationWalk.jpg"} />
                                <Card.Body className="cardBody_recreationSubPage">
                                    <Card.Title>Plantation Walk</Card.Title>
                                    <Card.Text className="text-justify">
                                        Tapovana is located in the middle of many different types of plantations. As the entire area is covered by a complete plantation, it’s like a feast for the eyes. Till where one’s eyes will go, all they see is beautiful green lush lands and pleasant weather. Anyone who likes to enjoy the beauty of greens, this place is for you.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_recreationSubPage" value="Show More" onClick={() => { navigate("/recreationPlantationwalk") }} />
                                </Card.Body>
                            </Card>
                        </div>

                        <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 col-lg-4 div_card_recreationSubPage ml-auto ">
                            <Card className="card_recreationSubPage">
                                <Card.Img className="img_card_recreationSubPage" variant="top" src={"assets/media/recreationPage_villageVisit.jpg"} />
                                <Card.Body className="cardBody_recreationSubPage">
                                    <Card.Title>Village Visit</Card.Title>
                                    <Card.Text className="text-justify">
                                        We at Tapovana take our guests for tours of all the villages which have heritage sites. We make sure our guests travels to most of these villages and know about the culture and activities. People can visit all the towns and get mingled with the locals. Our guests can learn about the local clothing and food styles.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_recreationSubPage" value="Show More" onClick={() => { navigate("/recreationVillagevisit") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 col-lg-4 div_card_recreationSubPage mr-auto ">
                            <Card className="card_recreationSubPage">
                                <Card.Img className="img_card_recreationSubPage" variant="top" src={"assets/media/recreationPage_mountainBiking.jpg"} />
                                <Card.Body className="cardBody_recreationSubPage">
                                    <Card.Title>Mountain Biking</Card.Title>
                                    <Card.Text className="text-justify">
                                        The mountains near Tapovana are suitable for trekking and mountain biking. We at Tapovana provide good services for both these outdoor activities. We provide trained professionals who can help with all of the challenges which one can face.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_recreationSubPage" value="Show More" onClick={() => { navigate("/recreationMountainbiking") }} />
                                </Card.Body>
                            </Card>
                        </div>

                    </div>

                </div>
            </section>


            <WebsiteFooter />

        </>
    )
}

export default RecreationIndoorgames
