import React, { useEffect, useState } from 'react'
import WebsiteFooter from './Components/WebsiteFooter'

import Axios from 'axios';

import PhoneInput from 'react-phone-number-input'

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        let div_contact_form = document.getElementById("div_contact_form");
        let height_data = parseInt(div_contact_form.clientHeight);
        document.getElementById("contactPage_maps").style.height = height_data + "px";

    }, [])

    const [formMobile, setFormMobile] = useState()

    const submitEnquiry = (e) => {
        e.preventDefault();
        let name = e.target.name.value;
        let mobile = (formMobile) ? formMobile : e.target.mobile.value;
        let email = e.target.email.value;
        let message = e.target.message.value;

        const addEnquiryUrl = global.config.server_url + "addEnquiry";
        const mobileRegex = new RegExp("[+][0-9]{2}[1-9]{1}[0-9]{9}");

        const addEnquiryData = {
            name: name,
            mobile: mobile,
            email: email,
            message: message
        };

        if (mobileRegex.test(mobile)) {
            Axios.post(addEnquiryUrl, addEnquiryData).then((addEnquiryResponse) => {
                if (addEnquiryResponse.data === true) {
                    alert("Submitted");
                    e.target.name.value = "";
                    e.target.mobile.value = "";
                    e.target.email.value = "";
                    e.target.message.value = "";

                } else {
                    alert("Error in Submitting Application!");
                }
            }).catch(err => { console.log(err); alert("Error in Submitting Application"); });
        } else {
            alert("Invalid Mobile Number")
        }

    }

    return (
        <>
            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>

            <section className="section_contactPage_form">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 div_contact_form" id="div_contact_form">
                            <form onSubmit={(e) => { submitEnquiry(e); }} className=" border bg_colour contact_form px-3 my-3 mx-auto" >
                                <div className="form-group ">
                                    <h3 className="text-center script_font">Write to us, <br />We will get back to you</h3>

                                </div>
                                <div className="form-group">
                                    <label htmlFor="name">Name <span style={{ color: "red" }}>*</span></label>
                                    <input type="text" required name="name" id="name" className="form-control" placeholder="Name *" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="mobile">Mobile <span style={{ color: "red" }}>*</span></label>
                                    <div className="form-control">
                                        <PhoneInput defaultCountry={"IN"} className="phone_input" placeholder="Enter phone number" name="mobile" value={formMobile} onChange={setFormMobile} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" name="email" id="email" className="form-control" placeholder="Email" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea name="message" id="message" rows="2" className="form-control" placeholder="Message"></textarea>
                                </div>
                                <div className="form-group text-center">
                                    <input type="submit" value="Submit" className="btn btn_header" />
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 px-0 div_contactPage_maps" id="div_contactPage_maps">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1943.9739739853508!2d75.6877242!3d12.9751811!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba4d746bd533185%3A0x375bc3c6ee67c58a!2sTapovana%20Heritage%20Resorts%20and%20SPA!5e0!3m2!1sen!2sin!4v1658396977200!5m2!1sen!2sin" width={"100%"} height={600} style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" id="contactPage_maps"></iframe>
                        </div>
                    </div>
                </div>
            </section>
            <WebsiteFooter />
        </>
    )
}

export default Contact
