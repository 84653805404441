import React, { useEffect } from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';

import { useNavigate } from 'react-router-dom';

import { Card } from 'react-bootstrap';


const Banquet = () => {
    var navigate = useNavigate();
    

    useEffect(() => {
        window.scrollTo(0, 0)
        let height_arr = [];

        let cardBody_banquetSubPage = document.getElementsByClassName("cardBody_banquetSubPage");

        for (let i = 0; i < cardBody_banquetSubPage.length; i++) {
            cardBody_banquetSubPage[i].style.display = "block";
            let height_data = cardBody_banquetSubPage[i].clientHeight;
            height_arr.push(parseInt(height_data));
        }
        var max_height = (Math.max.apply(Math, height_arr));

        for (let i = 0; i < cardBody_banquetSubPage.length; i++) {
            cardBody_banquetSubPage[i].style.height = (max_height + 20) + "px";
        }

    }, [])
    return (
        <>
            <div className="after_header" style={{ height: "75px" }}></div>

            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>

            <section className="banquetPage_overview section_heading">

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Banquet Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                Planning to organise a corporate retreat or a private function at an idyllic place?
                            </p>
                            <p className="text-justify">
                                Tapovana, with its natural bounty, heritage vibe and great hospitality, is where your quest ends. The starred resort gives you unbeatable indoor and outdoor options for organising memorable functions, ceremonies and corporate events for large gatherings. From a spacious party hall with ambient décor to a well-equipped board room to pleasant outdoor settings, we cover all your needs to host and entertain a large group of people. Our thoughtful management takes care of the smallest details, such as decorating the table with fineries and arranging drinkware for guests, setting up the A/V, and maintaining a comfortable indoor temperature well before the commencement of the event. Our professional chefs and trained servers ensure that each of your guests is served with scrumptious food in a timely and orderly manner. We lovingly host wedding ceremonies, charity events, board meetings, corporate seminars, team outings, and more. Just let us know the number of attendees and the itinerary, and we will plan and arrange the event to the last detail.
                            </p>

                        </div>
                    </div>
                </div>

            </section>
            <section className="section_banquetPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img src={"assets/media/banquetPage_banner.jpg"} className="banquetPage_banner" alt="Banquet Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_features section_subHeading" id="section_features">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            <h2 className="text-cener script_font">Our Banquets</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-lg-3 mx-auto div_card_banquetSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_banquetSubPage">
                                <Card.Img className="img_card_banquetSubPage" variant="top" src={"assets/media/banquetPage_hall.jpg"} />
                                <Card.Body className="cardBody_banquetSubPage">
                                    <Card.Title>Ball Room</Card.Title>
                                    <Card.Text className="text-justify">
                                        While on stay, if we have guests who want to have grand celebrations, we can arrange them. At Tapovana, we have the arrangements for the oversized banquets that can be hosted by our guests. All the deals will be taken care of by us for the same.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_banquetSubPage " value="Show More" onClick={() => { navigate("/banquetHall") }} />
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="col-md-6 col-lg-3 mx-auto div_card_banquetSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_banquetSubPage">
                                <Card.Img className="img_card_banquetSubPage" variant="top" src={"assets/media/banquetPage_boardroom.jpg"} />
                                <Card.Body className="cardBody_banquetSubPage">
                                    <Card.Title>Board Room</Card.Title>
                                    <Card.Text className="text-justify">
                                        At Tapovana, we have service of many different types of the banquet. One of these types of banquets that we provide here is Board room banquet. So if our guests want to have small or large-scale professional meetings, they can be arranged with us.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_banquetSubPage " value="Show More" onClick={() => { navigate("/banquetBoardroom") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-3 mx-auto div_card_banquetSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_banquetSubPage">
                                <Card.Img className="img_card_banquetSubPage" variant="top" src={"assets/media/banquetPage_outdoor.jpg"} />
                                <Card.Body className="cardBody_banquetSubPage">
                                    <Card.Title>Out Door</Card.Title>
                                    <Card.Text className="text-justify">
                                        Outdoor banquets are always suitable for family gatherings. Tapovana makes sure that our guests can taste the same without facing any kind of problems. We help you with most pleasing ambience and arrange your surroundings with your requests.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_banquetSubPage" value="Show More" onClick={() => { navigate("/banquetOutdoor") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-3 mx-auto div_card_banquetSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_banquetSubPage">
                                <Card.Img className="img_card_banquetSubPage" variant="top" src={"assets/media/banquetPage_greenlawn.jpg"} />
                                <Card.Body className="cardBody_banquetSubPage">
                                    <Card.Title>Green Lawn</Card.Title>
                                    <Card.Text className="text-justify">
                                        We at Tapovana have a large area of Green Lawns for use of our guests. So, setting up an excellent early morning or beautiful evening on a perfect banquet is always good. All the services of Tapovana are done in good coordination with our guests.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_banquetSubPage" value="Show More" onClick={() => { navigate("/banquetGreenlawn") }} />
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </section>

            <WebsiteFooter />

        </>
    )
}

export default Banquet
