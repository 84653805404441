import React, { useContext, useEffect, useState } from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';
import { useNavigate } from 'react-router-dom';


import RoomFacilities from '../Components/RoomFacilities';
import Axios from "axios";
import { ContextData } from '../Components/ContextData';
var moment = require("moment")

const RoomPrivatePoolVilla = () => {
    const context_data = useContext(ContextData)

    var navigate = useNavigate();

    

    useEffect(() => {
        context_data.setType("room")
        context_data.setRoom("private_pool_villa")
        context_data.setPackageName(false)

        window.scrollTo(0, 0)
        let height_arr = [];

        let cardBody_roomSubPage = document.getElementsByClassName("cardBody_roomSubPage");

        for (let i = 0; i < cardBody_roomSubPage.length; i++) {
            cardBody_roomSubPage[i].style.display = "block";
            let height_data = cardBody_roomSubPage[i].clientHeight;
            height_arr.push(parseInt(height_data));
        }
        var max_height = (Math.max.apply(Math, height_arr));

        for (let i = 0; i < cardBody_roomSubPage.length; i++) {
            cardBody_roomSubPage[i].style.height = (max_height + 20) + "px";
        }
        getTariffData();

    }, [])

    const [tariffData, setTariffData] = useState(null);

    const getTariffData = async () => {
        var getTariffsData = {
            type: "room",
            room: "private_pool_villa",
            check_in_day: moment(new Date()).format("D"),
            check_in_month: moment(new Date()).format("M"),
        };

        
        const getTariffsUrl = global.config.server_url + "getRoomDetails";
        Axios.post(getTariffsUrl, getTariffsData).then((tariffResponse) => {
            try {  setTariffData(tariffResponse.data[0]); }
            catch { console.log("error"); }
        }).catch(err => console.log(err));

    }

    function showDiscountAmount(tariff, amounts) {
        var weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var weekday = weekdays[new Date().getDay()];

        var base_discount_perc = (tariff.base_discount_perc) ? parseInt(tariff.base_discount_perc) : 0;

        var monday_discount_perc = (tariff.monday_discount_perc) ? parseInt(tariff.monday_discount_perc) : 0;
        var tuesday_discount_perc = (tariff.tuesday_discount_perc) ? parseInt(tariff.tuesday_discount_perc) : 0;
        var wednesday_discount_perc = (tariff.wednesday_discount_perc) ? parseInt(tariff.wednesday_discount_perc) : 0;
        var thursday_discount_perc = (tariff.thursday_discount_perc) ? parseInt(tariff.thursday_discount_perc) : 0;
        var friday_discount_perc = (tariff.friday_discount_perc) ? parseInt(tariff.friday_discount_perc) : 0;
        var saturday_discount_perc = (tariff.saturday_discount_perc) ? parseInt(tariff.saturday_discount_perc) : 0;
        var sunday_discount_perc = (tariff.sunday_discount_perc) ? parseInt(tariff.sunday_discount_perc) : 0;

        var day_discount_perc = (tariff.day_discount_perc) ? parseInt(tariff.day_discount_perc) : 0;

        var room_discount_amount = 0;

        if (day_discount_perc) {
            room_discount_amount = parseInt(amounts) * parseInt(day_discount_perc) / 100;
        }
        else if ((weekday.toLowerCase() == "monday" && monday_discount_perc) || (weekday.toLowerCase() == "tuesday" && tuesday_discount_perc) || (weekday.toLowerCase() == "wednesday" && wednesday_discount_perc) || (weekday.toLowerCase() == "thursday" && thursday_discount_perc) || (weekday.toLowerCase() == "friday" && friday_discount_perc) || (weekday.toLowerCase() == "saturday" && saturday_discount_perc) || (weekday.toLowerCase() == "sunday" && sunday_discount_perc)) {
            switch (weekday.toLowerCase()) {
                case "monday":
                    room_discount_amount = parseInt(amounts) * parseInt(monday_discount_perc) / 100;
                    break;
                case "tuesday":
                    room_discount_amount = parseInt(amounts) * parseInt(tuesday_discount_perc) / 100;
                    break;
                case "wednesday":
                    room_discount_amount = parseInt(amounts) * parseInt(wednesday_discount_perc) / 100;
                    break;
                case "thursday":
                    room_discount_amount = parseInt(amounts) * parseInt(thursday_discount_perc) / 100;
                    break;
                case "friday":
                    room_discount_amount = parseInt(amounts) * parseInt(friday_discount_perc) / 100;
                    break;
                case "saturday":
                    room_discount_amount = parseInt(amounts) * parseInt(saturday_discount_perc) / 100;
                    break;
                case "sunday":
                    room_discount_amount = parseInt(amounts) * parseInt(sunday_discount_perc) / 100;
                    break;
            }
        }
        else if (base_discount_perc) {
            room_discount_amount = parseInt(amounts) * parseInt(base_discount_perc) / 100;
        }

        var amount_after_discount = amounts + room_discount_amount
        if (amount_after_discount == amounts) {
            return <span>{indianNumberFormat(amount_after_discount)}/- + GST</span>
        } else if (amount_after_discount < amounts) {
            return <><div><span style={{ textDecoration: "line-through" }}>{indianNumberFormat(amounts)}</span></div><div><span><b>{indianNumberFormat(amount_after_discount)}/- + GST</b></span></div></>
        } else if (amount_after_discount > amounts) {
            return <span>{indianNumberFormat(amount_after_discount)}/- + GST</span>
        }
    }

    function indianNumberFormat(x) {
        if (x) {
            var number = parseFloat(x)
            return number.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: "currency", currency: "INR"
            });
        } else {
            return x
        }
    }

    const handleBookBtn = (roomName) => {
        var check_in = context_data.checkIn
        var check_out = moment(check_in).add(1, "days").format("yyyy-MM-DD")
        context_data.setMinCheckOut(check_out)
        context_data.setCheckIn(check_in)
        context_data.setCheckOut(check_out)

        context_data.setDatesModalShow(true)
    }

    return (
        <>

            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>
            <section className="roomValleyviewPage_overview section_heading">

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Private Pool Villa Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                Unlike all the other room present in the Tapovana, the private pool villa is the most special one. We have made this villa so that our guests can get the private pool inside their villa.
                            </p>
                            <p className="text-justify">
                                When you seek something even more luxurious than our Luxury villa, you’d want to spend a super lavish, very private holiday in one of our two exclusive Private Pool villas. Sprawled over 1,200 sq. ft. and featuring a private plunge pool, our Private Pool villas are palatial and sumptuously adorned. Whether coming for a family outing or a quiet romantic getaway, an exclusive stay at this villa is your answer to living majestically right in the midst of nature.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_roomSubPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0 ">
                            <img src={"assets/media/roomPage_private_pool_villa.jpg"} className="roomValleyviewPage_banner subPage_banner" alt="Room Private Pool Villa Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <RoomFacilities />

            {(tariffData != null && tariffData != false) ? (
                <section className="section_tariff">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center div_subHeading">
                                <h2 className="text-cener script_font">Private Pool Villa Tariff</h2>
                                <h5 className="text-cener script_font tariff_line">Tariff includes Buffet Breakfast, Lunch & Dinner</h5>
                            </div>
                            <div className="col-lg-3"></div>
                            <div className="col-lg-6 ">
                                <table className="table border border-dark" >
                                    <thead className="table-dark">
                                        <tr>
                                            <th>Guests</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {(tariffData.one_adult) ? (
                                            <tr>
                                                <td>Single</td>
                                                <td>{showDiscountAmount(tariffData, tariffData.one_adult)}</td>
                                            </tr>
                                        ) : ""}

                                        {(tariffData.two_adult) ? (
                                            <tr>
                                                <td>Double</td>
                                                <td>{showDiscountAmount(tariffData, tariffData.two_adult)}</td>
                                            </tr>
                                        ) : ""}

                                        {(tariffData.children) ? (
                                            <tr>
                                                <td>Children (6 to 12 Years)</td>
                                                <td>{showDiscountAmount(tariffData, tariffData.children)}</td>
                                            </tr>
                                        ) : ""}

                                        {(tariffData.extra_person) ? (
                                            <tr>
                                                <td>Extra Person</td>
                                                <td>{showDiscountAmount(tariffData, tariffData.extra_person)}</td>
                                            </tr>
                                        ) : ""}

                                        <tr>
                                            <td colSpan="2">
                                                <input onClick={() => handleBookBtn()} type="button" value="Book Now" className="btn btn_book btn_header" />
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-3"></div>

                        </div>
                    </div>
                </section>
            ) : ""}

            <WebsiteFooter />

        </>
    )
}

export default RoomPrivatePoolVilla
