import React, { useState } from 'react';
import { useEffect } from 'react';
import Slider from "react-slick";

const ThingsToDoCarouselRound = () => {

    const [settings, setSettings] = useState({ dots: true, className: "center", centerMode: true, infinite: true, slidesToShow: 3, slidesToScroll: 1, autoplay: true, speed: 1000, autoplaySpeed: 3000 });

    useEffect(() => {
        const checkPosition = () => {
            if (window.matchMedia('(max-width: 580px)').matches) {
                setSettings({ dots: true, className: "center", centerMode: true, infinite: true, slidesToShow: 1, slidesToScroll: 1, autoplay: true, speed: 1000, autoplaySpeed: 3000 });
            } else {
                setSettings({ dots: true, className: "center", centerMode: true, infinite: true, slidesToShow: 3, slidesToScroll: 1, autoplay: true, speed: 1000, autoplaySpeed: 3000 });
            }
        }

        checkPosition();

        console.log(typeof settings, settings)

    }, [])

    return (
        <>
            <section className="section_visiting_places bg_colour section_heading" id="section_visiting_places">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Things To Do</h1>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">

                            <Slider {...settings}>
                                <div>
                                    <img className="img_visiting_places" src={"assets/media/thingsToDo/thingsToDo_AgniGudda.jpg"} />
                                    <p className="text-center">Agni Gudda Trekking</p>
                                </div>
                                <div>
                                    <img className="img_visiting_places" src={"assets/media/thingsToDo/thingsToDo_BislieGhat.jpg"} />
                                    <p className="text-center">Bisle Ghat</p>
                                </div>
                                <div>
                                    <img className="img_visiting_places" src={"assets/media/thingsToDo/thingsToDo_BirdWatching.jpg"} />
                                    <p className="text-center">Bird Watching</p>
                                </div>
                                <div>
                                    <img className="img_visiting_places" src={"assets/media/thingsToDo/thingsToDo_CoffeeEstate.jpg"} />
                                    <p className="text-center">Coffee Estate Tour</p>
                                </div>
                                <div>
                                    <img className="img_visiting_places" src={"assets/media/thingsToDo/thingsToDo_HemavathiBackWaters.jpg"} />
                                    <p className="text-center">Hemavathi Back Waters</p>
                                </div>
                                <div>
                                    <img className="img_visiting_places" src={"assets/media/thingsToDo/thingsToDo_JenukalluGudda.jpg"} />
                                    <p className="text-center">Jenukallu Gudda Trekking</p>
                                </div>
                                <div>
                                    <img className="img_visiting_places" src={"assets/media/thingsToDo/thingsToDo_KadumaneTeaEstate.jpg"} />
                                    <p className="text-center">Kadumane Tea Estate</p>
                                </div>
                                <div>
                                    <img className="img_visiting_places" src={"assets/media/thingsToDo/thingsToDo_MalahalliFalls.jpg"} />
                                    <p className="text-center">Malahalli Falls</p>
                                </div>
                                <div>
                                    <img className="img_visiting_places" src={"assets/media/thingsToDo/thingsToDo_ManjarabadFort.jpg"} />
                                    <p className="text-center">Manjarabad Fort</p>
                                </div>
                                <div>
                                    <img className="img_visiting_places" src={"assets/media/thingsToDo/thingsToDo_MookanamaneFalls.jpg"} />
                                    <p className="text-center">Mookanamane Falls</p>
                                </div>
                                <div>
                                    <img className="img_visiting_places" src={"assets/media/thingsToDo/thingsToDo_SakleshpurRailway.jpg"} />
                                    <p className="text-center">Sakleshpur Railway Track Trekking</p>
                                </div>
                                <div>
                                    <img className="img_visiting_places" src={"assets/media/thingsToDo/thingsToDo_SakleshwaraTemple.jpg"} />
                                    <p className="text-center">Sakleshwara Temple</p>
                                </div>
                            </Slider>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ThingsToDoCarouselRound
