import React, { useEffect } from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';
import { Card } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

const Recreation = () => {
    var navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
        let height_arr = [];
        
        let cardBodyRecreation = document.getElementsByClassName("cardBody_recreation");

        for (let i = 0; i < cardBodyRecreation.length; i++) {
            cardBodyRecreation[i].style.display = "block";
            let height_data = cardBodyRecreation[i].clientHeight;
            height_arr.push(parseInt(height_data));
        }
        var max_height = (Math.max.apply(Math, height_arr));

        for (let i = 0; i < cardBodyRecreation.length; i++) {
            cardBodyRecreation[i].style.height = (max_height + 20) + "px";
        }

    }, [])

    return (

        <>
            <div className="after_header" style={{ height: "75px" }}></div>

            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>

            <section className="recreationPage_overview section_heading">

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Recreation Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                There are various fun and recreational activities for all age groups at Tapovana. We know how to engage all members of your family. From outdoor activities, such as trekking and camping under the stars, to in-resort facilities like a swimming pool, indoor games and plantation walk – we have recreational activities to keep you engrossed throughout the day.
                            </p>
                            <p className="text-justify">
                                What makes us different from other leisure destinations is the effort we put into going the extra mile for your delight; for instance, we arrange to spend the night in coffee plantations, dinners under the stars, bonfires, and much more to curate unique experiences you can't imagine elsewhere.
                            </p>

                        </div>
                    </div>
                </div>

            </section>
            <section className="section_recreationPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img src={"assets/media/recreationPage_banner.jpg"} className="recreationPage_banner" alt="Recreation Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_features section_subHeading" id="section_features">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            <h2 className="text-cener script_font">Our Recreations</h2>
                        </div>
                    </div>

                    <div className="row" >
                        <div className="col-md-6 col-lg-4 div_card_recreation" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_recreation"  >
                                <Card.Img className="img_card_recreation" variant="top" src={"assets/media/recreationPage_trekking.jpg"} />
                                <Card.Body className="cardBody_recreation">
                                    <Card.Title>Trekking</Card.Title>
                                    <Card.Text className="text-justify">
                                        Tapovana is a resort set in the beautiful high mountains between the beautiful farmlands that yield from sweet-smelling coffee to strong tea leaves. Imagine trolling between them during the sunny day or having spent a pleasant starry night in a small camp settled by you. We will look forward to your visit to Tapovana.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_recreation" value="Show More" onClick={() => { navigate("/recreationTrekking") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-4 div_card_recreation" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_recreation">
                                <Card.Img className="img_card_recreation" variant="top" src={"assets/media/recreationPage_birdWatching.jpg"} />
                                <Card.Body className="cardBody_recreation">
                                    <Card.Title>Bird Watching</Card.Title>
                                    <Card.Text className="text-justify">
                                        Bird watching is a work of art which is done by a very few people. We at Tapovana have skilled staff who will help you with the bird-watching activities. Bird watching can be done by our naked eyes, or we can use devices. Devices such as DSLR cameras or binoculars are used for the process.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_recreation" value="Show More" onClick={() => { navigate("/recreationBirdwatching") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-4 div_card_recreation" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_recreation">
                                <Card.Img className="img_card_recreation" variant="top" src={"assets/media/recreationPage_plantationWalk.jpg"} />
                                <Card.Body className="cardBody_recreation">
                                    <Card.Title>Plantation Walk</Card.Title>
                                    <Card.Text className="text-justify">
                                        Tapovana is located in the middle of many different types of plantations. As the entire area is covered by a complete plantation, it’s like a feast for the eyes. Till where one’s eyes will go, all they see is beautiful green lush lands and pleasant weather. Anyone who likes to enjoy the beauty of greens, this place is for you.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_recreation" value="Show More" onClick={() => { navigate("/recreationPlantationwalk") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-4 div_card_recreation" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_recreation">
                                <Card.Img className="img_card_recreation" variant="top" src={"assets/media/recreationPage_villageVisit.jpg"} />
                                <Card.Body className="cardBody_recreation">
                                    <Card.Title>Village Visit</Card.Title>
                                    <Card.Text className="text-justify">
                                        We at Tapovana take our guests for tours of all the villages which have heritage sites. We make sure our guests travels to most of these villages and know about the culture and activities. People can visit all the towns and get mingled with the locals. Our guests can learn about the local clothing and food styles.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_recreation" value="Show More" onClick={() => { navigate("/recreationVillagevisit") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-4 div_card_recreation" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_recreation">
                                <Card.Img className="img_card_recreation" variant="top" src={"assets/media/recreationPage_mountainBiking.jpg"} />
                                <Card.Body className="cardBody_recreation">
                                    <Card.Title>Mountain Biking</Card.Title>
                                    <Card.Text className="text-justify">
                                        The mountains near Tapovana are suitable for trekking and mountain biking. We at Tapovana provide good services for both these outdoor activities. We provide trained professionals who can help with all of the challenges which one can face.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_recreation" value="Show More" onClick={() => { navigate("/recreationMountainbiking") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-4 div_card_recreation" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_recreation">
                                <Card.Img className="img_card_recreation" variant="top" src={"assets/media/recreationPage_indoorGames.jpg"} />
                                <Card.Body className="cardBody_recreation">
                                    <Card.Title>Indoor Games</Card.Title>
                                    <Card.Text className="text-justify">
                                        We need to make sure that we make these children more active and make them interact with people. By playing Indoor games, the children can interact well with others. By playing Indoor games, they can learn many new things from people they interact with.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_recreation" value="Show More" onClick={() => { navigate("/recreationIndoorgames") }} />
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </section>

            <WebsiteFooter />

        </>

    )
}

export default Recreation
