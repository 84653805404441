import React, { useEffect } from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';

import { Card } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

import BanquetFacilities from '../Components/BanquetFacilities';
import BanquetEnquiry from '../Components/BanquetEnquiry';

const BanquetOutdoor = () => {
    var navigate = useNavigate();

    
    useEffect(() => {
        window.scrollTo(0, 0)
        let height_arr = [];

        let cardBody_banquetSubPage = document.getElementsByClassName("cardBody_banquetSubPage");

        for (let i = 0; i < cardBody_banquetSubPage.length; i++) {
            cardBody_banquetSubPage[i].style.display = "block";
            let height_data = cardBody_banquetSubPage[i].clientHeight;
            height_arr.push(parseInt(height_data));
        }
        var max_height = (Math.max.apply(Math, height_arr));

        for (let i = 0; i < cardBody_banquetSubPage.length; i++) {
            cardBody_banquetSubPage[i].style.height = (max_height + 20) + "px";
        }

    }, [])

    return (
        <>

            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>
            <section className="banquetOutdoorPage_overview section_heading">

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Outdoor Catering Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                Thankfully, the exquisite flavours and fine dining element we provide isn’t restricted to the Tapovana resort only. With an aim to make your events and parties livelier and more memorable, we offer outdoor catering services across the blessed town of Sakleshpur. Whatever may be the occasion, we have the right resources and rich experience in catering for large parties of guests at any location around Sakleshpur. Our team ensures that you get an exquisitely arranged party setting, complete with elegant table and chair decorations, strategically placed food stalls and courteous servers dressed immaculately. So, when you next plan celebrations in Sakleshpur, just give us a call, and we will take care of your outdoor catering needs.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_banquetSubPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img src={"assets/media/banquetPage_outdoor.jpg"} className="banquetOutdoorPage_banner subPage_banner" alt="Banquet Outdoor Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <BanquetFacilities />

            <BanquetEnquiry />

            <section className="section_features section_subHeading" id="section_features">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            <h2 className="text-cener script_font">Our Banquets</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-lg-4 mx-auto div_card_banquetSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_banquetSubPage">
                                <Card.Img className="img_card_banquetSubPage" variant="top" src={"assets/media/banquetPage_hall.jpg"} />
                                <Card.Body className="cardBody_banquetSubPage">
                                    <Card.Title>Hall</Card.Title>
                                    <Card.Text className="text-justify">
                                        While on stay, if we have guests who want to have grand celebrations, we can arrange them. At Tapovana, we have the arrangements for the oversized banquets that can be hosted by our guests. All the deals will be taken care of by us for the same.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_banquetSubPage " value="Show More" onClick={() => { navigate("/banquetHall") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-4 mx-auto div_card_banquetSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_banquetSubPage">
                                <Card.Img className="img_card_banquetSubPage" variant="top" src={"assets/media/banquetPage_boardroom.jpg"} />
                                <Card.Body className="cardBody_banquetSubPage">
                                    <Card.Title>Board Room</Card.Title>
                                    <Card.Text className="text-justify">
                                        At Tapovana, we have the service of many different types of the banquet. One of these types of banquets that we provide here is a Board room banquet. So if our guests want to have small or large-scale professional meetings, they can even be arranged with us.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_banquetSubPage " value="Show More" onClick={() => { navigate("/banquetBoardroom") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-4 mx-auto div_card_banquetSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_banquetSubPage">
                                <Card.Img className="img_card_banquetSubPage" variant="top" src={"assets/media/banquetPage_greenlawn.jpg"} />
                                <Card.Body className="cardBody_banquetSubPage">
                                    <Card.Title>Green Lawn</Card.Title>
                                    <Card.Text className="text-justify">
                                        We at Tapovana have a large area of Green Lawns present for the use of our guests. So, setting up an excellent early morning or beautiful evening on a perfect banquet is always good. All the services of Tapovana are done in good coordination with our guests.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_banquetSubPage" value="Show More" onClick={() => { navigate("/banquetGreenlawn") }} />
                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                </div>
            </section>


            <WebsiteFooter />

        </>
    )
}

export default BanquetOutdoor
