import React from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';
import EventsFacilities from '../Components/EventsFacilities';

const EventsParties = () => {
    return (
        <>
            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>

            <section className="packagesPage_overview section_heading">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Destination Parties Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                When you decide to have a party bash of the century, let us step in and take it over from you. A birthday celebration at Tapovana is more than a sumptuous meal – it begins with a road trip to the Ghats with your favourite people, enjoying world-class hospitality at a picturesque location, exploring the region's cultural heritage, planning treks and hikes, and indulging in some really fine dining. In short, a mini-vacation with a blend of luxury and natural beauty. Plan your destination party better with our handcrafted packages.
                            </p>
                            <p className="text-justify">
                                It will be a Party, just the way you want it.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_packagesSubPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img src={"assets/media/eventsPage_birthday.jpg"} className="packagesPage_banner subPage_banner" alt="Events Wedding Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <EventsFacilities />

            <WebsiteFooter />
        </>
    )
}

export default EventsParties
