import React, { useEffect, useState } from 'react'
import WebsiteFooter from './Components/WebsiteFooter'

import Axios from "axios";

import PhoneInput from 'react-phone-number-input'
var moment = require('moment');
const Careers = () => {

    useEffect(() => {

        window.scrollTo(0, 0);
        let div_contact_form = document.getElementById("div_contact_form");
        let height_data = parseInt(div_contact_form.clientHeight);
        document.getElementById("contactPage_maps").style.height = height_data + "px";

    }, [])

    const [formMobile, setFormMobile] = useState()

    const submitCareer = (e) => {
        e.preventDefault();

        var name = e.target.name.value;
        let mobile = (formMobile) ? formMobile : e.target.mobile.value;
        var email = e.target.email.value;
        var cv = e.target.career_cv.files[0];

        var cv_name = cv.name;

        var cv_extension = (cv_name.substring(cv_name.lastIndexOf(".") + 1));
        cv_extension = cv_extension.toLowerCase();

        if (cv_extension != "pdf" && cv_extension != "doc" && cv_extension != "docx") {
            alert("Attached File should be pdf / doc / docx");
            return;
        }

        let now = new Date();
        let current_date_time = moment().format('YYYYMMDDHHmmss');
        const cv_file_name = "career" + mobile + "_datetime" + current_date_time + "." + cv_extension;

        const formData = new FormData();
        formData.append(
            "myFile",
            e.target.career_cv.files[0],
            cv_file_name
        );

        const mobileRegex = new RegExp("[+][0-9]{2}[1-9]{1}[0-9]{9}");

        const submitAddCareerUrl = global.config.server_url + "addCareer";
        const submitAddCareerData = {
            name: name,
            mobile: mobile,
            email: email,
            cv_file_name: cv_file_name,
            // cv_file_name_path: process.env.PUBLIC_URL + "/resume/" + cv_file_name
            cv_file_name_path: `${window.location.origin.toString()}/resume/` + cv_file_name
        }

        if (mobileRegex.test(mobile)) {

            Axios.post(submitAddCareerUrl, submitAddCareerData).then((response) => {
                if (response.data === true) {
                    Axios.post(process.env.PUBLIC_URL + "/reactFileUpload.php", formData).then((response) => {
                        console.log(response);
                        if (response.data == true) {
                            console.log("File uploaded");
                        } else {
                            console.log("Error in uploading file");
                        }
                    }).catch(err => { console.log(err); alert("Error in uploading file"); });;

                    alert("Application Submitted");
                    e.target.name.value = "";
                    e.target.email.value = "";
                    e.target.mobile.value = "";
                    document.getElementById("career_cv").value = "";

                } else {
                    alert("Error in Submitting Application");
                }
            }).catch(err => { console.log(err); alert("Error in Submitting Application"); });
        } else {
            alert("Invalid Mobile Number")
        }
    }

    return (
        <>
            <div className="after_header" style={{ height: "75px" }}></div>

            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>

            <section className="section_contactPage_form">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 div_contact_form" id="div_contact_form">
                            <form onSubmit={(e) => { submitCareer(e) }} className=" border bg_colour career_form px-3 my-3 mx-auto" >
                                <div className="form-group ">
                                    <h3 className="text-center script_font">Want to Join Us ? <br /> Leave us your details</h3>

                                </div>
                                <div className="form-group">
                                    <label htmlFor="name">Name <span style={{ color: "red" }}>*</span></label>
                                    <input type="text" required name="name" id="name" className="form-control" placeholder="Name *" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="mobile">Mobile <span style={{ color: "red" }}>*</span></label>
                                    {/* <input type="number" required name="mobile" id="mobile" className="form-control" placeholder="Mobile *" /> */}
                                    <div className="form-control">
                                        <PhoneInput className="phone_input" defaultCountry={"IN"} placeholder="Enter phone number" name="mobile" value={formMobile} onChange={setFormMobile} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" name="email" id="email" className="form-control" placeholder="Email" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Attach Your CV</label><br />
                                    <input type="file" required name="career_cv" id="career_cv" className="career_cv " />
                                </div>
                                <div className="form-group text-center">
                                    <input type="submit" value="Submit" className="btn btn_header" />
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 px-0 div_contactPage_maps" id="div_contactPage_maps">
                            <img src={"assets/media/hiring.jpg"} alt="hiring" id="contactPage_maps" className="img_careers" />
                        </div>
                    </div>
                </div>
            </section>
            <WebsiteFooter />

        </>
    )
}

export default Careers
