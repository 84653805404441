import React, { useEffect } from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';

import { Card } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

import BanquetFacilities from '../Components/BanquetFacilities';
import BanquetEnquiry from '../Components/BanquetEnquiry';

const BanquetBallroom = () => {
    var navigate = useNavigate();

    
    useEffect(() => {
        window.scrollTo(0, 0)
        let height_arr = [];

        let cardBody_banquetSubPage = document.getElementsByClassName("cardBody_banquetSubPage");

        for (let i = 0; i < cardBody_banquetSubPage.length; i++) {
            cardBody_banquetSubPage[i].style.display = "block";
            let height_data = cardBody_banquetSubPage[i].clientHeight;
            height_arr.push(parseInt(height_data));
        }
        var max_height = (Math.max.apply(Math, height_arr));

        for (let i = 0; i < cardBody_banquetSubPage.length; i++) {
            cardBody_banquetSubPage[i].style.height = (max_height + 20) + "px";
        }

    }, [])

    return (
        <>

            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>
            <section className="banquetHallPage_overview section_heading">

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Ball Room Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                Add grace and charm to large events by celebrating them amid pristine nature and unparallel luxury. At Tapovana, we have a grand Ballroom to accommodate large gatherings in comfort and style. Whether it is a wedding, anniversary, award night, corporate meet, seminar, or any other mega event, we have just the right space and the best catering services to engage a large, discerning audience. We decorate and prepare the Ballroom as per your recommendations and requirements right before the schedule to avoid delays and last-minute hiccups. Moreover, we have a caring and efficient team that professionally tend to all your event organisation needs.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_banquetSubPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0 pt-4">
                            <img src={"assets/media/banquetPage_hall.jpg"} className="banquetHallPage_banner subPage_banner" alt="Banquet Hall Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <BanquetFacilities />

            <section className="section_capacity">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            <h2 className="text-cener script_font">Seating Capacity</h2>
                        </div>
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4 ">
                            <table className="table border border-dark" >
                                <thead className="table-dark">
                                    <tr>
                                        <th>Arrangements</th>
                                        <th>Capacity</th>
                                    </tr>
                                </thead>
                                <tbody className="">
                                    <tr>
                                        <td>Cocktail</td>
                                        <td>200 Persons</td>
                                    </tr>
                                    <tr>
                                        <td>Theatre</td>
                                        <td>150 Persons </td>
                                    </tr>
                                    <tr>
                                        <td>Class Room</td>
                                        <td>75 Persons</td>
                                    </tr>
                                    <tr>
                                        <td>Cluster</td>
                                        <td>60 Persons</td>
                                    </tr>
                                    <tr>
                                        <td>Hollow Block</td>
                                        <td>50 Persons</td>
                                    </tr>
                                    <tr>
                                        <td>U shape</td>
                                        <td>40 Persons</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-lg-4"></div>

                    </div>
                </div>
            </section>

            <BanquetEnquiry />

            <section className="section_features section_subHeading" id="section_features">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            <h2 className="text-cener script_font">Our Banquets</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-lg-4 mx-auto div_card_banquetSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_banquetSubPage">
                                <Card.Img className="img_card_banquetSubPage" variant="top" src={"assets/media/banquetPage_boardroom.jpg"} />
                                <Card.Body className="cardBody_banquetSubPage">
                                    <Card.Title>Board Room</Card.Title>
                                    <Card.Text className="text-justify">
                                        At Tapovana, we have the service of many different types of the banquet. One of these types of banquets that we provide here is a Board room banquet. So if our guests want to have small or large-scale professional meetings, they can even be arranged with us.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_banquetSubPage " value="Show More" onClick={() => { navigate("/banquetBoardroom") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-4 mx-auto div_card_banquetSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_banquetSubPage">
                                <Card.Img className="img_card_banquetSubPage" variant="top" src={"assets/media/banquetPage_outdoor.jpg"} />
                                <Card.Body className="cardBody_banquetSubPage">
                                    <Card.Title>Out Door</Card.Title>
                                    <Card.Text className="text-justify">
                                        Outdoor banquets are always suitable for family gatherings. Tapovana makes sure that our guests can taste the same without facing any kind of problems. We help you with the most pleasing ambience and arrange your surroundings with your requests.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_banquetSubPage" value="Show More" onClick={() => { navigate("/banquetOutdoor") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-4 mx-auto div_card_banquetSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_banquetSubPage">
                                <Card.Img className="img_card_banquetSubPage" variant="top" src={"assets/media/banquetPage_greenlawn.jpg"} />
                                <Card.Body className="cardBody_banquetSubPage">
                                    <Card.Title>Green Lawn</Card.Title>
                                    <Card.Text className="text-justify">
                                        We at Tapovana have a large area of Green Lawns present for the use of our guests. So, setting up an excellent early morning or beautiful evening on a perfect banquet is always good. All the services of Tapovana are done in good coordination with our guests.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_banquetSubPage" value="Show More" onClick={() => { navigate("/banquetGreenlawn") }} />
                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                </div>
            </section>


            <WebsiteFooter />

        </>
    )
}

export default BanquetBallroom
