import React, { useEffect } from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';

import { Card } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';


const DiningRestaurant = () => {
    var navigate = useNavigate();

    
    useEffect(() => {
        window.scrollTo(0, 0)
        let height_arr = [];

        let cardBody_diningSubPage = document.getElementsByClassName("cardBody_diningSubPage");

        for (let i = 0; i < cardBody_diningSubPage.length; i++) {
            cardBody_diningSubPage[i].style.display = "block";
            let height_data = cardBody_diningSubPage[i].clientHeight;
            height_arr.push(parseInt(height_data));
        }
        var max_height = (Math.max.apply(Math, height_arr));

        for (let i = 0; i < cardBody_diningSubPage.length; i++) {
            cardBody_diningSubPage[i].style.height = (max_height + 20) + "px";
        }

    }, [])

    return (
        <>

            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>
            <section className="diningRestaurantPage_overview section_heading">

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Restaurant Dining Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                Avartana,  the  multi-cuisine  restaurant  offers  a  variety  of  international  delicacies  through  an  extensive  buffet  for breakfast,  lunch,  and  dinner.  It  is  a  magical  blend  of  traditional  décor  with  a  modern  interior,  combined  with  quick reliable service making it a destination for the perfect dining experience. </p>
                            <p className="text-justify">
                                We  are  very  particular  about  the  gastronomical  experience  you  have  at  our  resort;  hence,  we  procure  only  the freshest,  quality  food  items  grown  organically  by  the  villagers.  By  sourcing  our  food  from  neighboring  farms  and suppliers, we keep a tab on the quality and freshness of food products and support the local economy as per our vision for sustainable living.
                            </p>

                        </div>
                    </div>
                </div>
            </section>

            <section className="section_diningSubPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img src={"assets/media/diningPage_restaurant.jpg"} className="diningRestaurantPage_banner subPage_banner" alt="Dining Restaurant Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_features section_subHeading" id="section_features">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            <h2 className="text-cener script_font">Other Dinings</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-lg-4 mx-auto div_card_diningSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_diningSubPage">
                                <Card.Img className="img_card_diningSubPage" variant="top" src={"assets/media/diningPage_roomservice.jpg"} />
                                <Card.Body className="cardBody_diningSubPage">
                                    <Card.Title>Room Service</Card.Title>
                                    <Card.Text className="text-justify">
                                        We all know the best part of going to a resort or hotel is the room service. The room services offered by Tapovana are of the best quality. None of our guests will have any kind of complaints about the room service provided by our staff. We have a completely different set of people who will be just one call.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_diningSubPage" value="Show More" onClick={() => { navigate("/diningRoomservice") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-4 mx-auto div_card_diningSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_diningSubPage">
                                <Card.Img className="img_card_diningSubPage" variant="top" src={"assets/media/diningPage_poolside.jpg"} />
                                <Card.Body className="cardBody_diningSubPage">
                                    <Card.Title>Pool Side</Card.Title>
                                    <Card.Text className="text-justify">
                                        During a hot day, all we need is a good dip in the pool and relax. So, Tapovana has made sure of that interest of our guests. Because of this interest of our guests, we have the facility of dining near the pool. This dining facility is again available for all our guests.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_diningSubPage" value="Show More" onClick={() => { navigate("/diningPoolside") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-4 mx-auto div_card_diningSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_diningSubPage">
                                <Card.Img className="img_card_diningSubPage" variant="top" src={"assets/media/diningPage_moonlight.jpg"} />
                                <Card.Body className="cardBody_diningSubPage">
                                    <Card.Title>Moon Light</Card.Title>
                                    <Card.Text className="text-justify">
                                        Want a romantic date with your partner? Don’t worry! Tapovana is here for your rescue. Just tell us the time and location of the resort or the nearby areas. We will make sure you get it. Nothing pleases one’s partner when the other half takes care of them by taking them out on romantic dates.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_diningSubPage" value="Show More" onClick={() => { navigate("/diningMoonlight") }} />
                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                </div>
            </section>


            <WebsiteFooter />

        </>
    )
}

export default DiningRestaurant
