import React, { useEffect, useState } from 'react'
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import PhoneInput from 'react-phone-number-input';
import axios from 'axios';

const EventsFacilities = () => {

    useEffect(() => {
        window.scrollTo(0, 0);

        if (window.location.pathname == "/eventsWedding") {
            document.getElementById("select_venue").value = "Wedding";
        }

        if (window.location.pathname == "/eventsMeetings") {
            document.getElementById("select_venue").value = "Meeting / Conference";
        }

        if (window.location.pathname == "/eventsParties") {
            document.getElementById("select_venue").value = "Parties";
        }

        if (window.location.pathname == "/eventsPreweddingPhotoshoot") {
            document.getElementById("select_venue").value = "Prewedding Photoshoot";
        }

        if (window.location.pathname == "/eventsPostweddingPhotoshoot") {
            document.getElementById("select_venue").value = "Postwedding Photoshoot";
        }

    }, [])

    const OpenInNewTab = (Type, data) => {
        if (Type == "Link") {
            const newWindow = window.open(data, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }

    }

    const [formMobile, setFormMobile] = useState()

    const submitEnquiry = (e) => {
        e.preventDefault();
        let name = e.target.name.value;
        let mobile = (formMobile) ? formMobile : e.target.mobile.value;
        let email = e.target.email.value;
        let message = e.target.message.value;

        const addEnquiryUrl = global.config.server_url + "addEnquiry";
        const mobileRegex = new RegExp("[+][0-9]{2}[1-9]{1}[0-9]{9}");

        const addEnquiryData = {
            name: name,
            mobile: mobile,
            email: email,
            message: message
        };

        if (mobileRegex.test(mobile)) {
            axios.post(addEnquiryUrl, addEnquiryData).then((addEnquiryResponse) => {
                if (addEnquiryResponse.data === true) {
                    alert("Submitted");
                    e.target.name.value = "";
                    e.target.mobile.value = "";
                    e.target.email.value = "";
                    e.target.message.value = "";

                } else {
                    alert("Error in Submitting Application!");
                }
            }).catch(err => { console.log(err); alert("Error in Submitting Application"); });
        } else {
            alert("Invalid Mobile Number")
        }

    }

    return (
        <>
            <section className="section_room_facilities" id="section_room_facilities">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            <h2 className="text-cener script_font">Event Facilities</h2>
                        </div>
                        <div className="col-lg-2"></div>
                        <div className="col-md-6 col-lg-4">
                            <div className="">
                                <ul className="cottages_facilities_ul">
                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Air Conditioned</li>
                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Bottled water</li>
                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Modern Audio Visual equipment</li>
                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Access to green lawn</li>
                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Stationeries on request</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <ul className="cottages_facilities_ul">
                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Adjustable chairs & tables</li>
                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Wi-Fi on request (charges applicable)</li>
                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Traditional tile floors</li>
                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Pure vegetarian & Non vegetarian menu selection</li>
                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Drop down screen and Overhead LCD Projector</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </section>

            <section className="section_events_enquiry my-3">
                <div className="container ">
                    <form onSubmit={(e) => submitEnquiry(e)}>
                        <div className="row">
                            <div className="col-12"><h3>Event Enquiry</h3></div>
                            <div className="col-4">
                                <div className="form-group">
                                    <input type="text" name="name" id="name" className="form-control" placeholder="Name" />
                                </div>
                                <div className="form-group">
                                    <div className="form-control">
                                        <PhoneInput defaultCountry={"IN"} className="phone_input" placeholder="Enter phone number" name="mobile" value={formMobile} onChange={setFormMobile} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="email" name="email" id="email" className="form-control" placeholder="Email" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <select id="select_venue" name="message" className="form-select">
                                        <option value="Wedding">Wedding</option>
                                        <option value="Meeting / Conference">Meeting / Conference</option>
                                        <option value="Parties">Parties</option>
                                        <option value="Prewedding Photoshoot">Prewedding Photoshoot</option>
                                        <option value="Postwedding Photoshoot">Postwedding Photoshoot</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <div className="input-group mb-3">
                                        <input type="text" required name="event_date" id="event_date" className="event_date form-control" placeholder="Event Date" onFocus={(e) => { e.target.type = "date" }} style={{ fontSize: "14px" }} aria-label="event_date" aria-describedby="event_date" />
                                        <div className="input-group-append">
                                            <span className="bg-light input-group-text" id="event_date"><MdIcons.MdDateRange /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="submit" value="Enquire Now" className="btn btn_header" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-control border-0">
                                    <label htmlFor=""><b>EVENT ENQUIRY</b></label>
                                </div>
                                <div className="form-control border-0">
                                    <label htmlFor="" className="link"><MdIcons.MdSmartphone onClick={() => OpenInNewTab("Link", "tel:+91-9686659966")} /> +91 96866 59966</label>
                                </div>
                                <div className="form-control border-0">
                                    <label htmlFor="" className="link"><MdIcons.MdMail onClick={() => OpenInNewTab("Link", "mail:reservations@tapovanaresorts.com")} /> reservations@tapovanaresorts.com</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>



        </>
    )
}

export default EventsFacilities
