import React, { useEffect } from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';

import { Card } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';


const RecreationMountainbiking = () => {
    var navigate = useNavigate();

    
    useEffect(() => {
        window.scrollTo(0, 0)
        let height_arr = [];

        let cardBody_recreationSubPage = document.getElementsByClassName("cardBody_recreationSubPage");

        for (let i = 0; i < cardBody_recreationSubPage.length; i++) {
            cardBody_recreationSubPage[i].style.display = "block";
            let height_data = cardBody_recreationSubPage[i].clientHeight;
            height_arr.push(parseInt(height_data));
        }
        var max_height = (Math.max.apply(Math, height_arr));

        for (let i = 0; i < cardBody_recreationSubPage.length; i++) {
            cardBody_recreationSubPage[i].style.height = (max_height + 20) + "px";
        }

    }, [])

    return (
        <>

            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>
            <section className="recreationMountainbikingPage_overview section_heading">

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Mountain Biking Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                For the adventure seekers, we offer mountain biking as a recreational activity. There are several tracks and trails around the property that will give you one of the most exhilarating off-roading experiences. Guests are requested to carry their own high-quality MTB cycle with safety accessories or rent it from local vendors.
                            </p>
                            <p className="text-justify">
                                For every bicycling excursion in the hills and surrounding forest area, we assign a staff who is well-versed with the terrain. Our team also makes provisions for essentials like medical care, snacks and refreshments, bike repair kits, compasses, etc., for your convenience.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_recreationSubPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img src={"assets/media/recreationPage_mountainBiking.jpg"} className="recreationMountainbikingPage_banner subPage_banner" alt="Recreation Mountain Biking  Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_features section_subHeading" id="section_features">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            <h2 className="text-cener script_font">Our Recreations</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 col-lg-4 div_card_recreationSubPage mx-auto ">
                            <Card className="card_recreationSubPage">
                                <Card.Img className="img_card_recreationSubPage" variant="top" src={"assets/media/recreationPage_trekking.jpg"} />
                                <Card.Body className="cardBody_recreationSubPage">
                                    <Card.Title>Trekking</Card.Title>
                                    <Card.Text className="text-justify">
                                        Tapovana is a resort set in the beautiful high mountains between the beautiful farmlands that yield from sweet-smelling coffee to strong tea leaves. Imagine trolling between them during the sunny day or having spent a pleasant starry night in a small camp settled by you. We will look forward to your visit to Tapovana.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_recreationSubPage" value="Show More" onClick={() => { navigate("/recreationTrekking") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 col-lg-4 div_card_recreationSubPage mx-auto ">
                            <Card className="card_recreationSubPage">
                                <Card.Img className="img_card_recreationSubPage" variant="top" src={"assets/media/recreationPage_birdWatching.jpg"} />
                                <Card.Body className="cardBody_recreationSubPage">
                                    <Card.Title>Bird Watching</Card.Title>
                                    <Card.Text className="text-justify">
                                        Bird watching is a work of art which is done by a very few people. We at Tapovana have skilled staff who will help you with the bird-watching activities. Bird watching can be done by our naked eyes, or we can use devices. Devices such as DSLR cameras or binoculars are used for the process.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_recreationSubPage" value="Show More" onClick={() => { navigate("/recreationBirdwatching") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 col-lg-4 div_card_recreationSubPage mx-auto ">
                            <Card className="card_recreationSubPage">
                                <Card.Img className="img_card_recreationSubPage" variant="top" src={"assets/media/recreationPage_plantationWalk.jpg"} />
                                <Card.Body className="cardBody_recreationSubPage">
                                    <Card.Title>Plantation Walk</Card.Title>
                                    <Card.Text className="text-justify">
                                        Tapovana is located in the middle of many different types of plantations. As the entire area is covered by a complete plantation, it’s like a feast for the eyes. Till where one’s eyes will go, all they see is beautiful green lush lands and pleasant weather. Anyone who likes to enjoy the beauty of greens, this place is for you.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_recreationSubPage" value="Show More" onClick={() => { navigate("/recreationPlantationwalk") }} />
                                </Card.Body>
                            </Card>
                        </div>

                        <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 col-lg-4 div_card_recreationSubPage ml-auto ">
                            <Card className="card_recreationSubPage">
                                <Card.Img className="img_card_recreationSubPage" variant="top" src={"assets/media/recreationPage_villageVisit.jpg"} />
                                <Card.Body className="cardBody_recreationSubPage">
                                    <Card.Title>Village Visit</Card.Title>
                                    <Card.Text className="text-justify">
                                        We at Tapovana take our guests for tours of all the villages which have heritage sites. We make sure our guests travels to most of these villages and know about the culture and activities. People can visit all the towns and get mingled with the locals. Our guests can learn about the local clothing and food styles.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_recreationSubPage" value="Show More" onClick={() => { navigate("/recreationVillagevisit") }} />
                                </Card.Body>
                            </Card>
                        </div>


                        <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 col-lg-4 div_card_recreationSubPage mr-auto ">
                            <Card className="card_recreationSubPage">
                                <Card.Img className="img_card_recreationSubPage" variant="top" src={"assets/media/recreationPage_indoorGames.jpg"} />
                                <Card.Body className="cardBody_recreationSubPage">
                                    <Card.Title>Indoor Games</Card.Title>
                                    <Card.Text className="text-justify">
                                        We need to make sure that we make these children more active and make them interact with people. By playing Indoor games, the children can interact well with others. By playing Indoor games, they can learn many new things from people they interact with.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_recreationSubPage" value="Show More" onClick={() => { navigate("/recreationIndoorgames") }} />
                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                </div>
            </section>


            <WebsiteFooter />

        </>
    )
}

export default RecreationMountainbiking
