import React, { useEffect } from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';

import EventsFacilities from '../Components/EventsFacilities';

const EventsWedding = () => {

    useEffect(() => {
        window.scrollTo(0, 0);

    }, [])

    return (
        <>
            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>

            <section className="packagesPage_overview pt-3 section_heading">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Wedding Event Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                A fairy tale wedding is every couple's dream. At Tapovana, we organize dream destination weddings with luxuriant themes and décors, far away from the cacophony and chaos. A perfect wedding is where the loved ones from both families bond over rituals, customs and fun games. At Tapovana, we take care of all the preparations and arrangements, giving the bride and groom sides precious time to enjoy the wedding proceedings in a relaxed, rejuvenated state. Choose from our specially curated wedding packages.
                            </p>
                            <p className="text-justify">
                                It will be a wedding, just the way you want it.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_packagesSubPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img src={"assets/media/eventsPage_wedding.jpg"} className="packagesPage_banner subPage_banner" alt="Events Wedding Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <EventsFacilities />

            <WebsiteFooter />
        </>
    )
}

export default EventsWedding
