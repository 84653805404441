import React from 'react'
import * as BsIcons from "react-icons/bs";

const RoomFacilities = () => {

    return (
        <>

            <section className="section_room_facilities" id="section_room_facilities">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            {(window.location.pathname == "/roomHilltopCottage") ? (<h2 className="text-cener script_font">Hilltop Cottage Facilities</h2>) : ("")}
                            {(window.location.pathname == "/roomHeritageThottimane") ? (<h2 className="text-cener script_font">Heritage Thottimane Facilities</h2>) : ("")}
                            {(window.location.pathname == "/roomValleyViewVilla") ? (<h2 className="text-cener script_font">Valley View Villa Facilities</h2>) : ("")}
                            {(window.location.pathname == "/roomLuxuryVilla") ? (<h2 className="text-cener script_font">Luxury Villa Facilities</h2>) : ("")}
                            {(window.location.pathname == "/roomPrivatePoolVilla") ? (<h2 className="text-cener script_font">Private Pool Villa Facilities</h2>) : ("")}

                        </div>
                        <div className="col-lg-2"></div>
                        <div className="col-md-6 col-lg-4">
                            <div className="">
                                <ul className="cottages_facilities_ul">
                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Welcome drink upon arrival</li>
                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Well stocked mini bar</li>
                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Individual air-conditioning</li>
                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> 24-hours hot and cold water</li>
                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Eco-friendly bath amenities</li>
                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> 24-hours Housekeeping</li>
                                    <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Secretarial services on request</li>
                                    {(window.location.pathname == "/roomHeritageThottimane") ? (<li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Indoor traditional swing</li>) : ("")}
                                    {(window.location.pathname == "/roomValleyViewVilla") ? (<li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Spacious living room</li>) : ("")}
                                    {(window.location.pathname == "/roomLuxuryVilla") ? (<li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Spacious living room</li>) : ("")}
                                    {(window.location.pathname == "/roomPrivatePoolVilla") ? (<li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Attached private pool and deck</li>) : ("")}

                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <ul className="cottages_facilities_ul">
                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Complimentary tea/coffee maker</li>
                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Soundproof Rooms</li>
                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Round the clock menu</li>
                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Digital in-room safe</li>
                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> 43" flat screen TV</li>
                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Shower cubicle</li>
                                <li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Check In:12 Noon/Check Out:10 AM</li>
                                {(window.location.pathname == "/roomHeritageThottimane") ? (<li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Common hall with central courtyard</li>) : ("")}
                                {(window.location.pathname == "/roomValleyViewVilla") ? (<li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Duplex kids bed</li>) : ("")}
                                {(window.location.pathname == "/roomLuxuryVilla") ? (<li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Lilly pool</li>) : ("")}
                                {(window.location.pathname == "/roomPrivatePoolVilla") ? (<li className="cottages_facilities_li"><BsIcons.BsCheckAll /> Two bed rooms</li>) : ("")}

                            </ul>
                        </div>
                        <div className="col-lg-2"></div>

                    </div>
                </div>
            </section>


        </>
    )
}

export default RoomFacilities
