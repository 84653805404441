
import React, { useEffect, useState } from 'react';

import { Carousel } from 'react-bootstrap';

import WebsiteFooter from './Components/WebsiteFooter';
import ThingsToDoCarouselRound from './Components/ThingsToDoCarouselRound';
import { useNavigate } from 'react-router-dom';

import * as FaIcons from "react-icons/fa";


import { Card } from "react-bootstrap";
import moment from 'moment';

function Home() {


    var navigate = useNavigate();

    const [checkIn, setCheckIn] = useState(moment(new Date()).format("yyyy-MM-DD"))
    console.log(checkIn)
    const [minCheckIn, setMinCheckIn] = useState(moment(new Date()).format("yyyy-MM-DD"))
    const [maxCheckIn, setMaxCheckIn] = useState("")
    const [checkOut, setCheckOut] = useState("")
    const [minCheckOut, setMinCheckOut] = useState("")
    const [maxCheckOut, setMaxCheckOut] = useState("")

    useEffect(() => {

        window.scrollTo(0, 0)
        let height_arr = [];

        let ourCottagesCardBody = document.getElementsByClassName("ourCottages_cardBody");

        for (let i = 0; i < ourCottagesCardBody.length; i++) {
            ourCottagesCardBody[i].style.display = "block";
            let height_data = ourCottagesCardBody[i].clientHeight;
            height_arr.push(parseInt(height_data));
            ourCottagesCardBody[i].style.display = "none";
        }
        ourCottagesCardBody[0].style.display = "block";
        var max_height = (Math.max.apply(Math, height_arr));

        for (let i = 0; i < ourCottagesCardBody.length; i++) {
            ourCottagesCardBody[i].style.height = max_height + "px";
        }

        let col_height = document.getElementById("allCards").clientHeight;
        if (window.matchMedia('(max-width: 580px)').matches) {
            document.getElementById("img_ourCottages").style.maxHeight = col_height + "px";
        } else {
            document.getElementById("img_ourCottages").style.height = col_height + "px";
        }


        let features_img = document.getElementsByClassName("img_features");
        let features_width = features_img[0].clientWidth;

        let features_text = document.getElementsByClassName("div_features_text");

        for (let i = 0; i < features_text.length; i++) {
            features_text[i].style.width = features_width + "px";
        }

        let navarasa_height_arr = [];

        let cardBodyNavarasa = document.getElementsByClassName("cardBody_navarasa");

        for (let i = 0; i < cardBodyNavarasa.length; i++) {
            cardBodyNavarasa[i].style.display = "block";
            let navarasa_height_data = cardBodyNavarasa[i].clientHeight;
            navarasa_height_arr.push(parseInt(navarasa_height_data));
        }
        var navarasa_max_height = (Math.max.apply(Math, navarasa_height_arr));

        for (let i = 0; i < cardBodyNavarasa.length; i++) {
            cardBodyNavarasa[i].style.height = (navarasa_max_height) + "px";
        }

        const checkPosition = () => {
            if (window.matchMedia('(max-width: 580px)').matches) {
                console.log("media query applied");
            } else {
                console.log("media query not applied");
            }
        }

        checkPosition();

        setDates();

    }, [])

    const setDates = () => {
        setMinCheckIn(moment(new Date()).format("yyyy-MM-DD"))
        setMaxCheckIn(moment(new Date()).format("yyyy-MM-DD"))
        setMinCheckOut(moment(new Date()).add(1, 'days').format("yyyy-MM-DD"))
        setMaxCheckOut(moment(new Date()).add(1, 'days').format("yyyy-MM-DD"))

        setCheckIn(moment(new Date()).format("yyyy-MM-DD"))
        setCheckOut(moment(new Date()).add(1, 'days').format("yyyy-MM-DD"))
    }

    const setCheckInDate = (e) => {
        var check_in = e.target.value
        var check_out = moment(check_in).add(1, "days").format("yyyy-MM-DD")
        setMinCheckOut(check_out)

        setCheckIn(check_in)
        setCheckOut(check_out)
    }

    const setCheckOutDate = (e) => {
        var check_out = e.target.value
        setCheckOut(check_out)
    }

    const expandOurCottagesCardBody = (classNumber) => {
        if (classNumber == 0) { document.getElementById("img_ourCottages").src = "assets/media/roomPage_home_hilltop_cottage.jpg"; }
        if (classNumber == 1) { document.getElementById("img_ourCottages").src = "assets/media/roomPage_home_heritage_thottimane.jpg"; }
        if (classNumber == 2) { document.getElementById("img_ourCottages").src = "assets/media/roomPage_home_valley_view_villa.jpg"; }
        if (classNumber == 3) { document.getElementById("img_ourCottages").src = "assets/media/roomPage_home_luxury_villa.jpg"; }
        if (classNumber == 4) { document.getElementById("img_ourCottages").src = "assets/media/roomPage_home_private_pool_villa.jpg"; }

        let ourCottagesCardBody = document.getElementsByClassName("ourCottages_cardBody");
        for (let i = 0; i < ourCottagesCardBody.length; i++) {
            ourCottagesCardBody[i].style.display = "none";
        }
        document.getElementsByClassName("ourCottages_cardBody")[classNumber].style.display = "block";
    }

    const showFeaturesText = (e) => {
        e.currentTarget.classList.toggle("h-100");
    }

    const checkVideo = () => {
        const testDiv = document.getElementsByClassName('section_walk_around')[0];
        console.log(testDiv.offsetTop);
        if (window.scrollY >= testDiv.offsetTop) {
            console.log(true);

            document.getElementById("video_walkAroundVideo").src = "https://www.youtube.com/embed/cNqU-r598OE?autoplay=1&mute=1";
        }
        else {
            document.getElementById("video_walkAroundVideo").src = "https://www.youtube.com/embed/cNqU-r598OE";
            console.log(false);
        }
    };

    const OpenInNewTab = (Type, Url) => {
        if (Type == "Link") {
            const newWindow = window.open(Url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }

    const submitHomeForm = (e) => {
        e.preventDefault();
        var check_in = e.target.check_in.value;
        var check_out = e.target.check_out.value;
        var type = "room"
        var url = (global.config.booking_url + "?check_in=" + check_in + "&check_out=" + check_out + "&type=" + type)
        OpenInNewTab("Link", url)
    }


    return (
        <>

            <section className="section_home_banner " id="section_home_banner">
                <div className="div_carousel_book w-100">
                    <div className="container">
                        <div className="row ">
                            <div className="col-md-1"></div>
                            <div className="col-md-10 col_carousel_book  mx-auto w-100">
                                <div className="card card_carousel_book w-100">
                                    <form onSubmit={submitHomeForm}>
                                        <div className="row">
                                            <div className="col-md-3 mx-auto">
                                                <div className="form-floating mb-3">
                                                    <select className="form-select" id="floatingSelect" aria-label="Floating label select example">
                                                        <option selected value="Tapovana Sakleshpur">Tapovana Sakleshpur</option>
                                                    </select>
                                                    <label for="floatingSelect">Select </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-floating mb-3">
                                                    <input type="date" name="check_in" className="form-control" id="floatingInput" placeholder="Check In" min={minCheckIn} max={maxCheckIn} value={checkIn} required onChange={setCheckInDate} />
                                                    <label for="floatingInput">Check In</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-floating mb-3">
                                                    <input type="date" name="check_out" className="form-control" id="floatingInput" placeholder="Check Out" min={minCheckOut} max={maxCheckOut} value={checkOut} required onChange={setCheckOutDate} />
                                                    <label for="floatingInput">Check Out</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <input type="submit" name="booking_submit" id="booking_submit" className="btn_book form-control booking_submit btn btn_header" value="Book Now" />
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                    </div>
                </div>
                <div className="div_carousel">
                    <Carousel>
                        <Carousel.Item>
                            <img className="img_carousel" src={"assets/media/banner1.jpg"} alt="First slide" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="img_carousel" src={"assets/media/banner4.jpg"} alt="Third slide" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="img_carousel" src={"assets/media/banner5.jpg"} alt="Third slide" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="img_carousel" src={"assets/media/banner6.jpg"} alt="Third slide" />
                        </Carousel.Item>
                    </Carousel>
                </div>
            </section>

            <section className="section_overview text-center section_heading" id="section_overview">
                <div className="container">
                    <div className="row">
                        <div className="col-12 div_heading">
                            <h1 className="text-cener script_font ">Welcome to Tapovana</h1>
                            <div className="div_heading">
                                <img src={"assets/media/namaste.png"} alt="namaste" style={{ width: "80px" }} data-aos="zoom-in" />
                            </div>
                            <h4 className="cursive_font mt-2 ">WAKE UP AND SMELL COFFEE.</h4>
                            <p className="text-center">
                                Weekend escapades can’t get any better than this.
                            </p>
                            <p className="text-center">
                                Imagine leaving the bustle of city life and arriving at a luxurious abode surrounded by coffee plantations, right in the lap of nature in a matter of few hours! Situated in the quaint and picturesque coffee town of Sakleshpur, about 220 KMs from Bengaluru, and in closer proximity to Mysore and Mangalore, Tapovana is an ideal destination for disengaging from the stress and sweat of the urban life.
                            </p>
                            <p className="text-center">
                                Here, you can find a lot to do - or nothing at all. For explorers and adventurers, there are ancient temples and forts to discover and pleasant nature hikes to enjoy. For those who like to relax – probably cuddled up with a good book - there is an endless supply of fresh coffee and majestic sunsets to witness.
                            </p>
                            <p className="text-center">
                                When you come to Tapovana, you don’t just cover miles; you travel back in time to a purer, more enriching way of living.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_our_room bg_colour text-center section_heading" id="section_our_room" >
                <div className="container">
                    <div className="row">
                        <div className="col-12 div_heading">
                            <h1 className="text-cener script_font ">Our Rooms</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 pr0 col_imgOurCottages">
                            <img className="img_ourCottages" id="img_ourCottages" src={"assets/media/roomPage_home_hilltop_cottage.jpg"} />
                        </div>
                        <div className="col-lg-4 pl0 col_cardOurCottages" id="col_cardOurCottages">
                            <div className="allCards" id="allCards">

                                <div className="card">
                                    <div className="card-header bg-dark text-light room_heading" onClick={() => expandOurCottagesCardBody(0)} >
                                        <b>HILLTOP COTTAGE</b>
                                    </div>
                                    <div className="card-body ourCottages_cardBody text-justify" >
                                        <p>Best suited for couples and small families, our Hilltop Cottages are modern private spaces carved in ancient Malnad architecture. The laterite cladding, Athangudi tiles, and smooth wooden pillars used in the cottage architecture are redolent of the rich cultural heritage of the land.</p>
                                        <div className="text-center">
                                            <input type="button" className="btn btn_header" value="Show More" onClick={() => { navigate("/roomHilltopCottage") }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header bg-dark text-light room_heading" onClick={() => expandOurCottagesCardBody(1)} >
                                        <b>HERITAGE THOTTIMANE</b>
                                    </div>
                                    <div className="card-body ourCottages_cardBody text-justify" style={{ display: "none" }} >
                                        <p>Heritage Thottimane is an ancient type of Indian way of constructing houses. As we are Indians, having a yard in the house is like a must. Thottimane is made in the form of traditional architecture Kerala homes which is very rare these days.  </p>
                                        <div className="text-center">
                                            <input type="button" className="btn btn_header" value="Show More" onClick={() => { navigate("/roomHeritageThottimane") }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header bg-dark text-light room_heading" onClick={() => expandOurCottagesCardBody(2)} >
                                        <b>VALLEY VIEW VILLA</b>
                                    </div>
                                    <div className="card-body ourCottages_cardBody text-justify" style={{ display: "none" }} >
                                        <p>The valley view villa type is the special one in Tapovana. We have made this villa so that our guests can get the best view of the mountains. By taking the excellent view out of one’s window, one can see the deep valleys.</p>
                                        <div className="text-center">
                                            <input type="button" className="btn btn_header" value="Show More" onClick={() => { navigate("/roomValleyViewVilla") }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header bg-dark text-light room_heading" onClick={() => expandOurCottagesCardBody(3)} >
                                        <b>LUXURY VILLA</b>
                                    </div>
                                    <div className="card-body ourCottages_cardBody text-justify" style={{ display: "none" }} >
                                        <p>Tapovana gave an excellent thought about a luxurious lifestyle and has made sure that our guests can have that beautiful experience. This way, our guests will have the best family time experience and can live like a king. </p>
                                        <div className="text-center">
                                            <input type="button" className="btn btn_header" value="Show More" onClick={() => { navigate("/roomLuxuryVilla") }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header bg-dark text-light room_heading" onClick={() => expandOurCottagesCardBody(4)} >
                                        <b>PRIVATE POOL VILLA</b>
                                    </div>
                                    <div className="card-body ourCottages_cardBody text-justify" style={{ display: "none" }} >
                                        <p>Private Pool villas are palatial and sumptuously adorned. Whether coming for a family outing or a quiet romantic getaway, an exclusive stay at this villa is your answer to living majestically right in the midst of nature.</p>

                                        <div className="text-center">
                                            <input type="button" className="btn btn_header" value="Show More" onClick={() => { navigate("/roomPrivatePoolVilla") }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_features section_heading" id="section_features">
                <div className="container ">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font ">Experience Navarasa</h1>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <p className="text-center">
                                    Stepping inside Tapovana is a treat to all your senses.
                                </p>
                                <p className="text-center">
                                    The old soul of Sakleshpur is rich in tradition. At Tapovana, we help you experience the antiquity and cultural heritage of glorious ancient India, evoking in you all the nine rasas – beauty, wonder, laughter and tranquillity through the loving hospitality and pleasant stay, and emotions like anger, sorrow, disgust, fear, and courage through colloquial storytelling in the form of folk songs and tribal dances.
                                </p>
                                <p className="text-center">
                                    Wise men say that a person lives entirely only when s/he experiences all the nine emotions or the navarasa. At Tapovana, we curate immersive experiences engaging the whole spectrum of emotions.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="container ">
                        <div className="row ">
                            <div className="col-12 navarasa_bg_colour">

                                <div className="row row_navarasa" >
                                    <div className="col-md-4 div_card_navarasa " data-aos="zoom-in" data-aos-duration="1500">
                                        <Card className="card_navarasa"  >
                                            <Card.Img className="img_card_navarasa" variant="top" src={"assets/media/navarasa/navarasa_architecture.jpg"} />
                                            <Card.Body className="cardBody_navarasa">
                                                <h6 className="text-center">ARCHITECTURE & INTERIORS</h6>
                                                <p className="w-100 text-center ">
                                                    Malnad, Kerala, Chettinad
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-md-4 div_card_navarasa " data-aos="zoom-in" data-aos-duration="1500">
                                        <Card className="card_navarasa">
                                            <Card.Img className="img_card_navarasa" variant="top" src={"assets/media/navarasa/navarasa_cuisine.jpg"} />
                                            <Card.Body className="cardBody_navarasa">
                                                <h6 className="text-center">CUISINE</h6>
                                                <p className="w-100 text-center ">
                                                    Emphasis on Regional Delicacy
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-md-4 div_card_navarasa " data-aos="zoom-in" data-aos-duration="1500">
                                        <Card className="card_navarasa">
                                            <Card.Img className="img_card_navarasa" variant="top" src={"assets/media/navarasa/navarasa_bullockCart.jpg"} />
                                            <Card.Body className="cardBody_navarasa">
                                                <h6 className="text-center">BACK TO ROOTS!</h6>
                                                <p className="w-100 text-center ">
                                                    Story Telling, Veg Farming, Village Visit
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>

                                <div className="row row_navarasa" >
                                    <div className="col-md-4 div_card_navarasa " data-aos="zoom-in" data-aos-duration="1500">
                                        <Card className="card_navarasa">
                                            <Card.Img className="img_card_navarasa" variant="top" src={"assets/media/navarasa/navarasa_game.jpg"} />
                                            <Card.Body className="cardBody_navarasa">
                                                <h6 className="text-center">NOSTALGIC GAMES</h6>
                                                <p className="w-100 text-center ">
                                                    Chauka Bhara, Anguli Mane, Bullock-cart Ride, Pot Making
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-md-4 div_card_navarasa " data-aos="zoom-in" data-aos-duration="1500">
                                        <Card className="card_navarasa">
                                            <Card.Img className="img_card_navarasa" variant="top" data-aos-duration="1500" src={"assets/media/navarasa/navarasa_cycle.jpg"} />
                                            <Card.Body className="cardBody_navarasa">
                                                <h6 className="text-center">NATURE LOVERS!</h6>
                                                <p className="w-100 text-center ">
                                                    Guided Coffee Trekking, Wild life Exp, Cycling Circuits
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-md-4 div_card_navarasa " data-aos="zoom-in" data-aos-duration="1500">
                                        <Card className="card_navarasa">
                                            <Card.Img className="img_card_navarasa" variant="top" src={"assets/media/navarasa/navarasa_spa.jpg"} />
                                            <Card.Body className="cardBody_navarasa">
                                                <h6 className="text-center">WELLNESS PROGRAMS</h6>
                                                <p className="w-100 text-center ">
                                                    Yoga, SPA, Rejuvenation Packages
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                                <div className="row row_navarasa">
                                    <div className="col-md-4 div_card_navarasa " data-aos="zoom-in" data-aos-duration="1500">
                                        <Card className="card_navarasa">
                                            <Card.Img className="img_card_navarasa" variant="top" src={"assets/media/navarasa/navarasa_kathakali.jpg"} />
                                            <Card.Body className="cardBody_navarasa">
                                                <h6 className="text-center">ART AND CULTURE</h6>
                                                <p className="w-100 text-center ">
                                                    Kathakali, Yakshagana, Bharatnatyam
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-md-4 div_card_navarasa " data-aos="zoom-in" data-aos-duration="1500">
                                        <Card className="card_navarasa">
                                            <Card.Img className="img_card_navarasa" variant="top" src={"assets/media/navarasa/navarasa_wedding.jpg"} />
                                            <Card.Body className="cardBody_navarasa">
                                                <h6 className="text-center">DESTINATION WEDDING</h6>
                                                <p className="w-100 text-center ">
                                                    MICE, Banquet Hall, Open Air Theater
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-md-4 div_card_navarasa " data-aos="zoom-in" data-aos-duration="1500">
                                        <Card className="card_navarasa">
                                            <Card.Img className="img_card_navarasa" variant="top" src={"assets/media/navarasa/navarasa_spiritual.jpg"} />
                                            <Card.Body className="cardBody_navarasa">
                                                <h6 className="text-center">GUIDED SPIRITUAL TOURS</h6>
                                                <p className="w-100 text-center ">
                                                    Siva Circuit, Dharmasthala
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </section>

            <section className="section_features bg_colour div_heading" id="section_features">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_features_header div_heading">
                            <h1 className="text-cener script_font">Features</h1>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3 div_features mb-3 " data-aos="fade-up" data-aos-duration="1500">

                            <div className="div_features_text" onClick={(e) => showFeaturesText(e)}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 className="features_text_header">Facilites</h5>
                                            <p className="text-justify" >
                                                At Tapovana, you can find many amenities. You will not feel like staying in the outskirts as all of you will be here. And nothing that you need will be missing. You get the best facilities and all just one call away.
                                            </p>
                                            <input type="button" value="Show More" className="btn btn_header" onClick={() => { navigate("/facilities") }} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="div_features_img" onClick={() => { navigate("/facilities") }} >
                                <img className="img_features" src={"assets/media/features3.jpg"} />
                                <div className="div_middle_click_btn">
                                    <FaIcons.FaHandPointUp className="middle_click_btn" />
                                </div>

                            </div>
                        </div>

                        <div className="col-md-3 div_features mb-3" data-aos="fade-up" data-aos-duration="1500">

                            <div className="div_features_text" onClick={(e) => showFeaturesText(e)}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 className="features_text_header">Dining</h5>
                                            <p className="text-justify">
                                                Dining is considered to be one of the most essential parts of any resort. So we at Tapovana have made sure that our guests have the best dining experience during their stay at our resort.
                                            </p>
                                            <input type="button" value="Show More" className="btn btn_header" onClick={() => { navigate("/dining") }} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="div_features_img" onClick={() => { navigate("/dining") }} >
                                <img className="img_features" src={"assets/media/features1.jpg"} />
                                <div className="div_middle_click_btn">
                                    <FaIcons.FaHandPointUp className="middle_click_btn" />
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 div_features mb-3" data-aos="fade-up" data-aos-duration="1500"  >
                            <div className="div_features_text" onClick={(e) => showFeaturesText(e)}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 className="features_text_header">Banquet</h5>
                                            <p className="text-justify">
                                                At Tapovana, we have the service for big gatherings also. Everyone sitting at one table and having food is one of the best things that can happen these days. As it is rare but always enjoyable. So, just tell us the occasion and count, and we’re ready to go for a big feast.
                                            </p>
                                            <input type="button" value="Show More" className="btn btn_header" onClick={() => { navigate("/banquet") }} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="div_features_img" onClick={() => { navigate("/banquet") }}>
                                <img className="img_features" src={"assets/media/features2.jpg"} />
                                <div className="div_middle_click_btn">
                                    <FaIcons.FaHandPointUp className="middle_click_btn" />
                                </div>

                            </div>

                        </div>
                        <div className="col-md-3 div_features mb-3" data-aos="fade-up" data-aos-duration="1500"  >
                            <div className="div_features_text" onClick={(e) => showFeaturesText(e)}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 className="features_text_header">Recreation</h5>
                                            <p className="text-justify">
                                                Recreational activities are the most crucial part of any vacation. All the guests look forward to what activities they will be part of while on their vacation. We at Tapovana provide the best recreation activities to be enjoyed by the guests.
                                            </p>

                                            <input type="button" value="Show More" className="btn btn_header" onClick={() => { navigate("/recreation") }} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="div_features_img" onClick={() => { navigate("/recreation") }} >
                                <img className="img_features" src={"assets/media/features4.jpg"} />
                                <div className="div_middle_click_btn">
                                    <FaIcons.FaHandPointUp className="middle_click_btn" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_walk_around section_heading" id="section_walk_around">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Virtual Tour</h1>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 p-0">
                            <iframe src='https://www.youtube.com/embed/cNqU-r598OE'
                                className="w-100 video_walkAroundVideo" id="video_walkAroundVideo"
                                frameborder='0'
                                allow='autoplay; encrypted-media'
                                allowfullscreen
                                title='video'
                            />
                        </div>
                    </div>
                </div>
            </section>

            <ThingsToDoCarouselRound />
            <section className="section_location " id="section_location" style={{ marginBottom: "-5px" }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Location</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center p-0" onClick={() => OpenInNewTab("link", "https://goo.gl/maps/8XK6EHUHfGVAJyNu9")}>
                            {/* <iframe onClick={() => OpenInNewTab("link", "https://goo.gl/maps/8XK6EHUHfGVAJyNu9")} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1943.9739739853508!2d75.6877242!3d12.9751811!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba4d746bd533185%3A0x375bc3c6ee67c58a!2sTapovana%20Heritage%20Resorts%20and%20SPA!5e0!3m2!1sen!2sin!4v1658396977200!5m2!1sen!2sin" className="home_location" allowfullscreen="" aria-hidden="false" tabindex="0" width="100%" frameborder="0"></iframe> */}
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1943.9739739853508!2d75.6877242!3d12.9751811!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba4d746bd533185%3A0x375bc3c6ee67c58a!2sTapovana%20Heritage%20Resorts%20and%20SPA!5e0!3m2!1sen!2sin!4v1658396977200!5m2!1sen!2sin" width={"100%"} height={600} style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>

            </section>
            <WebsiteFooter />
        </>
    )
}

export default Home