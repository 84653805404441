import React, { useEffect } from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';

import { Card } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';


const DiningMoonlight = () => {
    var navigate = useNavigate();

    
    useEffect(() => {
        window.scrollTo(0, 0)
        let height_arr = [];

        let cardBody_diningSubPage = document.getElementsByClassName("cardBody_diningSubPage");

        for (let i = 0; i < cardBody_diningSubPage.length; i++) {
            cardBody_diningSubPage[i].style.display = "block";
            let height_data = cardBody_diningSubPage[i].clientHeight;
            height_arr.push(parseInt(height_data));
        }
        var max_height = (Math.max.apply(Math, height_arr));

        for (let i = 0; i < cardBody_diningSubPage.length; i++) {
            cardBody_diningSubPage[i].style.height = (max_height + 20) + "px";
        }

    }, [])

    return (
        <>

            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>
            <section className="diningMoonlightPage_overview section_heading">

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Moonlight Dining Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                This one is for die-hard romantics and nature lovers. Dining under the silver glow of the moon, away from the crowd and other vacationers, savouring delicious food, and the breathtaking landscape in the company of your loved ones is once in a lifetime experience. And we make it happen for you! Yes, we let you choose a location outside the resort, arrange for a comfortable dining setting with decorations of your choice, and ensure that you enjoy your meal in complete privacy and safety with caring staff members available at a call to serve you. Sounds fantastic? It is indeed a unique experience you must have while you stay with us.
                            </p>

                        </div>
                    </div>
                </div>
            </section>

            <section className="section_diningSubPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img src={"assets/media/diningPage_moonlight.jpg"} className="diningMoonlightPage_banner subPage_banner" alt="Dining Moonlight Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_features section_subHeading" id="section_features">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            <h2 className="text-cener script_font">Other Dinings</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-lg-4 mx-auto div_card_diningSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_diningSubPage">
                                <Card.Img className="img_card_diningSubPage" variant="top" src={"assets/media/diningPage_restaurant.jpg"} />
                                <Card.Body className="cardBody_diningSubPage">
                                    <Card.Title>Restaurant</Card.Title>
                                    <Card.Text className="text-justify">
                                        The restaurants present at Tapovana are a one-stop for all your food needs. We serve all different kinds of cuisines. We provide services from fine dining to casual dining. Our guests can have the experience of all the different types of dining. We have other restaurants that even include contemporary, fast food, appetisers ambience-style.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_diningSubPage" value="Show More" onClick={() => { navigate("/diningRestaurant") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-4 mx-auto div_card_diningSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_diningSubPage">
                                <Card.Img className="img_card_diningSubPage" variant="top" src={"assets/media/diningPage_roomservice.jpg"} />
                                <Card.Body className="cardBody_diningSubPage">
                                    <Card.Title>Room Service</Card.Title>
                                    <Card.Text className="text-justify">
                                        We all know the best part of going to a resort or hotel is the room service. The room services offered by Tapovana are of the best quality. None of our guests will have any kind of complaints about the room service provided by our staff. We have a completely different set of people who will be just one call.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_diningSubPage" value="Show More" onClick={() => { navigate("/diningRoomservice") }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-4 mx-auto div_card_diningSubPage" data-aos="fade-up" data-aos-duration="1000">
                            <Card className="card_diningSubPage">
                                <Card.Img className="img_card_diningSubPage" variant="top" src={"assets/media/diningPage_poolside.jpg"} />
                                <Card.Body className="cardBody_diningSubPage">
                                    <Card.Title>Pool Side</Card.Title>
                                    <Card.Text className="text-justify">
                                        During a hot day, all we need is a good dip in the pool and relax. So, Tapovana has made sure of that interest of our guests. Because of this interest of our guests, we have the facility of dining near the pool. This dining facility is again available for all our guests.
                                    </Card.Text>
                                    <input type="button" className="btn btn_card_diningSubPage" value="Show More" onClick={() => { navigate("/diningPoolside") }} />
                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                </div>
            </section>


            <WebsiteFooter />

        </>
    )
}

export default DiningMoonlight
