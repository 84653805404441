import React, { useContext, useEffect, useState } from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';

import { Card, Accordion, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Slider from "react-slick";

import Axios from 'axios';

import $ from "jquery";
import { ContextData } from '../Components/ContextData';
var moment = require("moment");

const PackagesWellness = () => {
    var navigate = useNavigate();
    const context_data = useContext(ContextData)
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        let packages_height_arr = [];

        let cardBody_packagesPage = document.getElementsByClassName("cardBody_packagesPage");

        for (let i = 0; i < cardBody_packagesPage.length; i++) {
            cardBody_packagesPage[i].style.display = "block";
            let packages_height_data = cardBody_packagesPage[i].clientHeight;
            packages_height_arr.push(parseInt(packages_height_data));
        }
        var packages_max_height = (Math.max.apply(Math, packages_height_arr));

        for (let i = 0; i < cardBody_packagesPage.length; i++) {
            cardBody_packagesPage[i].style.height = (packages_max_height + 20) + "px";
        }

        getTariffData();

    }, [])

    const [tariffData, setTariffData] = useState(null);


    const getTariffData = async () => {
        var getTariffsData = {
            type: "package",
            package_name: "honeymoon",
            check_in_day: moment(new Date()).format("D"),
            check_in_month: moment(new Date()).format("M"),
        };

        
        const getTariffsUrl = global.config.server_url + "getRoomDetails";
        Axios.post(getTariffsUrl, getTariffsData).then((tariffResponse) => {
            try {  setTariffData(tariffResponse.data); }
            catch { console.log("error"); }
        }).catch(err => console.log(err));

    }

    const cardKeyMouseIn = (key) => {
        let idname = "accordionToggle";
        idname = idname.concat(key);
        document.getElementById(idname).click();
    }

    const cardKeyMouseOut = (key) => {
        let idname = "accordionToggle";
        idname = idname.concat(key);
        document.getElementById(idname).click();
    }

    const btnShowFacilitiesMobile = () => {
        if (window.matchMedia('(min-width: 992px)').matches) {
            $(".btn_show_facilities").hide();
        } else {
            $(".btn_show_facilities").show();
        }
    }

    function showDiscountAmount(tariff) {
        var weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var weekday = weekdays[new Date().getDay()];

        var base_discount_perc = (tariff.base_discount_perc) ? parseInt(tariff.base_discount_perc) : 0;

        var monday_discount_perc = (tariff.monday_discount_perc) ? parseInt(tariff.monday_discount_perc) : 0;
        var tuesday_discount_perc = (tariff.tuesday_discount_perc) ? parseInt(tariff.tuesday_discount_perc) : 0;
        var wednesday_discount_perc = (tariff.wednesday_discount_perc) ? parseInt(tariff.wednesday_discount_perc) : 0;
        var thursday_discount_perc = (tariff.thursday_discount_perc) ? parseInt(tariff.thursday_discount_perc) : 0;
        var friday_discount_perc = (tariff.friday_discount_perc) ? parseInt(tariff.friday_discount_perc) : 0;
        var saturday_discount_perc = (tariff.saturday_discount_perc) ? parseInt(tariff.saturday_discount_perc) : 0;
        var sunday_discount_perc = (tariff.sunday_discount_perc) ? parseInt(tariff.sunday_discount_perc) : 0;

        var day_discount_perc = (tariff.day_discount_perc) ? parseInt(tariff.day_discount_perc) : 0;

        var two_adult_amount = tariff.two_adult ? parseInt(tariff.two_adult) : 0;

        var room_discount_amount = 0;

        if (day_discount_perc) {
            room_discount_amount = parseInt(two_adult_amount) * parseInt(day_discount_perc) / 100;
        }
        else if ((weekday.toLowerCase() == "monday" && monday_discount_perc) || (weekday.toLowerCase() == "tuesday" && tuesday_discount_perc) || (weekday.toLowerCase() == "wednesday" && wednesday_discount_perc) || (weekday.toLowerCase() == "thursday" && thursday_discount_perc) || (weekday.toLowerCase() == "friday" && friday_discount_perc) || (weekday.toLowerCase() == "saturday" && saturday_discount_perc) || (weekday.toLowerCase() == "sunday" && sunday_discount_perc)) {
            switch (weekday.toLowerCase()) {
                case "monday":
                    room_discount_amount = parseInt(two_adult_amount) * parseInt(monday_discount_perc) / 100;
                    break;
                case "tuesday":
                    room_discount_amount = parseInt(two_adult_amount) * parseInt(tuesday_discount_perc) / 100;
                    break;
                case "wednesday":
                    room_discount_amount = parseInt(two_adult_amount) * parseInt(wednesday_discount_perc) / 100;
                    break;
                case "thursday":
                    room_discount_amount = parseInt(two_adult_amount) * parseInt(thursday_discount_perc) / 100;
                    break;
                case "friday":
                    room_discount_amount = parseInt(two_adult_amount) * parseInt(friday_discount_perc) / 100;
                    break;
                case "saturday":
                    room_discount_amount = parseInt(two_adult_amount) * parseInt(saturday_discount_perc) / 100;
                    break;
                case "sunday":
                    room_discount_amount = parseInt(two_adult_amount) * parseInt(sunday_discount_perc) / 100;
                    break;
            }
        }
        else if (base_discount_perc) {
            room_discount_amount = parseInt(two_adult_amount) * parseInt(base_discount_perc) / 100;
        }

        var amount_after_discount = two_adult_amount + room_discount_amount
        if (amount_after_discount == two_adult_amount) {
            return <span>{indianNumberFormat(amount_after_discount)}/- + GST</span>
        } else if (amount_after_discount < two_adult_amount) {
            return <><div><span style={{ textDecoration: "line-through" }}>{indianNumberFormat(two_adult_amount)}</span></div><div><span><b>{indianNumberFormat(amount_after_discount)}/- + GST</b></span></div></>
        } else if (amount_after_discount > two_adult_amount) {
            return <span>{indianNumberFormat(amount_after_discount)}/- + GST</span>
        }

    }

    function indianNumberFormat(x) {
        if (x) {
            var number = parseFloat(x)
            return number.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: "currency", currency: "INR"
            });
        } else {
            return x
        }
    }

    const handleBookBtn = (roomName) => {
        var check_in = context_data.checkIn
        var check_out = moment(check_in).add(2, "days").format("yyyy-MM-DD")
        context_data.setMinCheckOut(check_out)
        context_data.setCheckIn(check_in)
        context_data.setCheckOut(check_out)

        context_data.setRoom(roomName)
        context_data.setDatesModalShow(true)
    }

    return (
        <>
            <div className="after_header" style={{ height: "75px" }}></div>
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>

            <section className="packagesPage_overview section_heading">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Wellness Package Overview</h1>
                            <span>[Age limit for SPA is 21 yrs.]</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                Tapovana, an elixir for good health!
                            </p>
                            <p className="text-justify">
                                If you seek holistic wellness focused on mental and physical well-being, this is the best package for you. Enjoy entrancing Yoga and meditation sessions in the morning, breathe the pure and crisp air deeply as you go on treks and hikes, covering heritage sites and sacred heavenly abodes of gods and deities. Spend your evening enjoying tribal dance and live folk singing, discussing philosophy and theology, sitting around a merry campfire.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_packagesSubPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img src={"assets/media/facilitiesPage_yoga.jpg"} className="packagesPage_banner subPage_banner" alt="Packages Wellness Banner" />
                        </div>
                    </div>
                </div>
            </section>

            {(tariffData != null && tariffData != false) ? (

                <section className="section_package_tariff" onLoad={() => btnShowFacilitiesMobile()}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center div_subHeading">
                                <h2 className="text-cener script_font">Package Pricing</h2>
                            </div>

                            <div className="col-12 mb-4">
                                <Accordion defaultActiveKey="" id="accordion_block" >

                                    {tariffData.map((tariff, index) =>
                                        <>
                                            {(tariff.room == "hilltop_cottage") ? (
                                                <Accordion.Item eventKey="0" id="accordionToggle0" onMouseEnter={() => cardKeyMouseIn("0")} onMouseLeave={() => cardKeyMouseOut("0")} >
                                                    <Accordion.Header>
                                                        <div className="container-fluid" >
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <img className="img_package_tariff" src={"assets/media/roomPage_hilltop_cottage.jpg"} />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h3>Hilltop Cottage</h3>
                                                                    <p>Hilltop cottage is suitable for small families or couples.Our guests can spend some quality time with their loved ones here.</p>
                                                                    <h6>Check In: 12 Noon</h6>
                                                                    <h6>Check Out: 10 AM</h6>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <span><>Starts from </></span>
                                                                    {/* <h4 style={{ color: "orange" }}><>Rs. {tariff.two_adult}/- + GST</></h4> */}
                                                                    <div><h3>{showDiscountAmount(tariff)}</h3></div>
                                                                    <h5>For 2 Nights and 3 days</h5>
                                                                    <div className="d-flex">
                                                                        <div className="pt-1 mr-1"><input type="button" className="btn btn_book btn_header" value="Book Now" onClick={() => handleBookBtn("hilltop_cottage")} /></div>
                                                                        <div className="pt-1"><Accordion.Item as={Button} variant="button" className="btn btn_header btn_show_facilities" eventKey="0">View Facilities</Accordion.Item></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <h4>Hilltop Cottage Facilities</h4>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <ul>
                                                                        <li> Welcome drink</li>
                                                                        <li> Fruit basket</li>
                                                                        <li> Buffet breakfast & dinner at the restaurant</li>
                                                                        <li> SPA meal for lunch</li>
                                                                        <li> High tea at the restaurant</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <ul>
                                                                        <li> Yoga / Meditation</li>
                                                                        <li> Herbal juice in the SPA</li>
                                                                        <li> One aromatherapy for 45 minutes per person</li>
                                                                        <li> Choice of scrub or wrap or head massage for 30 min. per person</li>
                                                                        <li> Eco-friendly bath amenities</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ) : ""}

                                            {(tariff.room == "heritage_thottimane") ? (
                                                <Accordion.Item eventKey="1" id="accordionToggle1" onMouseEnter={() => cardKeyMouseIn("1")} onMouseLeave={() => cardKeyMouseOut("1")} >
                                                    <Accordion.Header>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <img className="img_package_tariff" src={"assets/media/roomPage_heritage_thottimane.jpg"} />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h3>Hertiage Thottimane</h3>
                                                                    <p>Hertiage Thottimane is made in the form of traditional architecture Kerala homes.These designs were made in the olden times, which is very rare these days.</p>
                                                                    <h6>Check In: 12 Noon</h6>
                                                                    <h6>Check Out: 10 AM</h6>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <span><>Starts from </></span>
                                                                    {/* <h4 style={{ color: "orange" }}><>Rs. {tariff.two_adult}/- + GST</></h4> */}
                                                                    <div><h3>{showDiscountAmount(tariff)}</h3></div>
                                                                    <h5>For 2 Nights and 3 days</h5>
                                                                    <div className="d-flex">
                                                                        <div className="pt-1 mr-1"><input type="button" className="btn btn_book btn_header" value="Book Now" onClick={() => handleBookBtn("heritage_thottimane")} /></div>
                                                                        <div className="pt-1"><Accordion.Item as={Button} variant="button" className="btn btn_header btn_show_facilities" eventKey="0">View Facilities</Accordion.Item></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <h4>Hertiage Thottimane Facilities</h4>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <ul>
                                                                        <li> Welcome drink</li>
                                                                        <li> Fruit basket</li>
                                                                        <li> Buffet breakfast & dinner at the restaurant</li>
                                                                        <li> SPA meal for lunch</li>
                                                                        <li> High tea at the restaurant</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <ul>
                                                                        <li> Yoga / Meditation</li>
                                                                        <li> Herbal juice in the SPA</li>
                                                                        <li> One aromatherapy for 45 minutes per person</li>
                                                                        <li> Choice of scrub or wrap or head massage for 30 min. per person</li>
                                                                        <li> Eco-friendly bath amenities</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ) : ""}

                                            {(tariff.room == "valley_view_villa") ? (
                                                <Accordion.Item eventKey="2" id="accordionToggle2" onMouseEnter={() => cardKeyMouseIn("2")} onMouseLeave={() => cardKeyMouseOut("2")} >
                                                    <Accordion.Header>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <img className="img_package_tariff" src={"assets/media/roomPage_valley_view_villa.jpg"} />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h3>Valley View Villa</h3>
                                                                    <p>We have valley view cottage where our guests can get the best view of the mountains present.By taking the excellent view out of one’s window, one can see the deep valleys.</p>
                                                                    <h6>Check In: 12 Noon</h6>
                                                                    <h6>Check Out: 10 AM</h6>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <span><>Starts from </></span>
                                                                    {/* <h4 style={{ color: "orange" }}><>Rs. {tariff.two_adult}/- + GST</></h4> */}
                                                                    <div><h3>{showDiscountAmount(tariff)}</h3></div>
                                                                    <h5>For 2 Nights and 3 days</h5>
                                                                    <div className="d-flex">
                                                                        <div className="pt-1 mr-1"><input type="button" className="btn btn_book btn_header" value="Book Now" onClick={() => handleBookBtn("valley_view_villa")} /></div>
                                                                        <div className="pt-1"><Accordion.Item as={Button} variant="button" className="btn btn_header btn_show_facilities" eventKey="0">View Facilities</Accordion.Item></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <h4>Valley View Villa Facilities</h4>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <ul>
                                                                        <li> Welcome drink</li>
                                                                        <li> Fruit basket</li>
                                                                        <li> Buffet breakfast & dinner at the restaurant</li>
                                                                        <li> SPA meal for lunch</li>
                                                                        <li> High tea at the restaurant</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <ul>
                                                                        <li> Yoga / Meditation</li>
                                                                        <li> Herbal juice in the SPA</li>
                                                                        <li> One aromatherapy for 45 minutes per person</li>
                                                                        <li> Choice of scrub or wrap or head massage for 30 min. per person</li>
                                                                        <li> Eco-friendly bath amenities</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ) : ""}

                                            {(tariff.room == "luxury_villa") ? (
                                                <Accordion.Item eventKey="3" id="accordionToggle3" onMouseEnter={() => cardKeyMouseIn("3")} onMouseLeave={() => cardKeyMouseOut("3")} >
                                                    <Accordion.Header>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <img className="img_package_tariff" src={"assets/media/roomPage_luxury_villa.jpg"} />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h3>Luxury Villa</h3>
                                                                    <p>As we all are working so hard just to have a luxurious lifestyle.Tapovana gave an excellent thought that our guests can have that beautiful experience.</p>
                                                                    <h6>Check In: 12 Noon</h6>
                                                                    <h6>Check Out: 10 AM</h6>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <span><>Starts from </></span>
                                                                    {/* <h4 style={{ color: "orange" }}><>Rs. {tariff.two_adult}/- + GST</></h4> */}
                                                                    <div><h3>{showDiscountAmount(tariff)}</h3></div>
                                                                    <h5>For 2 Nights and 3 days</h5>
                                                                    <div className="d-flex">
                                                                        <div className="pt-1 mr-1"><input type="button" className="btn btn_book btn_header" value="Book Now" onClick={() => handleBookBtn("luxury_villa")} /></div>
                                                                        <div className="pt-1"><Accordion.Item as={Button} variant="button" className="btn btn_header btn_show_facilities" eventKey="0">View Facilities</Accordion.Item></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <h4>Luxury Villa Facilities</h4>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <ul>
                                                                        <li> Welcome drink</li>
                                                                        <li> Fruit basket</li>
                                                                        <li> Cookie platter</li>
                                                                        <li> Buffet Breakfast and Dinner at the restaurant</li>
                                                                        <li> SPA meal for lunch</li>
                                                                        <li> High tea at the restaurant</li>
                                                                        <li> Eco-friendly bath amenities</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <ul>
                                                                        <li> Yoga / Meditation</li>
                                                                        <li> Herbal juice in the SPA</li>
                                                                        <li> One aromatherapy for 45 minutes per person</li>
                                                                        <li> Choice of scrub or wrap or head massage for 30 min. per person</li>
                                                                        <li> One night inroom candlelight dinner</li>
                                                                        <li> One night moon light dinner on the lawn</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ) : ""}

                                            {(tariff.room == "private_pool_villa") ? (
                                                <Accordion.Item eventKey="4" id="accordionToggle4" onMouseEnter={() => cardKeyMouseIn("4")} onMouseLeave={() => cardKeyMouseOut("4")} >
                                                    <Accordion.Header>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <img className="img_package_tariff" src={"assets/media/roomPage_private_pool_villa.jpg"} />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h3>Private Pool Villa</h3>
                                                                    <p>Unlike all the other cottages present in the Tapovana, the private pool villa is the most special one.We have made this villa so that our guests can get the private pool inside their cottage.</p>
                                                                    <h6>Check In: 12 Noon</h6>
                                                                    <h6>Check Out: 10 AM</h6>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <span><>Starts from </></span>
                                                                    {/* <h4 style={{ color: "orange" }}><>Rs. {tariff.two_adult}/- + GST</></h4> */}
                                                                    <div><h3>{showDiscountAmount(tariff)}</h3></div>
                                                                    <h5>For 2 Nights and 3 days</h5>
                                                                    <div className="d-flex">
                                                                        <div className="pt-1 mr-1"><input type="button" className="btn btn_book btn_header" value="Book Now" onClick={() => handleBookBtn("private_pool_villa")} /></div>
                                                                        <div className="pt-1"><Accordion.Item as={Button} variant="button" className="btn btn_header btn_show_facilities" eventKey="0">View Facilities</Accordion.Item></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <h4>Private Pool Villa Facilities</h4>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <ul>
                                                                        <li> Welcome drink</li>
                                                                        <li> Fruit basket</li>
                                                                        <li> Cookie platter</li>
                                                                        <li> Buffet breakfast at the restaurant</li>
                                                                        <li> SPA meal for lunch</li>
                                                                        <li> High tea at the restaurant</li>
                                                                        <li> Herbal juice in the SPA</li>
                                                                        <li> Yoga / Meditation</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <ul>
                                                                        <li> Two aromatherapy for 45 minutes per person</li>
                                                                        <li> Choice of scrub or wrap for 30 minutes per person</li>
                                                                        <li> Head massage for 30 minutes per person</li>
                                                                        <li> One night candlelight dinner beside the private pool</li>
                                                                        <li> One night moon light dinner on the lawn</li>
                                                                        <li> Early check-in/Late check-out upon availability</li>
                                                                        <li> Eco-friendly bath amenities</li>
                                                                    </ul>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ) : ""}
                                        </>
                                    )}

                                </Accordion>

                            </div>
                        </div>
                    </div>
                </section>
            ) : ""
            }

            <section className="section_slider_packages bg_colour section_subHeading" id="section_slider_packages">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_subHeading">
                            <h2 className="text-cener script_font">Activities</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 packages_slider">

                            <Slider {...settings}>
                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/meditation.jpg"} />
                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">Yoga + Meditation. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/kashaya.jpg"} />
                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">Local kashaya or energy drink (amla) and Tea/Coffee served in Restaurant</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/breakfast.jpg"} />
                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">Breakfast at Restaurant - Either buffet or a la carte imbibing local flavour</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/bullock_cart.jpg"} />
                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">Bullock Cart Ride around the resort</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/village.jpg"} />
                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">Plantation Walk + Local Village + Local Temple Visit in bullockcart/Jeep</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/lunch.jpg"} />
                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">Lunch at Restaurant - Either buffet or a la carte imbibing local flavour</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/spa.jpg"} />

                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">SPA - Traditional Oil bath, full body massage, Manicure, Pedicure, Foot massage</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/tea.jpg"} />

                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">HiTea and Snacks</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/games.jpg"} />

                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">In resort activities - Cricket/Beach VolleyBall/Badminton/Mud Volleyball/TT/Traditional Games like Chaukabara, dice, marble, chinnidand (basically teach them and play)</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/campfire.jpg"} />

                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">Campfire - light music</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/yakshagana.jpg"} />

                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">Campfire + Live Concert/ Tribal Dance (Yakshagana, Harikathe)</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/dinner.jpg"} />

                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">Dinner in Restaurant</p>
                                        </div>
                                    </div>
                                </div>

                                {/* day 2 */}

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/yoga.jpg"} />

                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">Yoga + Meditation. </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/gazebo.jpg"} />
                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">Local kashaya or energy drink or Tea/Coffee served in Gazebo area</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/la_carte.jpg"} />
                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">Breakfast at Restaurant1 - either buffet or a la carte imbibing local flavour</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/abbi_falls.jpg"} />
                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">Local private Water Falls/Trekking (Abbi Falls/ Manchnalli falls) in Jeep</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/buffet.jpg"} />
                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">Lunch at Restaurant1 - either buffet or a la carte imbibing local flavour</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/traditionalGames.jpg"} />

                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">Traditional Group activity - games involving all resort people showcasing games</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/mud_volleyball.jpg"} />
                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">In resort activities - Cricket/Beach VolleyBall/Badminton/Mud Volleyball/TT/Traditional Games like Chaukabara, dice, marble, chinnidand (basically teach them and play)</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/campfire.jpg"} />

                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">Campfire - light music</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/yakshagana1.jpg"} />

                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">Campfire + Live Concert/ Tribal Dance (Yakshagana, Harikathe)</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="div_packages_slider">
                                    <div className="div_img_packages_slider ">
                                        <img className="img_slider_packages" src={"assets/media/activities_images/dinner1.jpg"} />
                                        <div className="div_packages_slider_text text-center">
                                            <p className="text-center packages_slider_text">Dinner with Light Music</p>
                                        </div>
                                    </div>
                                </div>

                            </Slider>

                        </div>
                    </div>
                </div>
            </section>


            <WebsiteFooter />

        </>
    )
}

export default PackagesWellness
