import React, { useEffect } from 'react'

import WebsiteFooter from '../Components/WebsiteFooter';

import EventsFacilities from '../Components/EventsFacilities';

const EventsMeetings = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        let packages_height_arr = [];

        let cardBody_packagesPage = document.getElementsByClassName("cardBody_packagesPage");

        for (let i = 0; i < cardBody_packagesPage.length; i++) {
            cardBody_packagesPage[i].style.display = "block";
            let packages_height_data = cardBody_packagesPage[i].clientHeight;
            packages_height_arr.push(parseInt(packages_height_data));
        }
        var packages_max_height = (Math.max.apply(Math, packages_height_arr));

        for (let i = 0; i < cardBody_packagesPage.length; i++) {
            cardBody_packagesPage[i].style.height = (packages_max_height + 20) + "px";
        }

    }, [])

    return (
        <>
            {/* <div className="after_header" style={{ height: "75px" }}></div> */}
            <section className="section_after_navbar">
                <img src={"assets/media/after_navbar.jpg"} className="img_after_navbar" alt="" />
            </section>

            <section className="packagesPage_overview section_heading">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center div_heading">
                            <h1 className="text-cener script_font">Meeting / Conference Overview</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify">
                                Outdoor meeting and team bonding.
                            </p>
                            <p className="text-justify">
                                Thinking of a business trip and getaway with colleagues? Experience excellent service and hospitality as we organize your meetings, seminars, team bonding exercises and fun parties like clockwork. Find time to know your colleagues outside the office lobby during morning treks, around campfire and cocktail party conversations, or while swaying on the dance floor to a flaming number. The package includes a good spread of delicacies during breakfast, lunch and dinner hours.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_packagesSubPage_img">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img src={"assets/media/banquetPage_boardroom.jpg"} className="packagesPage_banner subPage_banner" alt="Packages Corporate Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <EventsFacilities />

            <WebsiteFooter />

        </>
    )
}

export default EventsMeetings
