import React, { useEffect, useState } from 'react'
import * as MdIcons from "react-icons/md";
import axios from 'axios';
import PhoneInput from 'react-phone-number-input';

const BanquetEnquiry = () => {

    useEffect(() => {
        window.scrollTo(0, 0);

        if (window.location.pathname == "/banquetBallRoom") {
            document.getElementById("select_venue").value = "Ballroom";
        }

        if (window.location.pathname == "/banquetBoardroom") {
            document.getElementById("select_venue").value = "Boardroom";
        }

        if (window.location.pathname == "/banquetOutdoor") {
            document.getElementById("select_venue").value = "Outdoor";
        }

        if (window.location.pathname == "/banquetGreenlawn") {
            document.getElementById("select_venue").value = "Greenlawn";
        }

    }, [])

    const OpenInNewTab = (Type, data) => {
        if (Type == "Link") {
            const newWindow = window.open(data, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }
    }

    const [formMobile, setFormMobile] = useState()

    const submitEnquiry = (e) => {
        e.preventDefault();
        let name = e.target.name.value;
        let mobile = (formMobile) ? formMobile : e.target.mobile.value;
        let email = e.target.email.value;
        let message = e.target.message.value;

        const addEnquiryUrl = global.config.server_url + "addEnquiry";
        const mobileRegex = new RegExp("[+][0-9]{2}[1-9]{1}[0-9]{9}");

        const addEnquiryData = {
            name: name,
            mobile: mobile,
            email: email,
            message: message
        };

        if (mobileRegex.test(mobile)) {
            axios.post(addEnquiryUrl, addEnquiryData).then((addEnquiryResponse) => {
                if (addEnquiryResponse.data === true) {
                    alert("Submitted");
                    e.target.name.value = "";
                    e.target.mobile.value = "";
                    e.target.email.value = "";
                    e.target.message.value = "";

                } else {
                    alert("Error in Submitting Application!");
                }
            }).catch(err => { console.log(err); alert("Error in Submitting Application"); });
        } else {
            alert("Invalid Mobile Number")
        }

    }

    return (
        <>
            <section className=" my-3">
                <div className="container ">
                    <form onSubmit={(e) => submitEnquiry(e)}>
                        <div className="row">
                            <div className="col-12"><h3>Banquet Enquiry</h3></div>
                            <div className="col-4">
                                <div className="form-group">
                                    <input type="text" name="name" id="name" className="form-control" placeholder="Name" />
                                </div>
                                <div className="form-group">
                                    <div className="form-control">
                                        <PhoneInput defaultCountry={"IN"} className="phone_input" placeholder="Enter phone number" name="mobile" value={formMobile} onChange={setFormMobile} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="email" name="email" id="email" className="form-control" placeholder="Email" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <select id="select_venue" name="message" className="form-control">
                                        <option value="Ballroom">Ballroom</option>
                                        <option value="Boardroom">Boardroom</option>
                                        <option value="Outdoor">Outdoor</option>
                                        <option value="Greenlawn">Greenlawn</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <div className="input-group mb-3">
                                        <input type="text" required name="event_date" id="event_date" className="event_date form-control" placeholder="Event Date" onFocus={(e) => { e.target.type = "date" }} style={{ fontSize: "14px" }} aria-label="event_date" aria-describedby="event_date" />
                                        <div className="input-group-append">
                                            <span className="bg-light input-group-text" id="event_date"><MdIcons.MdDateRange /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="submit" value="Enquire Now" className="btn btn_header" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-control border-0">
                                    <label htmlFor=""><b>BANQUET ENQUIRY</b></label>
                                </div>
                                <div className="form-control border-0">
                                    <label htmlFor="" className="link" ><MdIcons.MdSmartphone onClick={() => OpenInNewTab("Link", "tel:+91-9686659966")} /> +91 96866 59966</label>
                                </div>
                                <div className="form-control border-0">
                                    <label htmlFor="" className="link" ><MdIcons.MdMail onClick={() => OpenInNewTab("Link", "mail:reservations@tapovanaresorts.com")} /> reservations@tapovanaresorts.com</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>



        </>
    )
}

export default BanquetEnquiry
